import allRoutes from './allRoutes.json'

import Trending from "../../../pages/trending/Trending";
import PopularLists from "../../../pages/popularLists/PopularLists";
import CreateList from "../../../pages/createList/CreateList";
import Mint from "../../../pages/mint/Mint";
import ExporeStrategy from "../../../pages/exploreStrategy/ExploreStrategy";

const routesMap = {
	[allRoutes.Mint]: <Mint />,
	[allRoutes.Trending]: <Trending />,
	[allRoutes.CreateLists]: <CreateList />,
	[allRoutes.PopularLists]: <PopularLists />,
	[allRoutes.StrategyExplorer]: <ExporeStrategy />,
}

export default routesMap