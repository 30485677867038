import mixpanel, {Dict} from 'mixpanel-browser';
import {isProduction} from '../config'
import {TrackedEventsName} from "./enum";
import {MIXPANEL_PROJECT_TOKEN, MIXPANEL_PROXY_DOMAIN} from "./config";

const env_check = isProduction;

mixpanel.init(MIXPANEL_PROJECT_TOKEN, {debug: false, api_host: MIXPANEL_PROXY_DOMAIN});

const actions = {
	identify: (id: string) => {
		if (env_check) mixpanel.identify(id);
	},
	// alias: (id) => {
	// 	if (env_check) mixpanel.alias(id);
	// },
	track: (name: TrackedEventsName, props: Dict) => {
		if (env_check) mixpanel.track(name, props);
	},
	// people: {
	// 	set: (props) => {
	// 		if (env_check) mixpanel.people.set(props);
	// 	},
	// },
}

export const Mixpanel = actions