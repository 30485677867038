import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {useGetPopularStrategiesQuery} from "../../state/api/strategies/strategies.api";
import {useLazyGetFollowedFiltersQuery} from '../../state/api/user/user.api';

import {SortState} from "../../enum";
import {popularListsHeaderValues} from "./config";
import {isFollowed} from "./helper";

import Header from '../../components/header/Header'
import HeaderItem from "./components/HeaderItem";
import TableItem from "./components/TableItem";
import Pagination from "../../components/pagination/Pagination";
import NotifyModal from "../../components/notifyModal/notifyModal";

import {setCurrentPage, setRowsAmount, setTotalAmount} from "../../features/pagination/paginationSlice";
import {setFollowedFilters, setStrategies, setTopStrategies} from "../../features/user/userSlice";
import {setFiltersSort} from "../../features/sort/sortSlice";

import styles from './styles/popularLists.module.css'
import Skeleton from "../../components/skeleton/Skeleton";
import {SkeletonType} from "../../components/skeleton/emun";
import ListPreview from "./components/ListPreview";
import {debounce} from "lodash";

const PopularLists = () => {
	const dispatch = useAppDispatch()
	
	const { filtersSort } = useAppSelector(state => state.sort)
	const { searchedValue } = useAppSelector(state => state.search)
	const { rowsAmount, currentPage } = useAppSelector(state => state.pagination)
	const { followedFilters, topStrategies, strategies } = useAppSelector(state => state.user)
	const { isWalletConnected} = useAppSelector(state => state.auth)
	const [ getFollowedTrigger] = useLazyGetFollowedFiltersQuery({})
	
	const [page, setPage] = useState<{data: number, isLoading: boolean}>({
		data: currentPage,
		isLoading: false
	})
	
	const debouncedSetPage = useRef(
		debounce((page: {data: number, isLoading: boolean}) => setPage(page), 300, {leading: true})
	).current
	
	const topStrategiesQuery = useGetPopularStrategiesQuery({
		filters: {
			followersCount: -1
		},
		offset: 0,
		limit: 5
	})
	
	const strategiesQuery = useGetPopularStrategiesQuery({
		filters: {
			[filtersSort.sortedField]: filtersSort.sortType
		},
		offset: (page.data - 1) * rowsAmount,
		limit: rowsAmount,
		name: searchedValue
	})
	
	useEffect(() => {
		strategiesQuery.refetch()
		topStrategiesQuery.refetch()
		dispatch(setCurrentPage(1))
		dispatch(setRowsAmount(100))
		dispatch(setFiltersSort({
			sortedField: popularListsHeaderValues[4].id,
			sortType: SortState.toLow
		}))
	}, [])
	
	useEffect(() => {
		setPage({...page, isLoading: true})
		
		debouncedSetPage({data: currentPage, isLoading: false})
	}, [currentPage])
	
	useEffect(() => {
		return () => debouncedSetPage.cancel()
	}, [debouncedSetPage])

	useEffect(() => {
		if (isWalletConnected) {
			getFollowedTrigger({withoutDefault: false}).then(res => {
				const {data, isSuccess} = res
				
				if (isSuccess) dispatch(setFollowedFilters(data))
			})
		}
	}, [isWalletConnected])
	
	useEffect(() => {
		if (topStrategiesQuery.data) {
			dispatch(setTopStrategies(topStrategiesQuery.data.list))
		}
	}, [topStrategiesQuery.data])
	
	useEffect(() => {
		if (strategiesQuery.data) {
			dispatch(setTotalAmount(strategiesQuery.data.total))
			dispatch(setStrategies(strategiesQuery.data.list))
		}
	}, [strategiesQuery.data])
	
	return (
		<>
			<Header />
			<div className={styles.container}>
				<div className={styles.header_container}>
					<h2 className={styles.header_title}>Popular Groups</h2>
					<p className={styles.header_description}>Explore and follow the Groups to spot Smart Money activity</p>
				</div>
				<div className={styles.lists_preview}>
					{
						topStrategiesQuery.isLoading
						&& <Skeleton type={SkeletonType.StrategyPreview} amount={5} />
						|| topStrategiesQuery.isSuccess
						&& topStrategies.map((value, index) => (
							<ListPreview
								list={value}
								key={index}
							/>
						))
					}
				</div>
				<div className={styles.lists_table}>
					<div className={styles.lists_table_header}>
						{
							popularListsHeaderValues.map((value, index) => (
								<HeaderItem
									id={value.id}
									name={value.name}
									icon={value.icon}
									sortable={value.sortable}
									tooltip={value.tooltip}
									key={index}
								/>
							))
						}
					</div>
					{
						(strategiesQuery.isLoading || strategiesQuery.isFetching || page.isLoading) &&
						<Skeleton type={SkeletonType.Table} amount={rowsAmount}/>
						||
						strategiesQuery.isError &&
						<div className={styles.body_row}>
							<span className={styles.body_notification}>Updating database, please wait</span>
						</div>
						||
						strategiesQuery.isSuccess && strategies.map((value, index) => (
							<TableItem
								index={index + 1 + (rowsAmount * ( currentPage - 1 ))}
								list={value}
								isFollowed={isFollowed(followedFilters.data, value.id)}
								key={index}
							/>
						))
					}
				</div>
				{
					strategiesQuery.isSuccess &&
					<Pagination />
				}
			</div>
			<NotifyModal />
		</>
	);
};

export default PopularLists;
