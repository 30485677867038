import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import {useGetMintsQuery} from "../../../state/api/mints/mints.api";

import {setCurrentPage, setRowsAmount, setTotalAmount} from "../../../features/pagination/paginationSlice";

import {mintHeaderValues} from '../config'

import HeaderItem from "./HeaderItem";
import MintItem from "./MintItem";
import Pagination from "../../../components/pagination/Pagination";

import styles from '../../trending/styles/trending.module.css'
import {setMintSort} from "../../../features/sort/sortSlice";
import {SortState} from "../../../enum";
import Skeleton from "../../../components/skeleton/Skeleton";
import {SkeletonType} from "../../../components/skeleton/emun";
import {debounce} from "lodash";

const MintTable = () => {
	const dispatch = useAppDispatch()
	
	const { mintSort } = useAppSelector(state => state.sort)
	const { mintPeriod } = useAppSelector(state => state.timePeriod)
	const { rowsAmount, currentPage } = useAppSelector(state => state.pagination)
	
	const [page, setPage] = useState<{data: number, isLoading: boolean}>({
		data: currentPage,
		isLoading: false
	})
	
	const debouncedSetPage = useRef(
		debounce((page: {data: number, isLoading: boolean}) => setPage(page), 300, {leading: true})
	).current
	
	const { data, isSuccess, isLoading, isError, isFetching } = useGetMintsQuery({
		filters: {
			[mintSort.sortedField]: mintSort.sortType
		},
		offset: rowsAmount * (page.data - 1),
		limit: rowsAmount,
		range: mintPeriod
	})
	
	useEffect(() => {
		setPage({...page, isLoading: true})
		
		debouncedSetPage({data: currentPage, isLoading: false})
	}, [currentPage])
	
	useEffect(() => {
		return () => debouncedSetPage.cancel()
	}, [debouncedSetPage])
	
	useEffect(() => {
		dispatch(setRowsAmount(20))
		dispatch(setCurrentPage(1))
		dispatch(setMintSort({
			sortedField: mintHeaderValues[1].id,
			sortType: SortState.toLow
		}))
	}, [])
	
	useEffect(() => {
		if (data) dispatch(setTotalAmount(data.total))
	}, [data])
	
	return (
		<div className={styles.tab}>
			<div className={styles.tab_header_mint}>
				{
					mintHeaderValues.map((value, index) => (
						<HeaderItem
							headerValues={value}
							key={index}
						/>
					))
				}
			</div>
			<div className={styles.body}>
				{
					(isLoading || isFetching || page.isLoading)
					&& <Skeleton type={SkeletonType.Table} amount={rowsAmount} />
					||
					isError &&
					<div className={styles.body_row}>
						<span className={styles.body_notification}>Updating database, please wait</span>
					</div>
					||
					isSuccess &&
					data.list.map((mint, index) => (
						<MintItem
							name={mint.name}
							openseaLink={mint.openseaLink}
							supply={mint.supply}
							stats={mint.stats}
							logo={mint.logo}
							contracts={mint.contracts}
							media={mint.media}
							nftGoId={mint.nftGoId}
							slug={mint.slug}
							key={index}
						/>
					))
				}
				{
					!isError &&
					<Pagination />
				}
			</div>
		</div>
	);
};

export default MintTable;
