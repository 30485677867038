import {createContext, ReactElement, useContext, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {
	setExcludedHolders,
	setFilters,
	setIsStrategyEdited, setSelectAllHolders, setSelectedAddresses,
	setSelectedAddressesAmount
} from "../../features/holdersInteraction/holdersInteractionSlice";
import {setCurrentPage, setRowsAmount} from "../../features/pagination/paginationSlice";
import {setHoldersSort} from "../../features/sort/sortSlice";
import {holdersHeaderValues} from "../../pages/exploreStrategy/config";
import {SortState} from "../../enum";
import {IStrategies} from "../../state/api/strategies/types";
import useGetHolders, {UseGetHoldersResponse} from "../../pages/exploreStrategy/hooks/useGetHolders";
import {deleteEmptyFieldsInObj} from "../../utils";

interface TableExploreContext {
	page: number
	selectAllHandler: () => void
	isHolderChecked: (address: string) => boolean
	getHoldersQuery: UseGetHoldersResponse
	rowsAmount: number
	selectAllHolders: boolean
	totalAmount: number
	exploredStrategy: IStrategies
}

const TableActionContext = createContext<TableExploreContext | null>(null)

interface TableExploreProviderProps {
	exploredStrategy: IStrategies
	children: ReactElement | ReactElement[] | string
}

export const TableExploreProvider = ({exploredStrategy, children}: TableExploreProviderProps) => {
	const dispatch = useAppDispatch()
	
	const {
		filters,
		showAll,
		excludedHolders,
		isStrategyEdited,
		selectAllHolders,
		selectedAddresses,
	} = useAppSelector(state => state.holdersInteraction)
	const { holdersSort } = useAppSelector(state => state.sort)
	const { rowsAmount, currentPage, totalAmount } = useAppSelector(state => state.pagination)
	
	const getHolders = useGetHolders({
		exploredStrategy, filters, showAll, currentPage, rowsAmount, holdersSort
	})
	
	const {data, isSuccess} = getHolders
	
	const setSelectedAddressesAmountHandler = () => {
		if (!isStrategyEdited) {
			dispatch(setSelectedAddressesAmount(exploredStrategy.includedAddresses))
			
			return
		}
		
		if (isSuccess) {
			if (!selectedAddresses.length && !excludedHolders.length && selectAllHolders) {
				dispatch(setSelectedAddressesAmount(data.total))
				
				return
			}
			
			if (!selectedAddresses.length && !excludedHolders.length && !selectAllHolders) {
				dispatch(setSelectedAddressesAmount(0))
				
				return
			}
			
			if (selectedAddresses.length) {
				dispatch(setSelectedAddressesAmount(selectedAddresses.length))
				
				return
			}
			
			if (excludedHolders.length) {
				dispatch(setSelectedAddressesAmount(data.total - excludedHolders.length))
				
				return;
			}
		}
		
	}
	
	const selectAllHandler = (): void => {
		dispatch(setIsStrategyEdited(true));
		
		if (selectAllHolders) {
			dispatch(setSelectAllHolders(false))
		}
		else {
			dispatch(setSelectAllHolders(true))
		}
		
		dispatch(setSelectedAddresses([]))      // clear all selected holders to mark that all selected
		dispatch(setExcludedHolders([]))        // clear all excluded holders to mark that all selected
	}
	
	const isHolderChecked = (address: string): boolean => {
		if (selectAllHolders) {
			return true
		}
		
		if (excludedHolders.length !== 0) {
			return !excludedHolders.includes(address)
		}
		
		if (selectedAddresses.length !== 0) {
			return selectedAddresses.includes(address)
		}
		
		return false
	}
	
	useEffect(() => { // initial table config
		dispatch(setRowsAmount(20))
		dispatch(setCurrentPage(1))
		dispatch(setSelectAllHolders(
				showAll
					? (exploredStrategy.isStatic === false) && !exploredStrategy.excludedAddresses?.length
					// проверяет все ли холдеры группы попадают под фильтр
					: true
					// всегда истина так как список холдеров ограничен самой группой
		))
		
		dispatch(setFilters(deleteEmptyFieldsInObj(exploredStrategy.filters)))
		dispatch(setExcludedHolders(exploredStrategy.excludedAddresses ?? []))
		
		dispatch(setHoldersSort({
			sortedField: holdersHeaderValues[1].id,
			sortType: SortState.toLow
		}))
	}, [])
	
	useEffect(() => {
		setSelectedAddressesAmountHandler()
	}, [selectedAddresses, excludedHolders, data, isStrategyEdited])
	
	return (
		<TableActionContext.Provider
			value={{
				rowsAmount,
				totalAmount,
				getHoldersQuery: getHolders,
				page: currentPage,
				isHolderChecked,
				selectAllHandler,
				selectAllHolders,
				exploredStrategy: exploredStrategy,
			}}
		>
			{
				children
			}
		</TableActionContext.Provider>
	)
}

export const useTableExploreAction = () => {
	const context = useContext(TableActionContext)
	
	if (context === null) {
		throw new Error("useTableExploreAction must be used within TableExploreProvider")
	}
	
	return context
}
