import React from 'react';
import {useAppDispatch} from "../../../../state/hooks";
import {useTrending} from "../../../../lib/context/trending-context";
import {useDeleteStrategyMutation} from "../../../../state/api/strategies/strategies.api";

import {callNotifyModal} from "../../../../features/notify/notifySlice";
import {
	removeActiveStrategy,
	removeFollowedFilter,
	setFollowedFilters,
	setFollowedFiltersIsLoading
} from "../../../../features/user/userSlice";

import classNames from "classnames";

import {ReactComponent as CrossIcon} from "../../../../images/icon-cross.svg";

import styles from './modal.module.css'
import {useLazyGetFollowedFiltersQuery} from "../../../../state/api/user/user.api";

const Modal = () => {
	const dispatch = useAppDispatch()
	const [getFollowedFilters] = useLazyGetFollowedFiltersQuery()
	
	const {deleteGroupConfig} = useTrending()
	
	
	const [config, setConfig] = deleteGroupConfig
	const [deleteTrigger] = useDeleteStrategyMutation()
	
	const group = config.group
	
	if (group === undefined) {
		callNotifyModal("Something wrong! (group is undefined)")
		
		return null
	}

	const closeModal = () => {
		setConfig({
			group: undefined,
			isActive: false
		})
	}
	
	const onConfirm = () => {
		deleteTrigger(group.id)
			.then(() => {
				dispatch(setFollowedFiltersIsLoading(true))
				
				dispatch(removeActiveStrategy(group))
				getFollowedFilters({withoutDefault: true})
				.then(res => {
					if (res.isSuccess) dispatch(setFollowedFilters(res.data))
				})
			})
			.catch(() => {
				dispatch(callNotifyModal("error curred while group deleting!"))
			})
			.finally(() => {
				closeModal()
			})
	}
	
	return (
		<>
			{
				config.isActive &&
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<div className={styles.header}>
							<span>Confirm the removal</span>
						</div>
						<div className={styles.body}>
							<span>This action will delete the {config.group?.name} group you created</span>
						</div>
						<div className={styles.footer}>
							<div
								className={classNames(styles.button, styles.button_primary)}
								onClick={closeModal}
							>
								<span>cancel</span>
							</div>
							<div
								className={classNames(styles.button, styles.button_secondary)}
								onClick={onConfirm}
							>
								<span>confirm</span>
							</div>
						</div>
						<CrossIcon
							onClick={closeModal}
							className={styles.cross_icon}
						/>
					</div>
				</div>
			}
		</>
	);
};

export default Modal;