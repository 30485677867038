import React from 'react';

import styles from './styles/pagination.module.css'
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {setCurrentPage} from "../../features/pagination/paginationSlice";
import {DOTS, usePagination} from "./hooks/usePagination";
import classNames from "classnames";
import dotsIcon from '../../images/icon-dots.svg'

const PageSelector = () => {
	const dispatch = useAppDispatch()
	const { currentPage, rowsAmount, totalAmount } = useAppSelector(state => state.pagination)
	
	const paginationRange = usePagination({
		siblingCount: 1,
		totalAmount: totalAmount,
		rowsAmount: rowsAmount,
		currentPage: currentPage
	})
	
	if (currentPage === 0 || paginationRange === undefined || paginationRange.length < 1) {
		return null
	}
	
	const onNext = () => {
		if (currentPage < Math.ceil(totalAmount / rowsAmount))
			dispatch(setCurrentPage(currentPage + 1))
	}
	
	const onPrevious = () => {
		if (currentPage > 1)
			dispatch(setCurrentPage(currentPage - 1))
	}
	
	const isActive = (pageNumber: number | string) => {
		if (pageNumber === currentPage)
			return classNames(styles.page_selector_item, styles.page_selector_item_active)
		return styles.page_selector_item
	}
	
	return (
		
		<div className={styles.page_selector}>
			<div
				className={styles.page_selector_arrow_wrapper}
				onClick={() => onPrevious()}
			>
				<span
					className={styles.page_selector_arrow_left}
				></span>
						</div>
						<div className={styles.page_selector_container}>
							{
								paginationRange.map((pageNumber, index) => {
									if (pageNumber === DOTS) {
										return <div
											className={classNames(styles.page_selector_item, styles.page_selector_item_dots)}
											key={index}
										>
											<img src={dotsIcon} alt={''}/>
										</div>
									}
									
									return (
										<div
											className={isActive(pageNumber)}
											onClick={() => {
												if (typeof pageNumber === "number") dispatch(setCurrentPage(pageNumber))
											}}
											key={index}
										>
											<span>{pageNumber}</span>
										</div>
									)
								})
							}
						</div>
						<div
							className={styles.page_selector_arrow_wrapper}
							onClick={() => onNext()}
						>
				<span
					className={styles.page_selector_arrow_right}
				></span>
			</div>
		</div>
	);
};

export default PageSelector;