import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface INotifyState {
	isModalActive: boolean,
	modalContent: string
}

const initialState: INotifyState = {
	isModalActive: false,
	modalContent: ''
}

const notifySlice = createSlice({
	name: "notifySlice",
	initialState,
	reducers: {
		setNotifyModalActive: (state, action: PayloadAction<boolean>) => {
			state.isModalActive = action.payload
		},
		setNotifyModalContent: (state, action: PayloadAction<string>) => {
			state.modalContent = action.payload
		},
		callNotifyModal: (state, action: PayloadAction<string>) => {
			state.isModalActive = true
			state.modalContent = action.payload
		}
	}
})

export const {
	callNotifyModal,
	setNotifyModalActive,
	setNotifyModalContent,
} = notifySlice.actions

export default notifySlice.reducer