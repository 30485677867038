import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";

import userReducer from '../features/user/userSlice'
import sortReducer from '../features/sort/sortSlice'
import authReducer from '../features/auth/authSlice'
import searchReducer from '../features/search/searchSlice'
import notifyReducer from '../features/notify/notifySlice'
import timePeriodReducer from '../features/timePeriod/timePeriodSlice'
import paginationReducer from '../features/pagination/paginationSlice'
import createListReducer from '../features/createStrategy/createListSlice'
import holdersInteractionReducer from "../features/holdersInteraction/holdersInteractionSlice";

import {nftCollectionsApi} from "./api/collection/nftCollections.api";
import {currencyRateApi} from "./api/currencyRate/currencyRate.api";
import {strategiesApi} from './api/strategies/strategies.api'
import {holdersApi} from "./api/holders/holders.api"
import {mintsApi} from "./api/mints/mints.api";
import {userApi} from "./api/user/user.api"


export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    sort: sortReducer,
    search: searchReducer,
    notify: notifyReducer,
    timePeriod: timePeriodReducer,
    pagination: paginationReducer,
    createList: createListReducer,
    holdersInteraction: holdersInteractionReducer,
    [userApi.reducerPath]: userApi.reducer,
    [mintsApi.reducerPath]: mintsApi.reducer,
    [holdersApi.reducerPath]: holdersApi.reducer,
    [strategiesApi.reducerPath]: strategiesApi.reducer,
    [currencyRateApi.reducerPath]: currencyRateApi.reducer,
    [nftCollectionsApi.reducerPath]: nftCollectionsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
          mintsApi.middleware,
          userApi.middleware,
          holdersApi.middleware,
          strategiesApi.middleware,
          currencyRateApi.middleware,
          nftCollectionsApi.middleware,
      )
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


setupListeners(store.dispatch)
