import React, {useState} from "react";
import styles from "../../../../styles/collection.module.css";
import classNames from "classnames";
import Tooltip from "../../../../../../components/tooltip/Tooltip";
import {trimValue} from "../../../../../../lib/helper";

interface BadgeProps {
	badges: string[]
}

const Badge = ({badges}: BadgeProps) => {
	const [displayAll, setDisplayAll] = useState(false)
	
	return (
		<div className={styles.strategies_container}>
			{
				badges.map((value, index, array) => {
					if (!displayAll && index === 2)
						return (
							<div
								onClick={() => setDisplayAll(true)}
								className={classNames(styles.strategy, styles.strategy_active)}
								key={index}
							>
								{array.length - 2}+
							</div>
						)
					if (!displayAll && index > 1)
						return
					return (
						<Tooltip
							active={value.length > 10}
							content={value}
							key={index}
						>
							<div
								onClick={() => setDisplayAll(false)}
								className={ (displayAll) ? classNames(styles.strategy, styles.strategy_active) : styles.strategy}
							>
								{trimValue(value, 10)}
							</div>
						</Tooltip>
					)
				})
			}
		</div>
	)
}

export default Badge