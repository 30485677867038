import React, {ReactNode} from "react";
import classNames from "classnames";
import styles from "../../../../styles/collection.module.css";

interface ValueWithDeltaProps {
	delta: number
	children: string | ReactNode | ReactNode[]
}

function ValueWithDelta({delta, children} : ValueWithDeltaProps){
	return (
		<div className={styles['value-with-delta']}>
			{
				!!delta && (
				  <div
					  className={
							classNames(
								styles['value-with-delta__delta-text'],
								{
									[styles['value-with-delta__delta-text-profit']]: delta >= 0,
									[styles['value-with-delta__delta-text-lose']]: delta < 0,
								}
								)
						}
				  >
					  <div
						  className={styles['value-with-delta__delta-text__triangle']}
						  style={{transform: `rotate(${delta > 0? 0: 180}deg)`}}
					  >
						  ▲
					  </div>
								{Number.isInteger(delta) ? delta: delta.toFixed(2)}%
				  </div>
				)
			}
			<>
			{
				children
			}
			</>
		</div>
	)
}

export default ValueWithDelta;