import React, {useState} from 'react';
import classNames from "classnames";

import logo from "../../images/nftbull_logo.svg";

import styles from './styles/login.module.css'

interface IProps {
	setSecureData: (value: string | null) => void,
}

const Auth = ({ setSecureData }: IProps) => {
	const [login, setLogin] = useState('')
	const [pass, setPass] = useState('')
	
	const [isLoginValid, setIsLoginValid] = useState(true)
	const [isPassValid, setIsPassValid] = useState(true)
	const [isAuthError, setAuthError] = useState('')
	
	const singInHandler = async (email: string, password: string) => {
		if (email === '')  {
			setIsLoginValid(false)
			return
		}
		if (password === '') {
			setIsPassValid(false)
			return;
		}
		
		if (login === process.env.REACT_APP_CONFIG_LOGIN
			&& pass === process.env.REACT_APP_CONFIG_PASS) {
			localStorage.setItem('secureData', 'true')
			setSecureData('true')
		} else {
			setAuthError('Login or password is incorrect. Please try again.')
		}
	}
	
	return (
		<div className={styles.container}>
			<div className={styles['header_container']}>
				<div
					className={styles.logo_container}
				>
					<img
						className={styles.nftBull_logo}
						src={logo}
						alt={''}
					/>
					<div className={styles.title_container}>
						<h2>alpha</h2>
						<h1>Bulltor</h1>
					</div>
				</div>
				<h3 className={styles['header_subtitle']}>Welcome to <span>Bulltor</span></h3>
				<p className={styles['header_description']}>Enter your details to sign in.</p>
			</div>
			<div className={styles['body']}>
				<div className={
					(isLoginValid)
						? styles.field_container
						: classNames(styles.field_container, styles.field_container_invalid)
				}>
					<span className={styles['field_title']}>Login</span>
					<input
						onChange={(event) => {
							setLogin(event.target.value)
							setIsLoginValid(true)
						}}
						className={styles.field_input}
					/>
					<span className={styles['field_error']}>Please enter a valid email.</span>
				</div>
				<div className={
					(isPassValid)
						? styles['field_container']
						: classNames(styles.field_container, styles.field_container_invalid)
				}>
					<div className={styles['field_title_wrapper']}>
						<span className={styles['field_title']}>Password</span>
					</div>
					<input
						onChange={(event) => {
							setPass(event.target.value)
							setIsPassValid(true)
						}}
						className={styles['field_input']}
						type={'password'}
					/>
					<span className={styles['field_error']}>Password field cannot be empty.</span>
					{
						isAuthError && (
							<span
								className={classNames(styles['field_error'], styles.field_error_visible)}
							>
								{isAuthError}
							</span>
						)
					}
				</div>
				<div
					onClick={ async () => await singInHandler(login, pass)}
					className={styles['sing-in_button']}
				>
					<span>Sign in</span>
				</div>
			</div>
		</div>
	);
};

export default Auth;