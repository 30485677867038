import {disconnect, getAccount, signMessage} from '@wagmi/core'
import {BACKEND_URL} from "./config";
import {throws} from "assert";
import {isObjectEmpty} from "../lib/helper";
import {useWaitForTransaction} from "wagmi";

interface IFetchJwt {
	response: Response,
	repeatRequest?: {
		url: string,
		method: string,
		body: any,
		headers?: any
	}
}

export const fetchJwt = async ({response, repeatRequest}: IFetchJwt) => {
	if (response.status !== 401) {
		return response
	}
	
	let address = getAccount().address
	
	if (!getAccount().address) {
		localStorage.removeItem('jwt')
		await disconnect()
		return response
	}
	
	const originalMsg = await restApiRequest(`${BACKEND_URL}/auth/nonce?address=${address}`, 'GET', null)
	
	let encodedMsg
	
	try {
		encodedMsg = await signMessage({
			message: originalMsg
		})
	} catch (error: any) {
		console.error(error.message)
		await disconnect()
	}
	
	const jwt = await restApiRequest(`${BACKEND_URL}/auth/jwt?encodedMsg=${encodedMsg}&originalMsg=${originalMsg}`, 'GET', null)
	
	if (!jwt) {
		await disconnect()
		return
	}
	
	localStorage.setItem('jwt', jwt)
	
	
	
	if (repeatRequest) {
		const {url, method, headers, body} = repeatRequest
		
		const params: RequestInit = {
			method,
			mode: 'cors',
			body: body ? JSON.stringify(body) : undefined,
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('jwt')}`,
				...headers,
			},
		}
		
		return await fetch(url, params)
	}
}

export async function restApiRequest (url: string, method = 'GET', body: any, headers?: any) {
	try {
		const params: RequestInit = {
			method,
			mode: 'cors',
			body: body ? JSON.stringify(body) : undefined,
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('jwt')}`,
				...headers,
			},
		}

		return await fetch(url, params)
		.then((res) => fetchJwt({response: res, repeatRequest: {url, body, headers, method}})
		.then((res) => res?.json())
		.then((res) => {return res.data}))
	} catch (error: any) {
		throw new Error(error.message)
	}
}

export const deleteEmptyFieldsInObj = (initialObject: object) => {
	let object = {...initialObject}
	
	const keys = Object.keys(object)
	
	if (keys.length === 0) return {}
	
	keys.forEach(value => {
		
		const key = value as keyof typeof object
		const field = object[key]
		
		if (typeof field === 'object') {
			const value = deleteEmptyFieldsInObj(field)
			
			if (!isObjectEmpty(value)) object = {
				...object,
				[key]: value
			}
			else delete object[key]
		}
		
		if (field === null || field === undefined || isObjectEmpty(field)) {
			delete object[key]
		}
	})
	
	let checkAgain = false
	
	if (Object.keys(object).length === 0) checkAgain = true
	
	return object
}

export const getMonthToString = (value: number): string => {
	if ((value < 1) || (value > 12)) return 'error'
	
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	]
	
	return months[value]
}