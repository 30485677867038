import {createContext, ReactElement, useContext, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {setCurrentPage, setRowsAmount} from "../../features/pagination/paginationSlice";
import {setHoldersSort} from "../../features/sort/sortSlice";
import {holdersHeaderValues} from "../../pages/createList/config";
import {SortState} from "../../enum";
import {
	setAddresses,
	setExploredGroup,
	setSelectAll,
	setWatchedCollections
} from "../../features/createStrategy/createListSlice";
import useGetHolders, {UseGetHoldersResponse} from "../../pages/createList/hook/use-get-holders";

interface TableCreateContext {
	page: number
	holdersQuery: UseGetHoldersResponse
	onCheckedHandler: () => void
	displayHoldersInteraction: () => boolean
	setIsAddressSelected: (address: string) => boolean
}

const TableActionContext = createContext<TableCreateContext | null>(null)

interface TableProviderProps {
	children: string | ReactElement | ReactElement[]
}

export const TableProvider = ({children}: TableProviderProps) => {
	const dispatch = useAppDispatch()
	const { holdersSort } = useAppSelector(state => state.sort)
	const {
		filter,
		contractAddresses,
		selectAllAddresses,
		exploredGroups
	} = useAppSelector(state => state.createList)
	const { rowsAmount, currentPage, totalAmount } = useAppSelector(state => state.pagination)
	
	const holdersQuery = useGetHolders({
		filters: filter.filters,
		rowsAmount,
		holdersSort,
		currentPage,
		exploredGroups,
		contractAddresses,
	})
	
	const setIsAddressSelected = (address: string): boolean => {
		const {addresses, excludedAddresses} = filter
		
		if (selectAllAddresses && excludedAddresses.length) {
			return !excludedAddresses.includes(address)
		}
		if (selectAllAddresses) {
			return true
		}
		
		return addresses.includes(address)
	}
	
	const displayHoldersInteraction = () : boolean => {
		const {addresses, excludedAddresses} = filter
		
		return (!!addresses.length || !!excludedAddresses.length)
	}
	
	useEffect(() => { // initial table config
		dispatch(setRowsAmount(20))
		dispatch(setHoldersSort({
			sortedField: holdersHeaderValues[1].id,
			sortType: SortState.toLow
		}))
		
		return () => {
			dispatch(setExploredGroup([]))
		}
	}, [])
	
	useEffect(() => { // set first page
		dispatch(setCurrentPage(1))
	}, [filter.filters, contractAddresses, exploredGroups])
	
	const onCheckedHandler = () => {
		if (!selectAllAddresses && !!contractAddresses) {
			if (holdersQuery.isSuccess) {
				if (holdersQuery.data.isCollection && !!holdersQuery.data.collectyionId) {
					dispatch(setWatchedCollections([holdersQuery.data.collectyionId]))
					dispatch(setSelectAll(true))

					return
				}

				if (filter.addresses.length !== totalAmount) {
					dispatch(setSelectAll(false))
					dispatch(setAddresses(holdersQuery.data.list.map(item => item.address)))

					return;
				}
			}
			
			if (filter.addresses.length === totalAmount) {
				dispatch(setSelectAll(false))
				
				return;
			}
		}
		
		if (selectAllAddresses && filter.excludedAddresses.length) {
			dispatch(setSelectAll(true))
			
			return
		}
		if (!selectAllAddresses) {
			dispatch(setSelectAll(true))
			
			return
		}
		
		dispatch(setSelectAll(false))
	}
	
	return (
		<TableActionContext.Provider
			value={{
				page: currentPage,
				holdersQuery,
				onCheckedHandler,
				setIsAddressSelected,
				displayHoldersInteraction,
			}}
		>
			{children}
		</TableActionContext.Provider>
	)
}

export const useTableActions = () => {
	const context = useContext(TableActionContext)
	if (context === null) {
		throw new Error(
			"useTableActions must be used within a TableActionProvider"
		)
	}
	
	return context
}
