import {ISort} from "../../../features/sort/interface";
import {IStrategies} from "../../../state/api/strategies/types";
import {PeriodTrendingEnum} from "../enum";
import {useEffect, useRef, useState} from "react";
import {useLazyGetNftListQuery} from "../../../state/api/collection/nftCollections.api";
import {INftCollectionResponse} from "../../../state/api/collection/types";
import {useAppDispatch} from "../../../state/hooks";
import {setTotalAmount} from "../../../features/pagination/paginationSlice";
import {debounce} from "lodash";

interface UseGetCollectionProps {
	trendingSort: ISort
	currentPage: number
	rowsAmount: number
	trendingPeriod: PeriodTrendingEnum
	activeStrategies: IStrategies[],
	searchedValue: string | undefined
}

interface LoadingResponseType {
	isError: false,
	isLoading: true,
	isSuccess: false,
	data: undefined
}

interface ErrorResponseType {
	isError: true,
	isLoading: false,
	isSuccess: false,
	data: undefined
}

interface SuccessResponseType {
	isError: false
	isLoading: false
	isSuccess: true
	data: INftCollectionResponse
}

export type UseGetCollectionsResponse = SuccessResponseType | LoadingResponseType | ErrorResponseType

const LoadingResponse: LoadingResponseType = {
	data: undefined,
	isSuccess: false,
	isLoading: true,
	isError: false
}

const ErrorResponse: ErrorResponseType = {
	isError: true,
	isLoading: false,
	isSuccess: false,
	data: undefined
}


const useGetCollections = (props: UseGetCollectionProps): UseGetCollectionsResponse => {
	const {trendingPeriod, activeStrategies, trendingSort, searchedValue, currentPage, rowsAmount} = props
	
	const dispatch = useAppDispatch()
	
	const [response, setResponse] = useState<UseGetCollectionsResponse>(LoadingResponse)
	
	const [getCollections] = useLazyGetNftListQuery()
	
	const fetchGetCollections = ({rowsAmount, currentPage, trendingSort, trendingPeriod, activeStrategies, searchedValue}: UseGetCollectionProps) => {
		getCollections({
			filters: {
				[trendingSort.sortedField]: trendingSort.sortType
			},
			offset: (currentPage - 1) * rowsAmount,
			limit: rowsAmount,
			range: trendingPeriod,
			userFilters: activeStrategies.map(strategy => strategy.id).toString(),
			name: searchedValue
		})
			.unwrap()
			.then(res => {
				const {total} = res
				
				dispatch(setTotalAmount(total))
				setResponse({
					isSuccess: true,
					isLoading: false,
					isError: false,
					data: res,
				})
			})
			.catch(_ => setResponse(ErrorResponse))
	}
	
	const debouncedFetch = useRef(
		debounce((props: UseGetCollectionProps) => fetchGetCollections(props), 300)
	).current
	
	useEffect(() => {
		setResponse(LoadingResponse)
		
		debouncedFetch(props)
	}, [trendingPeriod, activeStrategies, trendingSort, searchedValue, currentPage, rowsAmount])
	
	return response
}

export default useGetCollections;