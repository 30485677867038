import React from 'react';
import styles from '../../styles/table.module.css'
import Skeleton from "../../../../components/skeleton/Skeleton";
import HolderRow from "./HolderRow";
import Suggestion from "./Suggestion";
import HeaderItem from "./HeaderItem";
import Pagination from "../../../../components/pagination/Pagination";
import {SkeletonType} from "../../../../components/skeleton/emun";
import HoldersInteraction from "../../../../components/holdersInteraction/HoldersInteraction";
import {holdersHeaderValues} from "../../config";
import {useTableExploreAction} from "../../../../lib/context/table-explore-context";

const ExplorerTable = () => {
	const {
		page,
		rowsAmount,
		totalAmount,
		isHolderChecked,
		getHoldersQuery,
		selectAllHolders,
		exploredStrategy,
		selectAllHandler,
	} = useTableExploreAction()
	
	const {data, isError, isLoading, isSuccess} = getHoldersQuery
	
	return (
		<div className={styles.table_container}>
			<div className={styles.title_container}>
				<div className={styles.explorer_container}>
					<h3 className={styles.explorer_title}>Explorer:</h3>
					<Suggestion exploredStrategy={exploredStrategy} />
				</div>
			</div>
			<HoldersInteraction />
			<div>
				<div className={styles.header}>
					<div className={styles.header_item}>
						<span>#</span>
					</div>
					<div className={styles.header_item}>
						<input
							type={"checkbox"}
							checked={selectAllHolders}
							readOnly={true}
							onChange={() => selectAllHandler()}
						/>
					</div>
					{
						holdersHeaderValues.map(({value, id, tooltip, icon}, index) => (
							<HeaderItem
								id={id}
								tooltip={tooltip}
								value={value}
								icon={icon}
								key={index}
							/>
						))
					}
				</div>
				<div className={styles.body}>
					{
						isLoading
						&& <Skeleton type={SkeletonType.Table} amount={rowsAmount} />
						||
						isError &&
						<div className={styles.body_row}>
							<span className={styles.body_notification}>Updating database, please wait</span>
						</div>
						||
						<div>
							{
								isSuccess &&
								data.holders.map(({pnlPercent, address, trades, mints, nft, holdingValueEth, collectionCount, ensName, filterIds, pnlEth}, index) =>
									<HolderRow
										isChecked={isHolderChecked(address)}
										holdingValue={holdingValueEth}
										collections={collectionCount}
										address={address}
										trades={trades}
										mints={mints}
										index={(index + 1 + (rowsAmount * (page - 1)))}
										nft={nft}
										pnlPercent={pnlPercent}
										pnlEth={pnlEth}
										filterIds={filterIds}
										ensName={ensName}
										key={index}
									/>
								)
							}
						</div>
					}
					{
						totalAmount &&
						<Pagination />
					}
				</div>
			</div>
		</div>
	);
};

export default ExplorerTable;
