export const rowsValues = [
	{
		value: 5,
	},
	{
		value: 10,
	},
	{
		value: 20,
	},
	{
		value: 50,
	},
	{
		value: 100
	}
]