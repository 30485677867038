import React from 'react';
import {ExplorerDropdownProvider} from "../../../../lib/context/explorer-dropdown-context";
import ExplorerTemplate from "./template";

const Explorer = () => {
	return (
		<ExplorerDropdownProvider>
			<ExplorerTemplate />
		</ExplorerDropdownProvider>
	);
};

export default Explorer;