import React, {createContext, useContext, useEffect, useState} from 'react';
import {IStrategies} from "../../state/api/strategies/types";
import useGetCollections, {UseGetCollectionsResponse} from "../../pages/trending/hook/use-get-collections";
import {useAppSelector} from "../../state/hooks";

interface IDeleteGroupConfig {
	isActive: boolean
	group: IStrategies | undefined
}

interface TrendingContext {
	deleteGroupConfig: [IDeleteGroupConfig, React.Dispatch<IDeleteGroupConfig>]
	collections: UseGetCollectionsResponse
	isGroupActivated: boolean
}

const TrendingContext = createContext<TrendingContext | null>(null)

interface TrendingProviderProps {
	children?: React.ReactNode
}

export const TrendingProvider = ({children}: TrendingProviderProps) => {
	const deleteGroupConfig = useState<IDeleteGroupConfig>({
		isActive: false,
		group: undefined
	})
	
	const { searchedValue } = useAppSelector(state => state.search)
	const { trendingSort } = useAppSelector(state => state.sort)
	const { trendingPeriod } = useAppSelector(state => state.timePeriod)
	const { activeStrategies } = useAppSelector(state => state.user)
	const { rowsAmount, currentPage } = useAppSelector(state => state.pagination)
	
	const [isGroupActivated, setIsGroupActivated] = useState<boolean>(!!activeStrategies.length)
	
	const collections = useGetCollections({
		activeStrategies,
		trendingPeriod,
		searchedValue,
		trendingSort,
		currentPage,
		rowsAmount,
	})
	
	useEffect(() => {
		setIsGroupActivated(!!activeStrategies.length)
	}, [activeStrategies]);
	
	return (
		<TrendingContext.Provider
			value={{
				deleteGroupConfig,
				isGroupActivated,
				collections,
			}}
		>
			{children}
		</TrendingContext.Provider>
	)
}

export const useTrending = () => {
	const context = useContext(TrendingContext)
	
	if (context ===  null) {
		throw new Error("useTrending must be used within a TrendingProvider")
	}
	return context
}
