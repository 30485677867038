export enum TooltipPosition {
	LEFT = 'left',
	RIGHT = 'right',
	TOP = 'top',
	BOTTOM = 'bottom'
}

export enum SortState {
	toLow = -1,
	toHigh = 1,
	default = 0
}