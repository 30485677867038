import React, {useLayoutEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../state/hooks";
import {useNavigate} from "react-router-dom";
import {setIsListCreated} from "../../../../features/createStrategy/createListSlice";
import classNames from "classnames";

import Spinner from "../../../../components/spinner/Spinner";

import {ReactComponent as CrossIcon} from '../../../../images/icon-cross.svg'

import allRoutes from '../../../../config/router/routes/allRoutes.json'

import styles from './styles/styles.module.css'
import {generateLink} from "../../../exploreStrategy/helper";

const LoadingContent = () => (
	<>
		<h1 className={styles.modal_title}>Loading!</h1>
		<div className={styles.modal_loading_content_container}>
			<Spinner sizeRem={3} />
			<p className={styles.modal_content}>Please wait...</p>
		</div>
	</>
)


const SuccessContent = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	
	const {createdListId} = useAppSelector(state => state.createList)
	const onTwitterShare = () => {
		if (!createdListId) return
		
		dispatch(setIsListCreated(undefined))
		
		const text = encodeURIComponent('I’ve created a Group of Smart Money with @‌bulltor_ai. Join me on BULLTOR.\n\n')
		const link = generateLink(createdListId)
		
		window.open(`https://twitter.com/intent/tweet?text=${text}&url=${link}`, "_blank");
	}
	
	return (
		<>
			<h1 className={styles.modal_title}>Success!</h1>
			<p className={styles.modal_content}>
				Now your Group is available at the Main Page.
			</p>
			<div className={styles.modal_button_container}>
				<div
					className={classNames(styles.modal_button, styles.modal_button_main)}
					onClick={() => {
						navigate(allRoutes.Trending)
						dispatch(setIsListCreated(undefined))
					}}
				>
					<span>main page</span>
				</div>
				<div
					className={classNames(styles.modal_button, styles.modal_button_more)}
					onClick={() => onTwitterShare()}
				>
					<span>Share on Twitter</span>
				</div>
			</div>
			<CrossIcon
				className={styles.modal_close}
				onClick={() => dispatch(setIsListCreated(undefined))}
			/>
		</>
	)
}

const Modal = () => {
	const { isListCreating } = useAppSelector(state => state.createList)

	useLayoutEffect(() => {
		document.body.style.overflowY = 'hidden'
		
		return () => {
			document.body.style.overflowY = 'scroll'
		}
	}, [])
	
	return (
		<div className={styles.modal_overlay}>
			<div className={styles.modal}>
				{
					!isListCreating
						? <SuccessContent />
						: <LoadingContent />
				}
			</div>
		</div>
	)
};

export default Modal;
