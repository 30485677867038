import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../state/hooks";

import {trendingHeaderValues} from "../config";

import HeaderItem from "./HeaderItem";
import Pagination from "../../../components/pagination/Pagination";

import {setCurrentPage, setRowsAmount} from "../../../features/pagination/paginationSlice";
import {getCollectionBadges} from "../helper";

import styles from '../styles/trending.module.css'
import {setTrendingSort} from "../../../features/sort/sortSlice";
import {SortState} from "../../../enum";
import Skeleton from "../../../components/skeleton/Skeleton";
import {SkeletonType} from "../../../components/skeleton/emun";
import TableRow from "./table-row";
import classNames from "classnames";
import {useTrending} from "../../../lib/context/trending-context";
import PeriodBar from "./PeriodBar";
import settingIcon from "../../../images/icon-setting.svg";

const TrendingTable = () => {
	const dispatch = useAppDispatch()
	
	const { searchedValue } = useAppSelector(state => state.search)
	const { trendingSort } = useAppSelector(state => state.sort)
	const { activeStrategies } = useAppSelector(state => state.user)
	const { rowsAmount } = useAppSelector(state => state.pagination)
	
	const {collections, isGroupActivated} = useTrending()
	
	
	useEffect(() => {
		dispatch(setRowsAmount(20))
		dispatch(setTrendingSort({
			sortedField: trendingHeaderValues()[3].id,
			sortType: SortState.toLow
		}))
	}, [])
	
	useEffect(() => {
		dispatch(setCurrentPage(1))
	}, [activeStrategies, searchedValue])
	
	useEffect(() => { // toggle sort according to group activation
		if (activeStrategies.length === 0 && trendingSort.sortedField === "avgPrice")
			dispatch(setTrendingSort({
				sortType: trendingSort.sortType,
				sortedField: "floorValue",
			}))
		if (activeStrategies.length !== 0 && trendingSort.sortedField === "floorValue")
			dispatch(setTrendingSort({
				sortType: trendingSort.sortType,
				sortedField: "avgPrice",
			}))
	}, [activeStrategies])
	
	return (
		<div className={styles.trending_collections_container}>
			<div className={styles.trending_collection_header}>
				<div className={styles.trending_collection_header_tabs}>
					<h3 className={classNames(styles.trending_collection_title, styles.collection_title_active)}>
						{
							isGroupActivated ? "PORTFOLIO & ACTIVITY OVERVIEW" : "Trending NFT Collections"
						}
					</h3>
				</div>
				<div className={styles.trending_collection_header_config}>
					<PeriodBar />
					<div className={styles.trending_collection_settings}>
						<img src={settingIcon} alt={''}/>
					</div>
				</div>
			</div>
			<div className={styles.trending_collections}>
				<div className={styles.tab}>
					<div className={classNames(styles.tab_header_trending, {
							[styles['tab_header_trending-default']]: activeStrategies.length === 0,
							[styles['tab_header_trending-active']]: activeStrategies.length !== 0,
						})
					}>
						{
							trendingHeaderValues(
								!!activeStrategies.length
							).map((value, index) => (
								<HeaderItem
									headerValues={value}
									key={index}
								/>
							))
						}
					</div>
					<div className={styles.body}>
						{
							collections.isLoading &&
							<Skeleton type={SkeletonType.Table} amount={rowsAmount}/>
							||
							collections.isError &&
							<div className={styles.body_row}>
								<span className={styles.body_notification}>Updating database, please wait</span>
							</div>
							||
							collections.isSuccess && collections.data.list.map((item) => (
								<TableRow
									id={item.id}
									key={item.id}
									name={item.name}
									logo={item.logo}
									buys={item.stats.buys ?? 0}
									openseaUrl={item.openseaLink}
									sales={item.stats.sales ?? 0}
									volume={item.stats.volume ?? 0}
									floorPrice={item.stats.floorValue}
									transfers={item.stats.transfers ?? 0}
									listings={item.stats.listingsPercent ?? 0}
									twitterScore={item.stats.twitterScore ?? 0}
									volumeDelta={item.stats.volumeChanged ?? 0}
									salesChanged={item.stats.salesChanged ?? 0}
									volumePercent={item.stats.volumePercent ?? 0}
									floorPriceMin={item.stats.floorPriceMin ?? 0}
									floorPriceAvg={item.stats.floorPriceAvg ?? 0}
									floorPriceDelta={item.stats.floorChanged ?? 0}
									salesBuysDelta={item.stats.salesBuysDelta ?? 0}
									listingsDelta={item.stats.listingsChanged ?? 0}
									floorPriceChart={item.stats.floorPriceChart ?? [[]]}
									twitterScoreDelta={item.stats.twitterScoreChanged ?? 0}
									strategyBadge={getCollectionBadges({
										activeStrategies: activeStrategies,
										filtersWithThisCollection: item.filtersWithThisCollection
									}) ?? []}
								/>
							))
						}
						{
							!collections.isError
							&& <Pagination />
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrendingTable;
