import React from "react";
import classNames from "classnames";
import {intToString, trimValue} from "../../../../lib/helper";
import Tooltip from "../../../../components/tooltip/Tooltip";
import styles from '../../styles/collection.module.css'
import {useAppSelector} from "../../../../state/hooks";
import Chart from "../Chart";
import {Mixpanel} from "../../../../utils/mixpanel";
import {TrackedEventsName} from "../../../../utils/mixpanel/enum";
import Badge from "./components/badge";
import Default from "./components/row-content/default";
import Empty from "./components/row-content/empty";
import WithActiveGroup from "./components/row-content/with-active-group";
import ValueWithDelta from "./components/value-with-delta";

function Button(
	{
		label,
		outline,
		style,
		...attributes
	}: {
			label: string;
			outline: string;
			style?: React.CSSProperties;
		} & React.ButtonHTMLAttributes<HTMLButtonElement>
){
	return (
		<button
			style={{borderColor: outline, ...style}}
			className={styles['button']}
			role='button'
			{...attributes}
		>
			{label}
		</button>
	)
}

export interface NftCollectionProps {
	id: string
	name: string
	logo: string
	buys: number
	sales: number
	volume: number
	listings: number
	transfers: number
	floorPrice: number
	openseaUrl: string
	volumeDelta: number
	salesChanged: number
	twitterScore: number
	listingsDelta: number
	floorPriceAvg: number
	floorPriceMin: number
	volumePercent: number
	salesBuysDelta: number
	strategyBadge: string[]
	floorPriceDelta: number
	floorPriceChart: number[][]
	twitterScoreDelta: number
}

const TableRow = (nftCollection : NftCollectionProps) =>{
	const {activeStrategies} = useAppSelector(state => state.user)

	const {
		id,
		logo,
		name,
		buys,
		sales,
		volume,
		listings,
		transfers,
		openseaUrl,
		twitterScore,
		strategyBadge,
		floorPriceAvg,
		salesBuysDelta,
		floorPriceMin,
		floorPriceChart,
		twitterScoreDelta,
	} = {
		...nftCollection,
		twitterScoreDelta: nftCollection.twitterScore === 0 ? 0 : nftCollection.twitterScoreDelta
	}
	
	const isActiveStrategies =
		activeStrategies.length !== 0
	
	const isActiveGroupEmpty =
		!volume && !floorPriceAvg && !floorPriceMin && !buys && !transfers && !sales && !salesBuysDelta && isActiveStrategies
	
	function buyHandler(){
		Mixpanel.track(TrackedEventsName.ClickBuy, {[TrackedEventsName.ClickBuy]: id})
		window.open(`${openseaUrl}?utm_source=bulltor`, "_blank");
	}
	
	return (
		<div className={
			classNames(
				styles['nft-collection'],
				{
					[styles['nft-collection-empty']]: (isActiveGroupEmpty && isActiveStrategies),
					[styles['nft-collection-active']]: (!isActiveGroupEmpty && isActiveStrategies),
					[styles['nft-collection-default']]: !isActiveStrategies
				}
			)
		}
		>
			<div
				style={{justifyContent: 'start'}}
				className={styles['nft-collection__column']}
			>
				<div className={styles['nft-collection__column__logo-container']}>
					<img
						className={styles['nft-collection__column__logo']}
						src={logo}
						alt={''}
					/>
				</div>
				<div className={styles['nft-collection__column__name-container']}>
					<Tooltip active={name.length > 30} content={name}>
						<div className={styles.text}>
							{trimValue(name, 30)}
						</div>
					</Tooltip>
					{
						strategyBadge.length !== 0 &&
							<Badge
								badges={strategyBadge}
							/>
					}
				</div>
			</div>
			{
				!isActiveStrategies ? <Default collection={nftCollection} />
				: isActiveGroupEmpty ? <Empty /> : <WithActiveGroup collection={nftCollection} />
			}
			{/*<div className={styles['nft-collection__column']}>*/}
			{/*	<ValueWithDelta delta={twitterScoreDelta}>*/}
			{/*		<Tooltip content={twitterScore.toString()}>*/}
			{/*			<span className={styles['value-with-delta__main-text']}>*/}
			{/*				{intToString(twitterScore, false)}*/}
			{/*			</span>*/}
			{/*		</Tooltip>*/}
			{/*	</ValueWithDelta>*/}
			{/*</div>*/}
			<div className={styles['nft-collection__column']}>
				<Chart
					collectionId={id}
					floorPriceChart={floorPriceChart}
				/>
			</div>
			<div className={styles['nft-collection__column']}>
				<Button
					label='buy'
					outline='#E72247'
					style={{width: "7.1rem"}}
					onClick={buyHandler}
				/>
			</div>
		</div>
	)
}

export default TableRow;
