import React, {useRef} from 'react';
import {useNavigate} from "react-router-dom";
import classNames from "classnames";

import allRoutes from '../../../config/router/routes/allRoutes.json'

import {IStrategies} from "../../../state/api/strategies/types";
import {TrackedEventsName} from "../../../utils/mixpanel/enum";

import {Mixpanel} from "../../../utils/mixpanel";
import {addActiveStrategy, removeActiveStrategy} from "../../../features/user/userSlice";
import {useAppDispatch} from "../../../state/hooks";

import {ReactComponent as CrossIcon} from "../../../images/icon-cross.svg";

import styles from '../styles/trending.module.css'
import {useTrending} from "../../../lib/context/trending-context";

interface IProp {
	userAddress: `0x${string}` | undefined
	isActive: boolean
	strategy: IStrategies
}

const FilterItem = ({strategy, isActive, userAddress}: IProp) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const ref = useRef<HTMLDivElement>(null)
	
	const {deleteGroupConfig} = useTrending()
	
	const activateHandler = (e: any) => {
		if (e.target instanceof Element && ref.current && ref.current.contains(e.target)) {
			return
		}
		if (!isActive) {
			Mixpanel.track(TrackedEventsName.ActivateStrategy, {[TrackedEventsName.ActivateStrategy]: strategy.status})
			dispatch(addActiveStrategy(strategy))
			return
		}
		dispatch(removeActiveStrategy(strategy))
	}
	
	const exploreTable = () => {
		Mixpanel.track(TrackedEventsName.ExploreStrategy, {[TrackedEventsName.ExploreStrategy]: strategy.name})
		navigate(`${allRoutes.Trending}/explore-group/${strategy.id}`)
	}
	
	const onDelete = () => {
		deleteGroupConfig[1]({
			group: strategy,
			isActive: true
		})
	}
	
	const showCrossIcon = userAddress === undefined ? false : (userAddress.toLowerCase() === strategy.creator)
	
	return (
		<div
			onClick={(event) => activateHandler(event)}
			className={
				(isActive)
				    ? classNames(styles.trending_list_item, styles.checked_list)
				    : styles.trending_list_item
			}
		>
			<span>{strategy.name}</span>
			<div
				className={styles.trending_list_item_container}
				ref={ref}
			>
				<span
					className={styles.trending_list_item_amount}
					onClick={exploreTable}
				>
					(
					<span>
						{strategy.includedAddresses}
					</span>
					)
				</span>
				{
					showCrossIcon &&
					<CrossIcon
						className={styles.trending_list_item_cross}
						onClick={onDelete}
					/>
				}
			</div>
		</div>
	);
};

export default FilterItem;
