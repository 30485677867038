import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../state/hooks";

import {setStage} from "../../../../features/createStrategy/createListSlice";
import {createStateMap} from "../../map";
import {CreateState} from "../../enum";

import styles from '../../styles/createlist.module.css'

const Create = () => {
	const { stage, selectAllAddresses, filter } = useAppSelector(state => state.createList)
	const { totalAmount } = useAppSelector(state => state.pagination)
	
	const dispatch = useAppDispatch()
	
	const getTotalAddresses = () => {
		if (selectAllAddresses && !filter.excludedAddresses.length) {
			return totalAmount
		}
		if (selectAllAddresses && !!filter.excludedAddresses.length) {
			return totalAmount - filter.excludedAddresses.length
		}
		else return filter.addresses.length
	}
	
	return (
		<div className={styles.create_wrapper}>
			<div className={styles.addresses_wrapper} onClick={() => dispatch(setStage(CreateState.initial))}>
				<span className={styles.create_text}>Total addresses</span>
				<span className={styles.addresses}>{getTotalAddresses()}</span>
			</div>
			{ createStateMap[stage] }
		</div>
	);
};

export default Create;
