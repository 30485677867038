import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BACKEND_URL} from "../../../utils/config";
import {IStrategies} from "../strategies/types";
import {IResponse} from "../../../interfaces";
import {RootState} from "../../store";
import {GetFollowedFiltersArgsProps} from "./types";

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BACKEND_URL,
		prepareHeaders: (headers, {getState}) => {
			const token = (getState() as RootState).auth.token
			
			if (token) {
				headers.set('authorization', `Bearer ${token}`)
			}
			
			return headers
		}
	}),
	endpoints: (builder) => ({
		getUserFilters: builder.query({
			query: () => '/user-filters',
			transformResponse: (v, meta, arg) => (v as IResponse<IStrategies[]>).data
		}),
		getFollowedFilters: builder.query({
			query: (args: GetFollowedFiltersArgsProps) =>
				`/user/followed-filters?withoutDefault=${args.withoutDefault}`,
			transformResponse: (v, meta, arg) => (v as IResponse<IStrategies[]>).data
		})
	})
})

export const {
	useGetUserFiltersQuery,
	useGetFollowedFiltersQuery,
	useLazyGetUserFiltersQuery,
	useLazyGetFollowedFiltersQuery,
} = userApi