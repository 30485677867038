import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './state/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import '@rainbow-me/rainbowkit/styles.css';
import {
    connectorsForWallets,
    darkTheme,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { metaMaskWallet } from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import {BrowserRouter} from "react-router-dom";

const { chains, provider } = configureChains(
    [mainnet],
    [
        publicProvider()
    ]
);

const connectors = connectorsForWallets([
    {
        groupName: 'Suggested',
        wallets: [
            metaMaskWallet({chains})
        ]
    }
])

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})


const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <>
    <Provider store={store}>
        <BrowserRouter>
            <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider theme={darkTheme({
                    borderRadius: 'medium',
                })} chains={chains}>
                    <App />
                </RainbowKitProvider>
            </WagmiConfig>
        </BrowserRouter>
    </Provider>
  </>
);

reportWebVitals();
