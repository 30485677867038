import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { BACKEND_URL } from '../../../utils/config'
import {IGetPopularStrategies} from "./interface";
import {IResponse} from "../../../interfaces";
import {RootState} from "../../store";
import {
	IExploredHoldersData,
	IHoldersWithExploredGroupsArgs,
	IHoldersWithExploredGroupsBody,
	IMergeStrategiesBody,
	IPopularStrategies,
	IStrategies
} from "./types";
import {CreateStrategyBody, IFilter} from "../../../features/createStrategy/interface";

const strategyUrl = '/filters'

export const strategiesApi = createApi({
	reducerPath: 'popularFilters',
	baseQuery: fetchBaseQuery({
		baseUrl: BACKEND_URL + strategyUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token
			if (token) {
				headers.set('authorization', `Bearer ${token}`)
			}
		}
	}),
	endpoints: (builder) => ({
		getStrategyById: builder.query({
			query: ({id}: {id: string}) => `/${encodeURIComponent(id)}`,
			transformResponse: (v) => (v as IResponse<IStrategies>).data
		}),
		getPopularStrategies: builder.query({
			query: ({limit, filters, offset, name}: IGetPopularStrategies) =>
				`/popular
				?offset=${offset}
				&filters=${encodeURIComponent(JSON.stringify(filters))}
				&limit=${limit}
				${name ? `&name=${encodeURIComponent(name)}` : ''}`,
			transformResponse: (v) => (v as IResponse<IPopularStrategies>).data
		}),
		getDefaultStrategies: builder.query({
			query: () => `/default`,
			transformResponse: (v) => (v as IResponse<IStrategies[]>).data
		}),
		getExploredHolders: builder.mutation({
			query: ({arg, body}: {arg: IHoldersWithExploredGroupsArgs, body: IHoldersWithExploredGroupsBody}) => ({
				url: `/explore?offset=${arg.offset}&limit=${arg.limit}&filterIds=${encodeURIComponent(arg.filterIds)}&filter=${encodeURIComponent(JSON.stringify(arg.filter))}`,
				method: 'POST',
				body: body
			}),
			transformResponse: (v) =>
				(v as IResponse<IExploredHoldersData>).data
			}),
		createStrategy: builder.mutation({
			query:  (body: CreateStrategyBody ) => ({
				url: ``,
				method: 'POST',
				body: body
			})
		}),
		deleteStrategy: builder.mutation({
			query: (id: string) => ({
				url: `${encodeURIComponent(id)}`,
				method: "DELETE",
			})
		}),
		updateStrategy: builder.mutation({
			query:  ({id, body}: {id: string, body: IFilter} ) => ({
				url: `${encodeURIComponent(id)}`,
				method: 'PUT',
				body: body
			})
		}),
		mergeStrategy: builder.mutation({
			query:  (body: IMergeStrategiesBody ) => ({
				url: `/merge`,
				method: 'POST',
				body: body
			})
		}),
		followStrategy: builder.mutation({
			query: (body: { filterId: string }) => ({
				url: `/follow`,
				method: 'POST',
				body: body
			})
		}),
		unfollowStrategy: builder.mutation({
			query: (body: { filterId: string }) => ({
				url: `/unfollow`,
				method: 'POST',
				body: body
			})
		}),
	}),
})

export const {
	useGetStrategyByIdQuery,
	useMergeStrategyMutation,
	useDeleteStrategyMutation,
	useCreateStrategyMutation,
	useUpdateStrategyMutation,
	useFollowStrategyMutation,
	useUnfollowStrategyMutation,
	useLazyGetStrategyByIdQuery,
	useGetDefaultStrategiesQuery,
	useGetPopularStrategiesQuery,
	useGetExploredHoldersMutation,
	useLazyGetPopularStrategiesQuery,
} = strategiesApi
