import {IHeaderValues} from "../interface";

export const mintHeaderValues: IHeaderValues[] = [
	{
		id: 'name',
		name: 'Collection Name',
		sortable: true
	},
	{
		id: 'mints',
		name: 'Mints',
		sortable: true,
	},
	{
		id: 'totalMints',
		name: 'Total Mints',
		sortable: true,
	},
	{
		id:'mintPrice',
		name: 'Mint Price',
		sortable: true,
	},
	{
		id:'twitterScore',
		name: 'Twitter Score',
		sortable: true,
	},
	{
		id: 'Mint Trend Chart',
		name: 'Mint Trend Chart',
		sortable: false,
	},
	{
		id: 'action',
		name: 'Action',
		sortable: false,
	}
]

export const periodMint = [
	{
		id: '5min',
		value: '5M'
	},
	{
		id: '1h',
		value: '1H'
	},
	{
		id: '6h',
		value: '6H'
	},
	{
		id: '1d',
		value: '1D'
	}
]