import React from 'react';
import styles from "../../../../styles/collection.module.css";
import ValueWithDelta from "../value-with-delta";
import Tooltip from "../../../../../../components/tooltip/Tooltip";
import {NftCollectionProps} from "../../index";
import {useAppSelector} from "../../../../../../state/hooks";
import {intToString} from "../../../../../../lib/helper";

interface DefaultProps {
	collection: Pick<Required<NftCollectionProps>, "sales"
		| "volume"
		| "listings"
		| "floorPrice"
		| "volumeDelta"
		| "salesChanged"
		| "listingsDelta"
		| "floorPriceDelta"
	>
}

const Default = ({collection}: DefaultProps) => {
	const { ethRate} = useAppSelector(state => state.user)
	
	const {
		sales,
		volume,
		listings,
		floorPrice,
		volumeDelta,
		salesChanged,
		listingsDelta,
		floorPriceDelta,
	} = {
		...collection,
		volumeDelta: collection.volume === 0 ? 0 : collection.volumeDelta,
		salesChanged: collection.sales === 0 ? 0 : collection.salesChanged,
		listingsDelta: collection.listings === 0 ? 0 : collection.listingsDelta,
		floorPriceDelta: collection.floorPrice === 0 ? 0 : collection.floorPriceDelta
	}
	
	return (
		<>
			<div className={styles['nft-collection__column']}>
				<ValueWithDelta delta={floorPriceDelta}>
					<Tooltip content={`${(floorPrice * ethRate).toFixed(2)} $`}>
						<span className={styles['value-with-delta__main-text']}>
							{intToString(floorPrice, true)}
						</span>
					</Tooltip>
				</ValueWithDelta>
			</div>
			<div className={styles['nft-collection__column']}>
				<ValueWithDelta delta={volumeDelta}>
					<Tooltip content={`${(volume).toFixed(4)} $`}>
						<span className={styles['value-with-delta__main-text']}>
							{intToString(volume / ethRate, true)}
						</span>
					</Tooltip>
				</ValueWithDelta>
			</div>
			<div className={styles['nft-collection__column']}>
				<ValueWithDelta delta={salesChanged}>
					<Tooltip content={sales.toString()}>
						<span className={styles['value-with-delta__main-text']}>
							{intToString(sales, false)}
						</span>
					</Tooltip>
				</ValueWithDelta>
			</div>
			<div className={styles['nft-collection__column']}>
				<ValueWithDelta delta={listingsDelta}>
					<Tooltip content={(listings * 100).toString()}>
						<span className={styles['value-with-delta__main-text']}>
							{`${intToString(listings * 100, false)} %`}
						</span>
					</Tooltip>
				</ValueWithDelta>
			</div>
		</>
	);
};

export default Default;
