import React, {useEffect, useState} from 'react';
import useHeaderItem from "../hook/useHeaderItem";

import Tooltip from "../../../components/tooltip/Tooltip";

import {useAppSelector} from "../../../state/hooks";

import {getSortIcon} from '../../../lib/helper';

import {IHeaderValues} from "../interface";
import {SortState, TooltipPosition} from "../../../enum";

import TooltipIcon from '../../../images/icon-tooltip.svg'

import styles from "../styles/trending.module.css"

interface IProp {
	headerValues: IHeaderValues,
}

const HeaderItem = ({headerValues}: IProp) => {
	const {
		clickHandler,
	} = useHeaderItem(headerValues)
	
	const { trendingSort } = useAppSelector(state => state.sort)

	const [sortIcon, setSortIcon] = useState(getSortIcon[SortState.default])
	
	useEffect(() => {
		if (trendingSort.sortedField !== headerValues.id) {
			setSortIcon(getSortIcon[SortState.default])
			return
		}

		setSortIcon(getSortIcon[trendingSort.sortType])

	}, [headerValues.id, trendingSort])

	return (
		<div
			onClick={() => clickHandler()}
			role='button'
			className={styles.tab_header_item}
		>
			{
				headerValues.sortable && (
					<img className={styles.tab_header_item_sort} src={sortIcon} alt={''} />
				)
			}
			<span>{headerValues.name}</span>
			{
				headerValues.icon && (
					<img className={styles.tab_header_item_icon} src={headerValues.icon} alt={''}/>
				)
			}
			{
				headerValues.tooltip && (
					<Tooltip
						active={true}
						style={{position: TooltipPosition.RIGHT}}
						content={headerValues.tooltip}
					>
						<img src={TooltipIcon} alt={'tooltip'} className={styles.tab_header_tooltip} />
					</Tooltip>
				)
			}
		</div>
	);
};

export default HeaderItem;
