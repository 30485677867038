import React, {useEffect} from 'react';

import styles from './styles/pagination.module.css'
import PageSelector from "./PageSelector";
import RowsSelector from "./RowsSelector";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {setCurrentPage} from "../../features/pagination/paginationSlice";

const Pagination = () => {
	const { rowsAmount, currentPage, totalAmount } = useAppSelector(state => state.pagination)
	
	return (
		<div className={styles.pagination_container}>
			<h4 className={styles.pagination_info}>
				Showing {(currentPage - 1) * rowsAmount + 1}-
				{
					(currentPage * rowsAmount <= totalAmount)
						? currentPage * rowsAmount
						: totalAmount
				} out of {totalAmount}
			</h4>
			<PageSelector />
			<RowsSelector />
		</div>
	);
};

export default Pagination;