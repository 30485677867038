import React from 'react';

import styles from '../styles/createlist.module.css'

const Steps = () => {
	return (
		<div className={styles['steps_container']}>
			<div className={styles['steps_item_wrapper']}>
				<span className={styles['steps_item_number']}>1</span>
				<p className={styles['steps_item_title']}>Set dynamic parameters</p>
			</div>
			<div className={styles['steps_hr_wrapper']}>
				<div className={styles['steps_hr']}></div>
			</div>
			<div className={styles['steps_item_wrapper']}>
				<span className={styles['steps_item_number']}>2</span>
				<p className={styles['steps_item_title']}>Use Explorer to add addresses manually</p>
			</div>
			<div className={styles['steps_hr_wrapper']}>
				<div className={styles['steps_hr']}></div>
			</div>
			<div className={styles['steps_item_wrapper']}>
				<span className={styles['steps_item_number']}>3</span>
				<p className={styles['steps_item_title']}>Complete and share your Group</p>
			</div>
		</div>
	);
};

export default Steps;