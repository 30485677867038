import twitterIcon from '../../../images/social_twitter.svg'
import discordIcon from '../../../images/social_discord.svg'

export const socialMap = [
	{
		icon: twitterIcon,
		url: 'https://twitter.com/bulltor_ai'
	},
	{
		icon: discordIcon,
		url: 'https://discord.com/invite/Pqf7gpasas '
	},
	// {
	// 	icon: gitIcon,
	// 	url: 'https://discord.com/'
	// }
]