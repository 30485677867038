import React from 'react';

import { ConnectButton as Button} from '@rainbow-me/rainbowkit';
import {useAppSelector} from "../../../state/hooks";

import metamaskLogo from '../../../images/metamask_logo.svg'

import styles from '../styles/header.module.css'

const ConnectButton = () => {
	const { isWalletConnected } = useAppSelector(state => state.auth)
	
	return (
		<Button.Custom>
			{({
				  account,
				  chain,
				  openAccountModal,
				  openChainModal,
				  openConnectModal,
				  authenticationStatus,
				  mounted,
			  }) => {
				const ready = mounted && authenticationStatus !== 'loading';
				const connected =
					isWalletConnected &&
					ready &&
					account &&
					chain &&
					(!authenticationStatus ||
						authenticationStatus === 'authenticated');
				
				return (
					<div
						className={styles.connect_wallet_container}
						{...(!ready && {
							'aria-hidden': true,
							'style': {
								opacity: 0,
								pointerEvents: 'none',
								userSelect: 'none',
							},
						})}
					>
						{(() => {
							if (!connected) {
								return (
									<button
										className={styles.connect_wallet_button}
										onClick={() => {
											openConnectModal()
										}}
										type="button"
									>
										Connect Wallet
									</button>
								);
							}
							
							if (chain.unsupported) {
								return (
									<button
										className={styles.connect_wallet_button}
										onClick={openChainModal}
										type="button"
									>
										Wrong network
									</button>
								);
							}
							
							return (
								<button
									className={styles.address_button}
									onClick={openAccountModal}
									type="button"
								>
									<img src={metamaskLogo} alt={''}/>
									{account.displayName}
								</button>
							);
						})()}
					</div>
				);
			}}
		</Button.Custom>
	
	);
};

export default ConnectButton;