import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import classNames from "classnames";

import {periodMint} from "../config";

import { setMintPeriod } from "../../../features/timePeriod/timePeriodSlice";

import styles from '../../trending/styles/trending.module.css'


const PeriodBar = () => {
	const dispatch = useAppDispatch()
	const { mintPeriod } = useAppSelector(state => state.timePeriod)
	
	const clickHandler = (id: string) => {
		dispatch(setMintPeriod(id))
	}
	
	return (
		<div className={styles.periodBar_container}>
			{
				Object.values(periodMint).map(({value, id}) => (
					<div
						onClick={() => clickHandler(id)}
						className={(mintPeriod === id)
							? classNames(styles.periodBar_item, styles.periodBar_item_active)
							: styles.periodBar_item
						}
						key={id}
					>
						<span>
							{ value }
						</span>
					</div>
				))
			}
		</div>

	);
};

export default PeriodBar
