import React from 'react';
import Header from "../../components/header/Header";
import styles from "../trending/styles/trending.module.css";
import classNames from "classnames";
import PeriodBar from "./components/PeriodBar";
import settingIcon from "../../images/icon-setting.svg";
import MintTable from "./components/MintTable";

const Mint = () => {
	return (
		<>
			<Header />
			<div className={styles.trending_container}>
				<div className={styles.trending_header}>
					<h1 className={styles.trending_title}>EXCLUSIVE INSIGHTS INTO MOVES OF TOP NFT PLAYERS</h1>
					<p className={styles.trending_description}>
						<span>Track <span style={{color: '#FD0808', fontWeight: 900}}>300k</span> High-Performing Traders and Alpha Groups</span>
						<span style={{color: '#9342FF'}}>and/or</span>
						<span>Create your own Group of addresses based on Advanced On-chain Data</span>
					</p>
				</div>
				<div className={styles.trending_lists_container}>
					<div className={styles.trending_collections_container}>
						<div className={styles.trending_collection_header}>
							<div className={styles.trending_collection_header_tabs}>
								<h3 className={classNames(styles.trending_collection_title, styles.collection_title_active)}>Mint</h3>
							</div>
							<div className={styles.trending_collection_header_config}>
								<PeriodBar />
								<div className={styles.trending_collection_settings}>
									<img src={settingIcon} alt={''}/>
								</div>
							</div>
						</div>
						<div className={styles.trending_collections}>
							<MintTable />
						</div>
					</div>
				</div>
			</div>
		
		</>
	);
};

export default Mint;
