import React, {useEffect} from 'react'
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate, useLocation
} from 'react-router-dom'

import allRoutes from './routes/allRoutes.json'
import navigationMap from './routes/routesMap'

const Router = () => {
	return (
		<>
				<Routes>
					{
						Object.values(allRoutes).map((route, index) => (
							<Route
								key={index}
								path={route}
								element={navigationMap[route]}
							/>
						))
					}
						<Route
							path={"*"}
							element={
								<Navigate
									to={allRoutes.Trending}
									replace
								/>
							}
						/>
				</Routes>
		</>
	);
};

export default Router
