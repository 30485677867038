import React from 'react';
import styles from "../../explorer.module.css";
import {useExplorerDropdownActions} from "../../../../../../lib/context/explorer-dropdown-context";

const FetchMore = () => {
	const {
		onFetchMoreHandler
	} = useExplorerDropdownActions()
	
	return (
		<div className={styles.body_item_wrapper}>
			<div className={styles.body_item_button_wrapper}>
				<div className={styles.body_item}>
					<button
						onClick={() => onFetchMoreHandler()}
						className={styles.body_item_button}
					>
						Fetch more...
					</button>
				</div>
			</div>
		</div>
	);
};

export default FetchMore;