import React from 'react';
import {useAppSelector} from "../../../../state/hooks";
import HeaderItem from "./HeaderItem";
import Pagination from "../../../../components/pagination/Pagination";
import HolderRow from "./HolderRow";
import {holdersHeaderValues} from "../../config";
import styles from '../../styles/table.module.css'
import HoldersInteraction from "../../../../components/holdersInteraction/HoldersInteraction";
import Skeleton from "../../../../components/skeleton/Skeleton";
import {SkeletonType} from "../../../../components/skeleton/emun";
import Explorer from "../explorer";
import {useTableActions} from "../../../../lib/context/table-create-context";

const ExplorerTable = () => {
	const {
		filter,
		selectAllAddresses
	} = useAppSelector(state => state.createList)
	
	const {
		rowsAmount,
		totalAmount
	} = useAppSelector(state => state.pagination)
	
	const {
		page,
		holdersQuery,
		onCheckedHandler,
		setIsAddressSelected,
		displayHoldersInteraction,
	} = useTableActions()
	
	const {isError, data, isLoading, isSuccess} = holdersQuery
	
	return (
		<div className={styles.table_container}>
			<div className={styles.title_container}>
				<div className={styles.explorer_container}>
					<h3 className={styles.explorer_title}>Explorer:</h3>
					<Explorer />
				</div>
			</div>
			{
				displayHoldersInteraction()
				&& <HoldersInteraction/>
			}
			<div>
				<div className={styles.header}>
					<div className={styles.header_item}>
						<span>#</span>
					</div>
					<div className={styles.header_item}>
						<input
							onChange={() => onCheckedHandler()}
							type={'checkbox'}
							checked={
								(selectAllAddresses && !filter.excludedAddresses.length) ||
								(filter.addresses.length === totalAmount)
							}
							readOnly={true}
						/>
					</div>
					{
						holdersHeaderValues.map(({value, id, icon, tooltip}, index) => (
							<HeaderItem
								id={id}
								tooltip={tooltip}
								value={value}
								icon={icon}
								key={index}
							/>
						))
					}
				</div>
				<div className={styles.body}>
					{
						isLoading
						&& <Skeleton type={SkeletonType.Table} amount={rowsAmount} />
						||
						isError &&
						<div className={styles.body_row}>
							<span className={styles.body_notification}>Updating database, please wait</span>
						</div>
						||
						<div>
							{
								(isSuccess && data.list.length !== 0)
									? data.list.map(({pnlPercent, address, trades, mints, nft, holdingValueEth, collectionCount, ensName, pnlEth}, index) =>
										<HolderRow
											isChecked={setIsAddressSelected(address)}
											holdingValue={holdingValueEth}
											collections={collectionCount}
											address={address}
											trades={trades}
											mints={mints}
											index={(index + 1 + (rowsAmount * (page - 1)))}
											nft={nft}
											pnlPercent={pnlPercent}
											pnlEth={pnlEth}
											key={index}
											ensName={ensName}
										/>
									)
									: (
										<div className={styles.body_row}>
											<span className={styles.body_notification}>No holders with such filters</span>
										</div>
									)
							}
						</div>
					}
					{
						totalAmount &&
						<Pagination />
					}
				</div>
			</div>
		</div>
	);
};

export default ExplorerTable;
