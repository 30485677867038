import {BACKEND_URL} from "../../../utils/config";

import {IResponse} from "../../../interfaces";
import {IData, IFiltersBody, IHolder} from "./types";
import {IStrategies} from "../strategies/types";

import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";
import {IFilter} from "../../../features/createStrategy/interface";
import {isObjectEmpty} from "../../../lib/helper";

const rout = '/holders'

interface IGetHolders {
	address?: string,
	id?: string,
	orderFilters: object,
	offset: number,
	limit: number
}

interface ISearchHolders {
	address: string;
}

const getHoldersUrl = (agr: IGetHolders) => {
	const {id, orderFilters, limit, offset, address} = agr
	
	
	if (address)
		return `?offset=${offset}&address=${encodeURIComponent(address)}&limit=${limit}&orderFilters=${encodeURIComponent(JSON.stringify(orderFilters))}`
	if (id)
		return `?offset=${offset}&id=${encodeURIComponent(id)}&limit=${limit}&orderFilters=${encodeURIComponent(JSON.stringify(orderFilters))}`
	
	return`?offset=${offset}&limit=${limit}&orderFilters=${encodeURIComponent(JSON.stringify(orderFilters))}`
}

export const holdersApi = createApi({
	reducerPath: 'holders',
	baseQuery: fetchBaseQuery({baseUrl: BACKEND_URL + rout}),
	endpoints: (builder) => ({
		getHolders: builder.mutation({
			query: ({agr, body}: { agr: IGetHolders, body: IFiltersBody }) => ({
				url: getHoldersUrl(agr),
				method: 'POST',
				body: body
			}),
			transformResponse: (v, meta, arg) => (v as IResponse<IData>).data
		}),
	}),
	
})

export const { useGetHoldersMutation} = holdersApi