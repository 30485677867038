import {useState} from "react";
import {useLazyGetCurrencyRateQuery} from "../../state/api/currencyRate/currencyRate.api";
import {useAppDispatch} from "../../state/hooks";
import {setEthRate} from "../../features/user/userSlice";
import {setNotifyModalActive, setNotifyModalContent} from "../../features/notify/notifySlice";

interface ICurrency {
	rate: number,
	lastUpdate: number
}

const useGetCurrencyRate = () => {
	const dispatch = useAppDispatch()
	
	const [getRate] = useLazyGetCurrencyRateQuery()
	
	const dispatchNotifyModal = (content: string) => {
		dispatch(setNotifyModalActive(true))
		dispatch(setNotifyModalContent(content))
	}
	
	const getCurrencyRate = () => {
		let currencyString = localStorage.getItem('currencyRate')
		
		if (currencyString) {
			const currencyRate: ICurrency = (JSON.parse(currencyString))
			
			if ((Date.now() - currencyRate.lastUpdate) < (24 * 60 * 60 * 1000)) {
				dispatch(setEthRate(currencyRate.rate))
				
				return
			}
		}
		
		getRate({})
		.unwrap()
		.catch(() => {
			dispatchNotifyModal('cant get eth rate')
			
			dispatch(setEthRate(1900))
		})
		.then((res) => {
			if (res) {
				dispatch(setEthRate(res))
				
				const currencyInfo: ICurrency = {
					rate: res,
					lastUpdate: Date.now()
				}
				
				localStorage.setItem('currencyRate', JSON.stringify(currencyInfo))
			}
			else {
				dispatchNotifyModal('cant get eth rate')
				
				dispatch(setEthRate(1900))
			}
		})
	}
	
	return {
		getCurrencyRate
	}
}

export default useGetCurrencyRate
