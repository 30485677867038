import {CreateState} from "../enum";

import InitialBody from "../components/create/InitialBody";
import ModalBody from "../components/create/ModalBody";
import FinalBody from "../components/create/FinalBody";

export const createStateMap = {
	[CreateState.initial]: <InitialBody />,
	[CreateState.PnL]: <ModalBody type={CreateState.PnL} />,
	[CreateState.UnPlN]: <ModalBody type={CreateState.UnPlN} />,
	[CreateState.WalletAge]: <ModalBody type={CreateState.WalletAge}/>,
	[CreateState.Finish]: <FinalBody />
}