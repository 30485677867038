import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import {
	setAddresses, setExcludedAddresses,
	setFilter, setOnAddAndCreate,
	setSelectAll,
	setSelectAllWithValue,
	setStage, setExploredGroup
} from "../../../features/createStrategy/createListSlice";

import allRoutes from "../../../config/router/routes/allRoutes.json";
import {
	clearHoldersInteraction, setExcludedHolders, setFilters,
	setIsStrategyEdited,
	setSelectAllHolders, setSelectedAddresses, setShowAll
} from "../../../features/holdersInteraction/holdersInteractionSlice";
import {CreateState} from "../../../pages/createList/enum";
import {useEffect, useState} from "react";
import {
	useMergeStrategyMutation,
} from "../../../state/api/strategies/strategies.api";
import {callNotifyModal} from "../../../features/notify/notifySlice";
import {deleteEmptyFieldsInObj} from "../../../utils";

interface UseHoldersInteractionResponse {
	getSelectedAddressesAmount: () => number
	isCreateAndAddVisible: boolean
	isCreateAndAddBlocked: boolean
	isAddToGroupBlocked: boolean
	isAddToGroupVisible: boolean
	isSelectAllVisible: boolean
	exploredStrategyId: string
	isShowAllVisible: boolean
	onAddToStrategy: (toID: string) => Promise<void>
	addToGroupTitle: string
	onCreateAndAdd: () => void
	isResetVisible: boolean
	onSelectAll: () => void
	onShowAll: () => void
	showAll: boolean
	onReset: () => void
}

const useHoldersInteraction = (): UseHoldersInteractionResponse => {
	const dispatch = useAppDispatch()
	const params = useParams()
	
	
	const [
		addToGroupTitle,
		setAddToGroupTitle
	] = useState<string>("add to group")
	
	const {
		totalAmount
	} = useAppSelector(state => state.pagination)
	const {
		filter ,
		selectAllAddresses
	} = useAppSelector(state => state.createList)
	const {
		filters,
		showAll,
		excludedHolders,
		selectAllHolders,
		isStrategyEdited,
		exploredStrategy,
		selectedAddresses,
		exploredStrategyId,
		selectedAddressesAmount,
	} = useAppSelector(state => state.holdersInteraction)
	
	const location = useLocation()
	const navigate = useNavigate()
	
	const [ mergeStrategy ] = useMergeStrategyMutation()
	
	const onReset = () => {
		if (allRoutes.CreateLists === location.pathname) {
			dispatch(setSelectAll(true))

			return
		}
		
		if (exploredStrategy === undefined) {
			console.error('explored strategy not provided')
			
			return
		}
		
		dispatch(setIsStrategyEdited(false))
		dispatch(setSelectAllHolders(
			showAll
				? (exploredStrategy.isStatic === false) && !exploredStrategy.excludedAddresses?.length
				// проверяет все ли холдеры группы попадают под фильтр
				: true
			// всегда истина так как список холдеров ограничен самой группой
		))
		
		dispatch(setFilters(deleteEmptyFieldsInObj(exploredStrategy.filters)))
		dispatch(setExcludedHolders(exploredStrategy.excludedAddresses ?? []))
		dispatch(setSelectedAddresses([]))
	}
	
	const onSelectAll = () => {
		if (allRoutes.CreateLists === location.pathname) {
			dispatch(setSelectAll(true))
			return
		}
		
		dispatch(setIsStrategyEdited(true))
		dispatch(setSelectAllHolders(true))
	}
	
	const onCreateAndAdd = async () => {
		if (allRoutes.CreateLists === location.pathname) {
			if (filter.filters === undefined) dispatch(setFilter({}))
			dispatch(setStage(CreateState.Finish))
			
			return
		}
		
		if (!selectAllHolders && excludedHolders.length === 0 && selectedAddresses.length === 0) {
			dispatch(callNotifyModal('You should select at list one holder'))
			
			return
		}
		
		dispatch(setFilter({}))
		
		dispatch(setExploredGroup([exploredStrategyId]))
		dispatch(setSelectAllWithValue(selectAllHolders || excludedHolders.length !== 0))
		dispatch(setExcludedAddresses(excludedHolders))
		dispatch(setAddresses(selectedAddresses))
		
		dispatch(setOnAddAndCreate(true))
		dispatch(setStage(CreateState.Finish))
		
		navigate(allRoutes.CreateLists)
	}
	
	const onShowAll = () => {
		dispatch(setShowAll(!showAll))
	}
	
	const isAddToGroupVisible: boolean = (location.pathname !== allRoutes.CreateLists)
	const isAddToGroupBlocked: boolean = isAddToGroupVisible && (!selectAllHolders && excludedHolders.length === 0 && selectedAddresses.length === 0)
	
	const isCreateAndAddVisible: boolean =(location.pathname !== allRoutes.CreateLists)
	const isCreateAndAddBlocked: boolean = isCreateAndAddVisible && (!selectAllHolders && excludedHolders.length === 0 && selectedAddresses.length === 0)
	
	const isSelectAllVisible: boolean = (
		(location.pathname === allRoutes.CreateLists && !(selectAllAddresses && !filter.excludedAddresses.length)) ||
		(location.pathname !== allRoutes.CreateLists && !(selectAllHolders && !excludedHolders.length))
	)
	
	
	const isResetVisible: boolean = (location.pathname !== allRoutes.CreateLists) && isStrategyEdited
	
	const isShowAllVisible: boolean = false
	
	const onAddToStrategy = async (toID: string) => {
		const fromID = params.id
		
		if (!selectAllHolders && excludedHolders.length === 0 && selectedAddresses.length === 0) {
			dispatch(callNotifyModal('You should select at list one holder'))
			
			return
		}
		
		if (fromID === undefined) {
			dispatch(callNotifyModal('From id is undefined'))
			
			return
		}
		
		setAddToGroupTitle("Pending...")
		
		mergeStrategy({
			from: fromID,
			to: toID,
			userFilter: {
				name: "name",
				addresses: (selectedAddresses.length !== 0) ? selectedAddresses : undefined,
				excludedAddresses: (excludedHolders.length !== 0) ? excludedHolders : undefined,
				filters: filters
			}
		})
		.unwrap()
		.then(() => {
			setAddToGroupTitle("Success ✔️")
		})
		.catch(() => {
			setAddToGroupTitle("Error ❌")
		})
		.finally(() => {
			setTimeout(() => setAddToGroupTitle("add to group"), 2000)
		})
	}
	
	const getSelectedAddressesAmount = (): number => {
		if (allRoutes.CreateLists === location.pathname) {
			if (selectAllAddresses && !filter.excludedAddresses.length) {
				return totalAmount
			}
			if (selectAllAddresses && !!filter.excludedAddresses.length) {
				return totalAmount - filter.excludedAddresses.length
			}
			else return filter.addresses.length
		}
		
		return selectedAddressesAmount
	}
	
	useEffect(() => {dispatch(clearHoldersInteraction())}, [])
	
	return {
		getSelectedAddressesAmount,
		isCreateAndAddVisible,
		isCreateAndAddBlocked,
		isAddToGroupBlocked,
		isAddToGroupVisible,
		isSelectAllVisible,
		exploredStrategyId,
		isShowAllVisible,
		onAddToStrategy,
		addToGroupTitle,
		onCreateAndAdd,
		isResetVisible,
		onSelectAll,
		onShowAll,
		showAll,
		onReset
	}
}

export default useHoldersInteraction
