import {useEffect, useRef, useState} from "react";
import {IData} from "../../../state/api/holders/types";
import {useGetHoldersMutation} from "../../../state/api/holders/holders.api";
import {useGetExploredHoldersMutation} from "../../../state/api/strategies/strategies.api";
import {ISort} from "../../../features/sort/interface";
import {IFilters} from "../../../features/holdersInteraction/interface";
import {useAppDispatch} from "../../../state/hooks";
import {setTotalAmount} from "../../../features/pagination/paginationSlice";
import {setAddresses, setSelectAll, setWatchedCollections} from "../../../features/createStrategy/createListSlice";
import {debounce} from "lodash";

interface UseGetHoldersProps {
	currentPage: number
	filters?: IFilters
	rowsAmount: number
	holdersSort: ISort
	exploredGroups: string[]
	contractAddresses: string
}

interface LoadingResponseType {
	isError: false,
	isLoading: true,
	isSuccess: false,
	data: undefined
}

interface ErrorResponseType {
	isError: true,
	isLoading: false,
	isSuccess: false,
	data: undefined
}

interface SuccessResponseType {
	isError: false
	isLoading: false
	isSuccess: true
	data: IData
}

export type UseGetHoldersResponse = SuccessResponseType | LoadingResponseType | ErrorResponseType

const LoadingResponse: LoadingResponseType = {
	data: undefined,
	isSuccess: false,
	isLoading: true,
	isError: false
}

const ErrorResponse: ErrorResponseType = {
	isError: true,
	isLoading: false,
	isSuccess: false,
	data: undefined
}


const useGetHolders = (props: UseGetHoldersProps): UseGetHoldersResponse => {
	const {
		filters,
		rowsAmount,
		currentPage,
		holdersSort,
		exploredGroups,
		contractAddresses,
	} = props
	
	const dispatch = useAppDispatch()

	const [response, setResponse] = useState<UseGetHoldersResponse>(LoadingResponse)
	const [
		debouncedExploredGroups,
		setDebouncedExploredGroups
	] = useState<string[]>(exploredGroups)
	
	const debounceSetExploredGroups = useRef(
		debounce((groups: string[]) => setDebouncedExploredGroups(groups), 5000)
	).current
	
	const [getDefaultHolders] = useGetHoldersMutation() // holders without explored group
	const [getExploredHolders] = useGetExploredHoldersMutation()
	
	const fetchExploredHolders = ({exploredGroups, holdersSort, filters, rowsAmount, currentPage}: Omit<UseGetHoldersProps, "contractAddresses">) => {
		getExploredHolders({
			arg: {
				limit: rowsAmount,
				offset: (currentPage - 1) * rowsAmount,
				filter: {
					[holdersSort.sortedField]: holdersSort.sortType
				},
				filterIds: exploredGroups.join(",")
			},
			body: {
				userFilter: {
					name: "",
					filters: filters
				},
				showAll: false
			}
		})
			.unwrap()
			.then((res) => {
				const {total, holders} = res
				
				setResponse({
					isError: false,
					isLoading: false,
					isSuccess: true,
					data: {
						list: holders,
						total: total
					}
				})
				
				dispatch(setWatchedCollections([])) // clear watched collection
				dispatch(setTotalAmount(total))
			})
			.catch(() => setResponse(ErrorResponse))
	}
	
	const fetchDefaultHolders = ({holdersSort, contractAddresses, filters, rowsAmount, currentPage}: Omit<UseGetHoldersProps, "exploredGroups">) => {
		getDefaultHolders({
			agr: {
				address: contractAddresses,
				orderFilters: {
					[holdersSort.sortedField]: holdersSort.sortType
				},
				offset: (currentPage - 1) * rowsAmount,
				limit: rowsAmount
			},
			body: {
				userFilter: (!!filters || contractAddresses.length) ? {
					name: '',
					filters: filters,
					addresses: [],
				} : undefined
			}
		})
			.unwrap()
			.then((res) => {
				const {total, list, isCollection, collectyionId} = res
				
				setResponse({
					isError: false,
					isLoading: false,
					isSuccess: true,
					data: res
				})
				
				dispatch(setTotalAmount(total))
				
				if (isCollection && !!collectyionId) dispatch(setWatchedCollections([collectyionId])) // check is watched collection
				else {
					dispatch(setWatchedCollections([]))
					
					if (!!contractAddresses) {
						dispatch(setSelectAll(false))
						dispatch(setAddresses(list.map(item => item.address)))
					}
				}
				
			})
			.catch(() => {
				setResponse(ErrorResponse)
			})
	}
	
	const getHolders = (isGroupsExploring: boolean, props: UseGetHoldersProps) => {
		isGroupsExploring
			? fetchExploredHolders(props)
			: fetchDefaultHolders(props)
	}
	
	const debouncedGetHolders = useRef(
		debounce((isGroupsExploring: boolean, props: UseGetHoldersProps) => getHolders(isGroupsExploring, props), 300)
	).current
	
	useEffect(() => {
		return () => {
			debouncedGetHolders.cancel()
			debounceSetExploredGroups.cancel()
		}
	}, [debouncedGetHolders, debounceSetExploredGroups])
	
	useEffect(() => {
		setResponse(LoadingResponse)
		
		debounceSetExploredGroups(exploredGroups)
	}, [exploredGroups])
	
	useEffect(() => {
		setResponse(LoadingResponse)
		
		debouncedGetHolders(
			!!exploredGroups.length,
			{...props, exploredGroups: debouncedExploredGroups}
		)
	}, [filters, contractAddresses, holdersSort, rowsAmount, currentPage, debouncedExploredGroups])
	
	return response
}

export default useGetHolders;
