import React from 'react';
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../state/hooks";
import {useGetDefaultStrategiesQuery} from "../../../state/api/strategies/strategies.api";

import Tooltip from "../../../components/tooltip/Tooltip";
import FilterItem from "./FilterItem";
import Skeleton from "../../../components/skeleton/Skeleton";

import {isStrategyActive} from "../helper";
import {tooltipsContent} from "../config";

import {TooltipPosition} from "../../../enum";
import {SkeletonType} from "../../../components/skeleton/emun";

import allRoutes from "../../../config/router/routes/allRoutes.json";

import toolTipIcon from "../../../images/icon-tooltip.svg";
import plusIcon from "../../../images/icon-plus.svg";

import styles from '../styles/trending.module.css'
import {useAccount} from "wagmi";
import {useTrending} from "../../../lib/context/trending-context";

const Strategies = () => {
	const navigate = useNavigate()
	
	const { address } = useAccount()
	
	const { isWalletConnected, isLoading } = useAppSelector(state => state.auth)
	const { followedFilters, activeStrategies } = useAppSelector(state => state.user)
	const { collections } = useTrending()
	
	const defaultStrategy = useGetDefaultStrategiesQuery({})
	
	return (
		<>
			<div className={styles.lists_title_wrapper}>
				<h2 className={styles.trending_lists_title}>{'Activate Smart Money Group to track:'}</h2>
				<Tooltip
					style={{position: TooltipPosition.RIGHT}}
					content={tooltipsContent.pickStrategyToFollow}
					active={true}
				>
					<img className={styles.tooltip} src={toolTipIcon} alt={''} />
				</Tooltip>
			</div>
			<div className={styles.trending_lists}>
				{
					(isLoading || followedFilters.isLoading || defaultStrategy.isLoading || collections.isLoading)
						&& (
							<>
								<Skeleton type={SkeletonType.Strategy} amount={5} />
							</>
						)
					|| (
						defaultStrategy.isSuccess && !defaultStrategy.data.length && !followedFilters.data.length
							&& (
								<span style={{'color': '#fff'}}>No available Groups yet.</span>
							)
					)
					|| (
						defaultStrategy.isError
							&& <span style={{'color': '#fff'}}>Updating database, please wait</span>
					)
					|| (
						defaultStrategy.isSuccess
						&& <>
							{
								defaultStrategy.data.concat(isWalletConnected ? followedFilters.data : [])
								.map((value, index) =>
									<FilterItem
										strategy={value}
										userAddress={address}
										isActive={isStrategyActive(value, activeStrategies)}
										key={index}
									/>
								)
							}
							{
								isWalletConnected
								&& (
									<div
										onClick={() => navigate(allRoutes.CreateLists)}
										className={styles.trending_lists_create_list}
									>
										<img src={plusIcon} alt={''} />
										<span>CREATE Group</span>
									</div>
								)
							}
						</>
					)
				}
				<div className={styles.trending_lists_create_list_wrapper}></div>
			</div>
		</>
	);
};

export default Strategies;
