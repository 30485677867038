import {IBound, ICreateListState} from "./interface";
import {CreateState} from "../../pages/createList/enum";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFilters} from "../holdersInteraction/interface";

const initialState: ICreateListState = {
	exploredGroups: [],
	contractAddresses: '',
	createdListId: undefined,
	onAddAndCreate: false,
	isListCreating: false,
	selectAllAddresses: true,
	stage: CreateState.initial,
	filter: {
		name: '',
		watchedCollections: [],
		addresses: [],
		excludedAddresses: [],
		filters: undefined
	}
}

export const createListSlice = createSlice({
	name: 'createList',
	initialState,
	reducers: {
		setName: (state, action: PayloadAction<string>) => {
			state.filter.name = action.payload
		},
		setPln: (state, action: PayloadAction<IBound | 'any'>) => {
			if (!state.filter.filters) {
				if (action.payload === 'any') {
					state.filter.filters = {}
					
					return
				}
				
				state.filter.filters = {
					pnlPercent: action.payload
				}
				
				return
			}
			
			if (action.payload === 'any') {
				delete state.filter.filters.pnlPercent
				
				return
			}
			
			state.filter.filters.pnlPercent = action.payload
		},
		setUnPln: (state, action: PayloadAction<IBound | 'any'>) => {
			if (!state.filter.filters) {
				if (action.payload === 'any') {
					state.filter.filters = {}
					
					return
				}
				
				state.filter.filters = {
					unPnlPercent: action.payload
				}
				
				return
			}
			
			if (action.payload === 'any') {
				delete state.filter.filters.unPnlPercent
				
				return
			}
			
			state.filter.filters.unPnlPercent = action.payload
		},
		setWalletAge: (state, action: PayloadAction<IBound | 'any'>) => {
			if (!state.filter.filters) {
				if (action.payload === 'any') {
					state.filter.filters = {}
					
					return
				}
				
				state.filter.filters = {
					walletAge: action.payload
				}
				
				return
			}
			
			if (action.payload === 'any') {
				delete state.filter.filters.walletAge
				
				return
			}
			
			state.filter.filters.walletAge = action.payload
		},
		setStage: (state, action: PayloadAction<CreateState>) => {
			state.stage = action.payload
		},
		setWatchedCollections: (state, action: PayloadAction<string[]>) => {
			state.filter.watchedCollections = [...action.payload]
		},
		setSelectAllWithValue: (state, action: PayloadAction<boolean>) => {
			state.selectAllAddresses = action.payload
		},
		setFilter: (state, action: PayloadAction<IFilters | undefined>) => {
			if (action.payload === undefined) {
				state.filter.filters = {}
			}
			state.filter.filters = action.payload
		},
		setSelectAll: (state, action: PayloadAction<boolean>) => {
			state.selectAllAddresses = action.payload
			state.filter.addresses = []
			state.filter.excludedAddresses = []
		},
		setAddresses: (state, action: PayloadAction<string[]>) => {
			state.filter.addresses = [...action.payload]
		},
		addAddress: (state, action: PayloadAction<string>) => {
			state.filter.addresses.push(action.payload)
		},
		removeAddress: (state, action: PayloadAction<string>) => {
			if (state.filter) {
				const index = state.filter.addresses.findIndex((address) => {
					return action.payload === address
				})
				
				if (index === -1) {
					return
				}
				
				state.filter.addresses.splice(index, 1)
			}
		},
		setExcludedAddresses: (state, action: PayloadAction<string[]>) => {
			state.filter.excludedAddresses = [...action.payload]
		},
		addExcludedAddress: (state, action: PayloadAction<string>) => {
			state.filter.excludedAddresses.push(action.payload)
		},
		removeExcludedAddress: (state, action: PayloadAction<string>) => {
			if (state.filter) {
				const index = state.filter.excludedAddresses.findIndex((address) => {
					return action.payload === address
				})
				
				if (index === -1) {
					return
				}
				
				state.filter.excludedAddresses.splice(index, 1)
			}
		},
		
		clearFilter: (state) => {
			state.exploredGroups = []
			state.filter.name = ''
			state.filter.addresses = []
			state.filter.excludedAddresses = []
			state.contractAddresses = ''
			state.filter.description = ''
			state.filter.filters = {}
		},
		setDescription: (state, action: PayloadAction<string>) => {
			state.filter.description = action.payload
		},
		setListCreating: (state) => {
			state.isListCreating = true
		},
		setIsListCreated: (state, action: PayloadAction<string | undefined>) => {
			state.isListCreating = false
			state.createdListId = action.payload
		},
		setContractAddress: (state, action: PayloadAction<string>) => {
			state.contractAddresses = action.payload
		},
		setOnAddAndCreate: (state, action: PayloadAction<boolean>) => {
			state.onAddAndCreate = action.payload
		},
		addExploredGroup: (state, action: PayloadAction<string>) => {
			state.exploredGroups = [...state.exploredGroups, action.payload]
		},
		setExploredGroup: (state, action: PayloadAction<string[]>) => {
			state.exploredGroups = action.payload
		},
		removeExploredGroup: (state, action: PayloadAction<string>) => {
			const index = state.exploredGroups.findIndex((address) => {
				return action.payload === address
			})
			
			if (index === -1) {
				return
			}
			
			state.exploredGroups.splice(index, 1)
		}
	}
})

export const {
	setPln,
	setName,
	setStage,
	setUnPln,
	setFilter,
	addAddress,
	clearFilter,
	setAddresses,
	setSelectAll,
	setWalletAge,
	removeAddress,
	setDescription,
	setListCreating,
	addExploredGroup,
	setIsListCreated,
	setExploredGroup,
	setOnAddAndCreate,
	setContractAddress,
	addExcludedAddress,
	removeExploredGroup,
	setExcludedAddresses,
	removeExcludedAddress,
	setWatchedCollections,
	setSelectAllWithValue,
} = createListSlice.actions

export default createListSlice.reducer
