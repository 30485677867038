import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import {ISort} from "../../../features/sort/interface";
import {IHeaderValues} from "../interface";
import {SortState} from "../../../enum";
import {setTrendingSort} from "../../../features/sort/sortSlice";
import {Mixpanel} from "../../../utils/mixpanel";
import {TrackedEventsName} from "../../../utils/mixpanel/enum";

const useHeaderItem = (headerValues: IHeaderValues) => {
	const dispatch = useAppDispatch()
	
	const { trendingSort } = useAppSelector(state => state.sort)
	const { activeStrategies } = useAppSelector(state => state.user)
	
	const clickHandler = () => {
		if (!headerValues.sortable) {
			return;
		}
		
		const res: ISort = {
			sortedField: "",
			sortType: SortState.default
		}
		
		if (trendingSort.sortedField !== headerValues.id) {
			res.sortType = SortState.toLow
			res.sortedField = headerValues.id
			
			dispatch(setTrendingSort(res))
			
			Mixpanel.track(TrackedEventsName.ActivateFilter, {[TrackedEventsName.ActivateFilter]: {filter: res, page: 'Explore strategy'}})
			
			return;
		}
		
		if (trendingSort.sortType === SortState.toLow){
			res.sortType = SortState.toHigh
		} else {
			res.sortType = SortState.toLow
		}
		
		res.sortedField = headerValues.id
		
		Mixpanel.track(TrackedEventsName.ActivateFilter, {[TrackedEventsName.ActivateFilter]: {filter: res, page: 'Trending'}})
		dispatch(setTrendingSort(res))
	}
	
	return {
		clickHandler
	}
}

export default useHeaderItem