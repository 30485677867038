import {createAsyncThunk} from "@reduxjs/toolkit";
import {restApiRequest} from "../../utils";
import {BACKEND_URL} from "../../utils/config";
import {disconnect, signMessage} from '@wagmi/core'
import {Mixpanel} from '../../utils/mixpanel'
import {TrackedEventsName} from "../../utils/mixpanel/enum";

export const getJwt = createAsyncThunk(
	'auth/getJwt',
	async (address: string) => {
		interface IJwt {
			token: string,
			lastUpdate: number
		}
	
		let jwtString = localStorage.getItem('jwt')
		
	
		if (jwtString) {
			try {
				let jwt: IJwt = JSON.parse(jwtString)
				
				if (
					(!!jwt.lastUpdate) &&
					((Date.now() - jwt.lastUpdate) < (24 * 60 * 60 * 1000)) &&
					(Date.now() > jwt.lastUpdate)
				) {
					return jwt.token
				}
			} catch (e) {
				await disconnect()
				Mixpanel.track(TrackedEventsName.SignInFailed, {
					[TrackedEventsName.SignInFailed]: {address, reason: "incorrect type of jwt"}
				})
				console.error("incorrect type of jwt")
			}
			
		}
		
		const originalMsg = await restApiRequest(`${BACKEND_URL}/auth/nonce`, 'POST', {address: address})
		
		let encodedMsg
		
		try {
			encodedMsg = await signMessage({
				message: originalMsg
			})
		} catch (error: any) {
			await disconnect()
			Mixpanel.track(TrackedEventsName.SignInFailed, {[TrackedEventsName.SignInFailed]: {address, reason: error.message}})
			console.error(error.message)
		}
		
		const token = await
			restApiRequest(
				`${BACKEND_URL}/auth/jwt`,
				'POST',
				{
					encodedMsg: encodedMsg,
					originalMsg: originalMsg
				}
			)
		
		if (!token) {
			await disconnect()
			Mixpanel.track(TrackedEventsName.SignInFailed, {[TrackedEventsName.SignInFailed]: {address, reason: 'cant get jwt'}})
			throw new Error('cant get jwt')
		}
		
		const jwt: IJwt = {
			token,
			lastUpdate: Date.now()
		}
		
		localStorage.setItem('jwt', JSON.stringify(jwt))
		
		Mixpanel.track(TrackedEventsName.SignInSuccess, {[TrackedEventsName.SignInSuccess]: address})
		Mixpanel.identify(address)
		
		return jwt.token
	}
)