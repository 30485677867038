import ethIcon from '../../../images/icon-eth.svg'
import {ITableHeaderValues} from "../interfaces"

export const popularListsHeaderValues: ITableHeaderValues[] = [
	{
		id: '#',
		name: '#',
		sortable: false
	},
	{
		id: 'name',
		name: 'Group Name',
		sortable: true,
	},
	{
		id: 'avgPnl',
		name: 'PnL %',
		sortable: true,
		tooltip: 'PnL % (Profit and Loss %) = (PnL / ETH spent to purchase NFTs in portfolio) * 100'
	},
	{
		id: 'avgUnPnl',
		name: 'uPnL %',
		sortable: true,
		tooltip: 'uPnL % (Profit and Loss %) = (uPnL / ETH spent to purchase NFTs in portfolio) * 100'
	},
	{
		id: 'followersCount',
		name: 'Followers',
		sortable: true,
	},
	{
		id: 'includedAddresses',
		name: 'Addresses',
		sortable: true,
	},
	{
		id: 'lastUpdate',
		name: 'Last Update',
		sortable: true,
	},
	{
		id: 'creator',
		name: 'Creator',
		sortable: true,
	},
	{
		id: 'action',
		name: 'Action',
		sortable: false,
	}
]
