import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from "../../../state/hooks";

import {getSortIcon} from "../../../lib/helper";

import {ISort} from '../../../features/sort/interface';
import {IHeaderValues} from "../interface";

import {setMintSort} from "../../../features/sort/sortSlice";

import {SortState} from "../../../enum";

import styles from "../../trending/styles/trending.module.css"

interface IProp {
	headerValues: IHeaderValues,
}

const HeaderItem = ({headerValues}: IProp) => {
	const dispatch = useAppDispatch()
	const { mintSort } = useAppSelector(state => state.sort)

	const [sortIcon, setSortIcon] = useState(getSortIcon[SortState.default])
	
	const clickHandler = () => {
		if (!headerValues.sortable) {
			return;
		}

		const res: ISort = {
			sortedField: "",
			sortType: SortState.default
		}

		if (mintSort.sortedField !== headerValues.id) {
			res.sortType = SortState.toLow
			res.sortedField = headerValues.id

			dispatch(setMintSort(res))

			return;
		}

		if (mintSort.sortType === SortState.toLow){
			res.sortType = SortState.toHigh
		} else {
			res.sortType = SortState.toLow
		}

		res.sortedField = headerValues.id

		dispatch(setMintSort(res))
	}
	
	useEffect(() => {
		if (mintSort.sortedField !== headerValues.id) {
			setSortIcon(getSortIcon[SortState.default])
			return
		}

		setSortIcon(getSortIcon[mintSort.sortType])

	}, [headerValues.id, mintSort])

	return (
		<div
			onClick={() => clickHandler()}
			role='button'
			className={styles.tab_header_item}
		>
			{
				headerValues.sortable && (
					<img className={styles.tab_header_item_sort} src={sortIcon} alt={''} />
				)
			}
			<span>{headerValues.name}</span>
		</div>
	);
};

export default HeaderItem;
