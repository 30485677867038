import React from 'react';
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import {useNavigate} from "react-router-dom";
import {useConnectModal} from "@rainbow-me/rainbowkit";

import {IStrategies} from "../../../state/api/strategies/types";
import allRoutes from "../../../config/router/routes/allRoutes.json";

import styles from '../styles/popularLists.module.css'
import {setNotifyModalActive, setNotifyModalContent} from "../../../features/notify/notifySlice";
import {followersCountToggle} from "../../../features/user/userSlice";
import {useFollowStrategyMutation, useUnfollowStrategyMutation} from "../../../state/api/strategies/strategies.api";
import {Mixpanel} from "../../../utils/mixpanel";
import {TrackedEventsName} from "../../../utils/mixpanel/enum";
import {intToString} from "../../../lib/helper";

interface IProp {
	list: IStrategies,
}

const ListPreview = ({list}: IProp) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	
	const [followTrigger] = useFollowStrategyMutation()
	const [unfollowTrigger] = useUnfollowStrategyMutation()
	
	const { openConnectModal } = useConnectModal()
	
	const {isWalletConnected} = useAppSelector(state => state.auth)
	
	const {followedFilters, userAddress} = useAppSelector(state => state.user)
	
	const isFollowed = !!followedFilters.data.find(filter => filter.id === list.id)
	
	const clickHandler = () => {
		if (!isWalletConnected && openConnectModal) {
			dispatch(setNotifyModalContent('Please connect wallet'))
			dispatch(setNotifyModalActive(true))
			return
		}
		
		// if (userAddress === list.creator) return
		
		(!isFollowed)
			? followTrigger({filterId: list.id})
			.then(() => {
				dispatch(followersCountToggle({method: "follow", strategy: list}))
				Mixpanel.track(TrackedEventsName.FollowStrategy, {[TrackedEventsName.FollowStrategy]: list.name})
			})
			: unfollowTrigger({filterId: list.id})
			.then(() => {
				dispatch(followersCountToggle({method: "unfollow", strategy: list}))
				Mixpanel.track(TrackedEventsName.UnFollowStrategy, {[TrackedEventsName.UnFollowStrategy]: list.name})
			})
	}
	
	const exploreHandler = () => {
		Mixpanel.track(TrackedEventsName.ExploreStrategy, {[TrackedEventsName.ExploreStrategy]: list.name})
		navigate(`${allRoutes.PopularLists}/explore-group/${list.id}`)
	}
	
	return (
		<div className={styles.preview_item}>
			<div>
				<h3 className={styles.preview_title}>{list.name}</h3>
				<p className={styles.preview_description}>{list.description}</p>
			</div>
			{
				(list.includedAddresses !== list.addresses?.length) &&
				<span className={styles.preview_item_live}>LIVE</span>
			}
			<div>
				<div className={styles.preview_info_container}>
					<div className={styles.preview_info_wrapper}>
						<span className={styles.preview_info_title}>Addresses</span>
						<span className={styles.preview_info_content}>{list.includedAddresses}</span>
					</div>
					<div className={styles.preview_info_wrapper}>
						<span className={styles.preview_info_title}>Followers</span>
						<span className={styles.preview_info_content}>{list.followersCount}</span>
					</div>
					<div className={styles.preview_info_wrapper}>
						<span className={styles.preview_info_title}>uPnL</span>
						<span className={styles.preview_info_content}>
							{
								!!list.avgUnPnl
									? `${intToString((list.avgUnPnl ?? 0) * 100)} %`
									: 'No Data'
							}
						</span>
					</div>
					<div className={styles.preview_info_wrapper}>
						<span className={styles.preview_info_title}>PnL</span>
						<span className={styles.preview_info_content}>
							{
								!!list.avgPnl
									? `${intToString((list.avgPnl ?? 0) * 100)} %`
									: 'No Data'
							}
						</span>
					</div>
				</div>
				<div className={styles.preview_buttons_container}>
					<div
						className={isFollowed
							? classNames(styles.preview_button, styles.preview_button_active)
							: styles.preview_button}
						onClick={ clickHandler }
					>
						<span>
							{
								isFollowed
									? 'Followed'
									: 'Follow'
							}
						</span>
					</div>
					<div
						onClick={exploreHandler}
						className={styles.preview_button}
					>
						<span>Explore</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListPreview;
