import sortDefault from "../../../images/sort-default.svg"
import sortToHigh from "../../../images/sort-high.svg"
import sortToLow from "../../../images/sort-low.svg"
import {SortState} from "../../../enum";
import {IStrategies} from "../../../state/api/strategies/types";

export const getSortIcon = {
	[SortState.toHigh]: sortToHigh,
	[SortState.toLow]: sortToLow,
	[SortState.default]: sortDefault
}

export const isFollowed = (followedFilters: IStrategies[], id: string): boolean => {
	return !!followedFilters.find(filter => filter.id === id)
}