import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IPagination {
	currentPage: number,
	totalAmount: number,
	rowsAmount: number
}

const initialState: IPagination = {
	currentPage: 1,
	totalAmount: 0,
	rowsAmount: 20
}

export const paginationSlice = createSlice({
		name: 'pagination',
		initialState,
	    reducers: {
			setCurrentPage: (state, action: PayloadAction<number>) => {
				state.currentPage = action.payload
			},
		    setTotalAmount: (state, action: PayloadAction<number>) => {
				state.totalAmount = action.payload
		    },
		    setRowsAmount: (state, action: PayloadAction<number>) => {
				state.rowsAmount = action.payload
		    }
	    }
	}
)

export const { setCurrentPage, setTotalAmount, setRowsAmount } = paginationSlice.actions

export default paginationSlice.reducer
