import {useMemo, useState} from "react";
import {useAppSelector} from "../../../state/hooks";
import {useLazyGetUserFiltersQuery} from "../../../state/api/user/user.api";
import {useAccount} from "wagmi";

const useGetUserStrategies = () => {
	
	const [isContentPending, setIsContentPending] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	
	const {isConnected} = useAccount()
	
	
	const {
		userAddress,
	} = useAppSelector(state => state.user)
	
	const [getUserFiltersTrigger, getUserFilters] = useLazyGetUserFiltersQuery()
	
	const userFilters = useMemo(() => {
		if (!isConnected) {
			setIsError(true)
			setIsContentPending(false)
			setError('You should sign in for first')
		}
		if (getUserFilters.isError) {
			setIsError(true)
			setIsContentPending(false)
			setError('Updating database, please wait')
		}
		
		if (getUserFilters.isSuccess) {
			setIsError(false)
			setIsContentPending(false)

			return getUserFilters.data.filter((item) =>
				item.creator.toLowerCase() === userAddress.toLowerCase()
			)
		}
	}, [getUserFilters, isConnected])
	
	const onUserFiltersRefetch = () => {
		getUserFiltersTrigger({})
	}
	
	return {
		onUserFiltersRefetch,
		error,
		isError,
		isContentPending,
		userFilters
	}
}

export default useGetUserStrategies