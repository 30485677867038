import {useEffect} from "react";

interface UseOnOutsideClickProps {
	clickHandler: () => void
	isListening: boolean
	ref: React.RefObject<Element>
}

const useOnOutsideClick = ({clickHandler, ref, isListening}: UseOnOutsideClickProps) => {
	const onOutsideClick = (event: MouseEvent) => {
		if (!isListening) return
		
		if (!ref.current) return
		if (event.target instanceof Element && ref.current.contains(event.target)) return
		
		clickHandler()
	}
	
	useEffect(() => {
		document.addEventListener('click', onOutsideClick)
		
		return () => document.removeEventListener('click', onOutsideClick)
	}, [ref, clickHandler, isListening])
}

export default useOnOutsideClick
