import {ISearchState} from "./interface";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: ISearchState = {
	searchedValue: undefined
}

const searchSlice = createSlice({
	name: 'searchSlice',
	initialState,
	reducers: {
		setSearchedValue: (state, action: PayloadAction<string | undefined>) => {
			state.searchedValue = action.payload
		}
	}
})

export const {
	setSearchedValue
} = searchSlice.actions

export default searchSlice.reducer