import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../state/hooks";

import {ReactComponent as LinkIcon} from "../../../../images/icon-link.svg"

import styles from '../../styles/table.module.css'
import {intToString} from "../../../../lib/helper";
import Tooltip from "../../../../components/tooltip/Tooltip";
import {
	addExcludedHolders,
	addSelectedAddresses, removeExcludedHolders,
	removeSelectedAddresses, setSelectAllHolders
} from "../../../../features/holdersInteraction/holdersInteractionSlice";

interface IProp {
	isChecked: boolean,
	index?: number,
	address: string,
	pnlPercent: number,
	pnlEth: number,
	holdingValue?: number,
	nft?: number,
	collections?: number,
	trades?: number,
	mints?: number,
	ensName?: string,
	filterIds?: string[]
}

interface IValue {
	value?: number
	symbol?: string
}

const Value = ({ value, symbol = "" }: IValue) => {
	return (
		<>
			{
				!!value ?
					<Tooltip
						active={!(intToString(value) === (value?.toString()))}
						content={Number.isInteger(value) ? value.toString() : value.toFixed(4)}
					>
						<span>{`${intToString(value)} ${symbol}`}</span>
					</Tooltip> :
					<span>---</span>
			}
		</>
	)
}

interface IAddressItem {
	address: string,
	ensName?: string
}

const AddressItem = ({address, ensName}: IAddressItem) => {
	const [copyText, setCopyText] = useState('Copy address')
	
	useEffect(() => {
		if (copyText === 'Copied!') setTimeout(() => {setCopyText('Copy address')}, 1500)
	}, [copyText])
	
	async function copyHandler() {
		setCopyText('Copied!')
		await navigator.clipboard.writeText(address)
	}
	
	function exploreHandler(){
		window.open(`https://etherscan.io/address/${address}`, "_blank");
	}
	
	return (
		<>
			<Tooltip active={true} content={copyText}>
				<span onClick={copyHandler}>{ensName ?? address}</span>
			</Tooltip>
			<Tooltip active={true} content={'Explore holder'}>
				<LinkIcon onClick={exploreHandler} className={styles.link_icon }/>
			</Tooltip>
		</>
	)
}

const HolderRow = ({isChecked ,index, address, mints, nft, pnlEth, pnlPercent, trades, holdingValue, collections, ensName}: IProp) => {
	const dispatch = useAppDispatch()
	
	const { selectAllHolders, excludedHolders } = useAppSelector(state => state.holdersInteraction)

	const onToggle = () => {
		if (isChecked) {
			if (selectAllHolders || (excludedHolders.length !== 0)) {
				dispatch(setSelectAllHolders(false))
				dispatch(addExcludedHolders(address))
				
				return
			}
			
			dispatch(setSelectAllHolders(false))
			dispatch(removeSelectedAddresses(address))
			return
		}
		
		if (selectAllHolders || (excludedHolders.length !== 0)) {
			if (excludedHolders.length === 1) dispatch(setSelectAllHolders(true))
			else dispatch(setSelectAllHolders(false))
			
			dispatch(removeExcludedHolders(address))

			return
		}
		dispatch(setSelectAllHolders(false))
		dispatch(addSelectedAddresses(address))
	}
	
	return (
		<div className={styles.body_row}>
			<div className={styles.body_item} >
				<span>{index}</span>
			</div>
			<div className={styles.body_item}>
				<input readOnly={true} type={'checkbox'} checked={isChecked} onChange={() => onToggle()}/>
			</div>
			<div className={styles.body_item}>
				<AddressItem ensName={ensName} address={address}/>
			</div>
			<div className={styles.body_item}>
				<Value value={holdingValue}/>
			</div>
			<div className={styles.body_item}>
				<Value value={(pnlPercent) * 100} symbol="%"/>
			</div>
			<div className={styles.body_item}>
				<Value value={pnlEth}/>
			</div>
			<div className={styles.body_item}>
				<Value value={nft}/>
			</div>
			<div className={styles.body_item}>
				<Value value={collections}/>
			</div>
			<div className={styles.body_item}>
				<Value value={trades}/>
			</div>
			<div className={styles.body_item}>
				<Value value={mints}/>
			</div>
		</div>
	);
};

export default HolderRow;
