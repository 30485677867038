import React from 'react';
import {useAppSelector} from "../../../../../../state/hooks";
import {PeriodTrendingEnum} from "../../../../enum";
import styles from "../../../../styles/collection.module.css";

const Empty = () => {
	const { trendingPeriod } = useAppSelector(state => state.timePeriod)
	
	const currentTimeFrame = {
		[PeriodTrendingEnum.min5]: '5 minutes',
		[PeriodTrendingEnum.h1]: '1 hours',
		[PeriodTrendingEnum.h6]: '6 hours',
		[PeriodTrendingEnum.d1]: '1 day',
		[PeriodTrendingEnum.d7]: '7 days',
		[PeriodTrendingEnum.d30]: '30 days',
	}
	
	return (
		<div className={styles['nft-collection__column-empty']}>
			<span>There is no activity during {currentTimeFrame[trendingPeriod]} time frame</span>
		</div>
	);
};

export default Empty;