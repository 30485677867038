import React, {useState} from 'react';

import InitialBody from "./InitialBody";

import styles from '../../styles/createlist.module.css'
import {IStrategies} from "../../../../state/api/strategies/types";
import {CreateState} from "../../enum";
import ModalBody from "./ModalBody";

interface IProp {
	exploredStrategy: IStrategies
}


const Create = ({ exploredStrategy }: IProp) => {
	const [stage, setStage] = useState<CreateState>(CreateState.initial)
	
	const changeStage = (stage: CreateState) => {
		setStage(stage)
	}
	
	return (
		<div className={styles.create_wrapper}>
			<div className={styles.addresses_wrapper}>
				<span className={styles.create_text}>Total addresses</span>
				<span className={styles.addresses}>{exploredStrategy.includedAddresses}</span>
			</div>
			{
				stage === CreateState.initial
					? <InitialBody exploredStrategy={exploredStrategy} changeStage={changeStage} />
					: <ModalBody type={stage} changeStage={changeStage} />
			}
		</div>
	);
};

export default Create;
