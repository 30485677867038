import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../state/hooks";
import {setHoldersSort} from "../../../../features/sort/sortSlice";

import {getSortIcon} from "../../config";

import {ISort} from "../../../../features/sort/interface";
import {SortState, TooltipPosition} from "../../../../enum";
import {IHeaderValues} from "../../../createList/interface";

import Tooltip from "../../../../components/tooltip/Tooltip";

import tooltipIcon from "../../../../images/icon-tooltip.svg";

import styles from '../../styles/table.module.css'
import {Mixpanel} from "../../../../utils/mixpanel";
import {TrackedEventsName} from "../../../../utils/mixpanel/enum";

const HeaderItem = ({value, id, tooltip, icon}: IHeaderValues) => {
	const dispatch = useAppDispatch()
	const { holdersSort } = useAppSelector(state => state.sort)
	
	const [sortIcon, setSortIcon] = useState(getSortIcon[SortState.default])
	
	const clickHandler = () => {
		const res:ISort = {
			sortedField: "",
			sortType: SortState.toHigh
		}
		
		if (holdersSort.sortedField !== id) {
			res.sortedField = id
			res.sortType = SortState.toLow
			
			dispatch(setHoldersSort(res))
			Mixpanel.track(TrackedEventsName.ActivateFilter, {[TrackedEventsName.ActivateFilter]: {filter: res, page: 'Explore strategy'}})
			
			return;
		}
		
		if (holdersSort.sortType === SortState.toLow){
			res.sortType = SortState.toHigh
		} else {
			res.sortType = SortState.toLow
		}
		
		res.sortedField = id
		
		Mixpanel.track(TrackedEventsName.ActivateFilter, {[TrackedEventsName.ActivateFilter]: {filter: res, page: 'Explore strategy'}})
		dispatch(setHoldersSort(res))
	}
	
	useEffect(() => {
		if (holdersSort.sortedField !== id) {
			setSortIcon(getSortIcon[SortState.default])
			return
		}
		
		setSortIcon(getSortIcon[holdersSort.sortType])
	}, [id, holdersSort])
	
	return (
		<div
			className={styles.header_item}
			onClick={() => clickHandler()}
		>
			<img className={styles.header_item_sort} src={sortIcon} alt={''}/>
			<span>{value}</span>
			{
				icon && (
					<img src={icon} alt={'eth'} className={styles.header_item_icon}/>
				)
			}
			{
				tooltip && (
					<Tooltip
						active={true}
						content={tooltip}
						style={{position: TooltipPosition.RIGHT}}
					>
						<img className={styles.header_item_tooltip} src={tooltipIcon} alt={'tooltip'} />
					</Tooltip>
				)
			}
		</div>
	);
};

export default HeaderItem;
