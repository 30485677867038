import {useAppDispatch} from "../../../state/hooks";
import {modalValueDispatch, modalValues} from "../config";
import {CreateState} from "../enum";

const useCreate = () => {
	const dispatch = useAppDispatch()
	
	const setAllFiltersAny = () => {
		dispatch(modalValueDispatch(modalValues[CreateState.PnL][0].stat, CreateState.PnL))
		dispatch(modalValueDispatch(modalValues[CreateState.UnPlN][0].stat, CreateState.UnPlN))
		dispatch(modalValueDispatch(modalValues[CreateState.WalletAge][0].stat, CreateState.WalletAge))
	}
	
	return {
		setAllFiltersAny
	}
}

export default useCreate