import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BACKEND_URL} from "../../../utils/config";
import {IMints, IMintsResponse} from "./types";
import {IGetMints} from "./interface";
import {IResponse} from "../../../interfaces";

export const mintsApi = createApi({
	reducerPath: 'mintsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BACKEND_URL
	}),
	endpoints: (builder) => ({
		getMints: builder.query<IMintsResponse, IGetMints>({
			query: ({limit, offset, filters, range}) => {
				return `/hot-mints?offset=${offset}&limit=${limit}&range=${encodeURIComponent(range)}&filters=${encodeURIComponent(JSON.stringify(filters))}`
			},
			transformResponse: (v, meta, arg) =>
				(v as IResponse<IMintsResponse>).data
		})
	})
})

export const { useGetMintsQuery } = mintsApi