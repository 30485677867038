import React from 'react';
import {useNavigate} from "react-router-dom";
import {useConnectModal} from "@rainbow-me/rainbowkit";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../state/hooks";

import {trimAddress} from "../../createList/helper";

import {IStrategies} from "../../../state/api/strategies/types";

import allRoutes from '../../../config/router/routes/allRoutes.json'

import styles from '../styles/popularLists.module.css'
import {intToString, trimValue} from "../../../lib/helper";
import Tooltip from "../../../components/tooltip/Tooltip";
import {setNotifyModalActive, setNotifyModalContent} from "../../../features/notify/notifySlice";
import {followersCountToggle} from "../../../features/user/userSlice";
import {useFollowStrategyMutation, useUnfollowStrategyMutation} from "../../../state/api/strategies/strategies.api";
import {Mixpanel} from "../../../utils/mixpanel";
import {TrackedEventsName} from "../../../utils/mixpanel/enum";

interface IProp {
	isFollowed: boolean,
	index: number,
	list: IStrategies,
}

const TableItem = ({ index, list, isFollowed }: IProp) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	
	const [followTrigger] = useFollowStrategyMutation()
	const [unfollowTrigger] = useUnfollowStrategyMutation()
	
	const {isWalletConnected} = useAppSelector(state => state.auth)
	const { openConnectModal } = useConnectModal()
	
	const clickHandler = () => {
		if (!isWalletConnected && openConnectModal) {
			dispatch(setNotifyModalContent('Please connect wallet'))
			dispatch(setNotifyModalActive(true))
			return
		}
		
		// if (userAddress === list.creator) return
		
		(!isFollowed)
			? followTrigger({filterId: list.id})
			.then(() => {
				dispatch(followersCountToggle({method: "follow", strategy: list}))
				Mixpanel.track(TrackedEventsName.FollowStrategy, {[TrackedEventsName.FollowStrategy]: list.name})
			})
			: unfollowTrigger({filterId: list.id})
			.then(() => {
				dispatch(followersCountToggle({method: "unfollow", strategy: list}))
				Mixpanel.track(TrackedEventsName.UnFollowStrategy, {[TrackedEventsName.UnFollowStrategy]: list.name})
			})
	}
	
	const exploreHandler = () => {
		Mixpanel.track(TrackedEventsName.ExploreStrategy, {[TrackedEventsName.ExploreStrategy]: list.name})
		navigate(`${allRoutes.PopularLists}/explore-group/${list.id}`)
	}
	
	return (
		<div className={styles.table_row}>
			<div className={styles.table_cell}>
				{ index }
			</div>
			<div className={styles.table_cell}>
				<Tooltip
					active={list.name.length > 30}
					content={list.name}
				>
					<span>{trimValue(list.name, 30)}</span>
				</Tooltip>
			</div>
			<div className={styles.table_cell}>
				<Tooltip
					active={!(intToString((list.avgPnl ?? 0) * 100) === ((list.avgPnl ?? 0) * 100).toString())}
					content={((list.avgPnl ?? 0) * 100).toString()}
				>
					<span>{`${intToString((list.avgPnl ?? 0) * 100)} %`}</span>
				</Tooltip>
			</div>
			<div className={styles.table_cell}>
				<Tooltip
					active={!(intToString((list.avgUnPnl ?? 0) * 100) === ((list.avgUnPnl ?? 0) * 100).toString())}
					content={((list.avgUnPnl ?? 0) * 100).toString()}
				>
					<span>{`${intToString((list.avgUnPnl ?? 0) * 100)} %`}</span>
				</Tooltip>
			</div>
			<div className={styles.table_cell}>
				<Tooltip
					active={!(intToString(list.followersCount ?? 'no data') === (list.followersCount ?? 'no data').toString())}
					content={(list.followersCount ?? 1000).toString()}
				>
					<span>{intToString(list.followersCount ?? 'no data')}</span>
				</Tooltip>
			</div>
			<div className={styles.table_cell}>
				<Tooltip
					active={!(intToString(list.includedAddresses) === list.includedAddresses.toString())}
					content={list.includedAddresses.toString()}
				>
					<span>{intToString(list.includedAddresses)}</span>
				</Tooltip>
			</div>
			<div className={styles.table_cell}>
				{(list.lastUpdate) && new Date(list.lastUpdate).getDate()}.
				{(list.lastUpdate) && new Date(list.lastUpdate).getMonth()}.
				{(list.lastUpdate) && new Date(list.lastUpdate).getFullYear()}
			</div>
			<div className={styles.table_cell}>
				<Tooltip
					active={true}
					content={list.creator}
				>
					{trimAddress(list.creator)}
				</Tooltip>
			</div>
			<div className={styles.table_cell}>
				<div
					className={
						isFollowed
							? classNames(styles.follow_button, styles.follow_button_active)
							: styles.follow_button}
					onClick={ clickHandler }
				>
					<span>
						{
							isFollowed
								? "Unfollow"
								: "Follow"
						}
					</span>
				</div>
				<div
					onClick={exploreHandler}
					className={styles.explore_button}
				>
					<span>Explore</span>
				</div>
			</div>
			
		</div>
	);
};

export default TableItem;
