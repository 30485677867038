import React, {useEffect} from 'react';
import {setExploredStrategy, setExploredStrategyId} from "../../features/holdersInteraction/holdersInteractionSlice";
import {useGetStrategyByIdQuery} from "../../state/api/strategies/strategies.api";
import {callNotifyModal} from "../../features/notify/notifySlice";
import {useAppDispatch} from "../../state/hooks";
import {Navigate, useNavigate, useParams} from "react-router-dom";

import Header from "../../components/header/Header";
import Create from "./components/create/Create";
import ExplorerTable from "./components/explorerTable/ExplorerTable";
import NotifyModal from "../../components/notifyModal/notifyModal";

import allRoutes from "../../config/router/routes/allRoutes.json"

import styles from "./styles/createlist.module.css"
import {TableExploreProvider} from "../../lib/context/table-explore-context";

const ExploreStrategy = () => {
	const dispatch = useAppDispatch()
	const params = useParams()
	const navigate = useNavigate()
	
	const {data, isSuccess, isLoading, isError} = useGetStrategyByIdQuery({id: params.id ?? ''})
	
	useEffect(() => {
		if (isSuccess) {
			if (data === null) {
				dispatch(callNotifyModal('group with this id doesnt exist any more'))
				navigate(allRoutes.Trending)
				
				return
			}
		
			dispatch(setExploredStrategy(data))
			dispatch(setExploredStrategyId(data.id))
		}
	}, [data, isSuccess])
	
	return (
		<>
			<Header />
			<div className={styles.container}>
				{
					isLoading &&
					(
						<div className={styles.loading_wrapper}>
							<div className={styles.loading}></div>
						</div>
					)
					||
					isError &&
					(
						<div><span style={{color: "white", fontSize: "2.4rem"}}>Updating database, please wait</span></div>
					)
					||
					(isSuccess && !!data) &&
					(
						<>
							<h1 className={styles.title}>Explore Group</h1>
							<div className={styles.create_container}>
								<Create exploredStrategy={data} />
							</div>
							<TableExploreProvider exploredStrategy={data}>
								<ExplorerTable />
							</TableExploreProvider>
						</>
					)
					||
					(isSuccess && !data) &&
					(
						<Navigate to={allRoutes.Trending} />
					)
				}
				<NotifyModal />
			</div>
		</>
	);
};

export default ExploreStrategy;
