import React from 'react';
import {ReactComponent as Arrow} from '../../../../../images/icon-arrowdown.svg';
import styles from "../explorer.module.css";
import classNames from "classnames";
import {useExplorerDropdownActions} from "../../../../../lib/context/explorer-dropdown-context";

const ExplorerHeader = () => {
	const {exploredGroups, open, setOpen} = useExplorerDropdownActions()
	
	return (
		<div className={styles.header} onClick={() => setOpen(!open)}>
			<h3 className={styles.title}>
				{(exploredGroups.length === 0) ? 'Select suggestion' : `Selected groups ${exploredGroups.length}`}
			</h3>
			<Arrow className={open ? classNames(styles.arrow, styles.arrow_active) : styles.arrow}/>
		</div>
	);
};

export default ExplorerHeader;