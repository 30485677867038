import React, {useState} from 'react';
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../state/hooks";

import { rowsValues } from './config'

import styles from './styles/pagination.module.css'
import {setCurrentPage, setRowsAmount} from "../../features/pagination/paginationSlice";

const RowsSelector = () => {
	const dispatch = useAppDispatch()
	const { rowsAmount } = useAppSelector(state => state.pagination)
	
	const [isActive, setIsActive] = useState(false)
	
	const clickHandle = (value: number) => {
		dispatch(setCurrentPage(1))
		dispatch(setRowsAmount(value))
		setIsActive(false)
	}
	
	const isSelectorActive = () => {
		if (isActive)
			return classNames(styles.rows_selector_header_active, styles.rows_selector_header)
		return styles.rows_selector_header
	}
	
	return (
		<div className={styles.rows_selector}>
			<div
				className={isSelectorActive()}
				onClick={() => setIsActive(!isActive)}
			>
				<span>rows {rowsAmount}</span>
			</div>
			{
				isActive && (
					<div className={styles.rows_selector_body}>
						{
							Object.values(rowsValues).map((value, index) => (
								<div
									className={styles.rows_selector_item}
									key={index}
									onClick={() => clickHandle(value.value)}
								>
									<span>rows {value.value}</span>
								</div>
							))
						}
					</div>
				)
			}
		</div>
	);
};

export default RowsSelector;