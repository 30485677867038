import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from "../../../../state/hooks";
import {setStage} from "../../../../features/createStrategy/createListSlice";

import {modalValues} from "../../config";

import {CreateState} from "../../enum";
import {IBound} from "../../../../features/createStrategy/interface";

import {ReactComponent as CrossIcon} from '../../../../images/icon-cross.svg'

import {getCreateFilterValue} from "../../helper";

import {
	setExcludedHolders,
	setIsStrategyEdited,
	setPln, setSelectAllHolders, setShowAll,
	setUnPln,
	setWalletAge
} from "../../../../features/holdersInteraction/holdersInteractionSlice";

import styles from '../../styles/modal.module.css'
import {setCurrentPage} from "../../../../features/pagination/paginationSlice";

interface IProp {
	type: CreateState.PnL | CreateState.UnPlN | CreateState.WalletAge,
	changeStage(stage: CreateState): void
}

const ModalBody = ({ type, changeStage }: IProp) => {
	const { filters, excludedHolders } = useAppSelector(state => state.holdersInteraction)
	
	const initialValue = {
		[CreateState.PnL]: filters.pnlPercent,
		[CreateState.UnPlN]: filters.unPnlPercent,
		[CreateState.WalletAge]: filters.walletAge
	}
	
	
	const [value, setValue] = useState<IBound | undefined | any>(initialValue[type])
	
	const dispatch = useAppDispatch()
	
	const dispatchValue = {
		[CreateState.PnL]: () => {
			if (value) dispatch(setPln(value))
		},
		[CreateState.UnPlN]: () => {
			if (value) dispatch(setUnPln(value))
		},
		[CreateState.WalletAge]: () => {
			if (value) dispatch(setWalletAge(value))
		},
	}
	
	const applyHandle = () => {
		dispatchValue[type]()
		dispatch(setIsStrategyEdited(true))
		if (!!excludedHolders.length) {
			dispatch(setExcludedHolders([]))
			dispatch(setSelectAllHolders(true))
		}
		dispatch(setCurrentPage(1))
		changeStage(CreateState.initial)
	}
	
	return (
		<div className={styles['modal']}>
			<div className={styles['header']}>
				<h3 className={styles['header_title']}>Choose {type}</h3>
				<CrossIcon
					onClick={() => changeStage(CreateState.initial)}
				/>
			</div>
			<div className={styles['body']}>
				<input
					className={styles['body_input']}
					placeholder={'Enter amount or choose below'}
					type={'text'}
					value={getCreateFilterValue(value, type)}
					readOnly={true}
				/>
				{
					modalValues[type].map((value, index) => (
							<div
								className={styles['body_button']}
								onClick={() => setValue(value.stat)}
								key={index}
							>
								<span>{value.name}</span>
							</div>
						)
					)
				}
			</div>
			<div className={styles['footer']}>
				<div
					onClick={() => applyHandle()}
					className={styles['apply_button']}
				>
					<span>Apply</span>
				</div>
			</div>
		</div>
	);
};

export default ModalBody
