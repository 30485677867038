import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {setNotifyModalActive} from "../../features/notify/notifySlice";

import styles from './styles.module.css'

const NotifyModal = () => {
	const dispatch = useAppDispatch()
	const {isModalActive, modalContent} = useAppSelector(state => state.notify)
	
	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setNotifyModalActive(false))
		} , 5000)

		return () => {clearTimeout(timer)}
	}, [isModalActive])
	
	if (!isModalActive) return null
	
	return (
		<div className={styles.modal}>
			<h3 className={styles.modal_title}>Error</h3>
			<p className={styles.modal_content}>{modalContent}</p>
		</div>
	);
};

export default NotifyModal;
