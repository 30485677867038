import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {ISort, ISortState} from "./interface";

import {SortState} from "../../enum";

import {holdersHeaderValues} from "../../pages/createList/config";
import {popularListsHeaderValues} from "../../pages/popularLists/config";
import {trendingHeaderValues} from "../../pages/trending/config";
import {mintHeaderValues} from "../../pages/mint/config"

const initialState: ISortState = {
	mintSort: {
		sortedField: mintHeaderValues[1].id,
		sortType: SortState.toLow
	},
	filtersSort: {
		sortedField: popularListsHeaderValues[4].id,
		sortType: SortState.toLow
	},
	holdersSort: {
		sortedField: holdersHeaderValues[1].id,
		sortType: SortState.toLow
	},
	trendingSort: {
		sortedField: trendingHeaderValues()[3].id,
		sortType: SortState.toLow
	}
}

export const sortSlice = createSlice({
	name: 'sort',
	initialState,
	reducers: {
		setFiltersSort: (state, action: PayloadAction<ISort>) => {
			state.filtersSort = action.payload
		},
		setHoldersSort: (state, action: PayloadAction<ISort>) => {
			state.holdersSort = action.payload
		},
		setTrendingSort: (state, action: PayloadAction<ISort>) => {
			state.trendingSort = action.payload
		},
		setMintSort: (state, action: PayloadAction<ISort>) => {
			state.mintSort = action.payload
		}
	}
})

export const {setMintSort, setHoldersSort, setTrendingSort, setFiltersSort} = sortSlice.actions

export default sortSlice.reducer