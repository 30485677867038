import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFollowersCountToggle, IUser} from "./interface";
import {IStrategies} from "../../state/api/strategies/types";

const initialState: IUser = {
	ethRate: 1,
	strategies: [],
	userAddress: '',
	topStrategies: [],
	followedFilters: {
		data: [],
		isLoading: false
	},
	activeStrategies: [],
	isRemainderVisible: true,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsRemainderVisible: (state, action: PayloadAction<boolean>) => {
			state.isRemainderVisible = action.payload
		},
		setEthRate: (state, action: PayloadAction<number>) => {
			state.ethRate = action.payload
		},
		removeFollowedFilter: (state, action: PayloadAction<string>) => {
			const index = state.followedFilters.data.findIndex(item => item.id === action.payload)
			
			if (index === -1) return
			
			state.followedFilters.data.splice(index, 1)
		},
		setFollowedFiltersIsLoading: (state, action: PayloadAction<boolean>) => {
			state.followedFilters.isLoading = action.payload
		},
		setFollowedFilters: (state, action: PayloadAction<IStrategies[]>) => {
			state.followedFilters.isLoading = false
			state.followedFilters.data = action.payload
		},
		setUserAddress:(state, action: PayloadAction<string>) => {
			state.userAddress = action.payload
		},
		setTopStrategies:(state, action: PayloadAction<IStrategies[]>) => {
			state.topStrategies = action.payload
		},
		setStrategies: (state, action: PayloadAction<IStrategies[]>) => {
			state.strategies = action.payload
		},
		addActiveStrategy: (state, action: PayloadAction<IStrategies>) => {
			state.activeStrategies = [...state.activeStrategies, action.payload]
		},
		removeActiveStrategy: (state, action: PayloadAction<IStrategies>) => {
			const index = state.activeStrategies.findIndex((strategy) => {
				return action.payload.id === strategy.id
			})
			
			if (index === -1) return
			
			state.activeStrategies.splice(index, 1)
		},
		clearActiveStrategy: (state) => {
			state.activeStrategies = []
		},
		followersCountToggle: (state, action: PayloadAction<IFollowersCountToggle>) => {
			const indexStrategy = state.strategies.findIndex(strategy =>
				action.payload.strategy.id === strategy.id)
			const indexFollowed = state.followedFilters.data.findIndex(strategy =>
				action.payload.strategy.id === strategy.id)
			if (indexStrategy === -1 || (indexFollowed === -1 && action.payload.method === "unfollow")) return
			
			
			const indexTopStrategy = state.topStrategies.findIndex(topStrategy =>
				action.payload.strategy.id === topStrategy.id)
			
			const followToggle = {
				['follow']: () => {
					state.strategies[indexStrategy].followersCount += 1
					state.followedFilters.data.push(action.payload.strategy)
					
					if (indexTopStrategy === -1) return
					
					state.topStrategies[indexTopStrategy].followersCount += 1
				},
				['unfollow']: () => {
					state.strategies[indexStrategy].followersCount -= 1
					state.followedFilters.data.splice(indexFollowed, 1)
					
					if (indexTopStrategy === -1) return
					
					state.topStrategies[indexTopStrategy].followersCount -= 1
				}
			}
			
			followToggle[action.payload.method]()
		}
	}
})

export const {
	setEthRate,
	setStrategies,
	setUserAddress,
	setTopStrategies,
	addActiveStrategy,
	setFollowedFilters,
	clearActiveStrategy,
	followersCountToggle,
	removeActiveStrategy,
	removeFollowedFilter,
	setIsRemainderVisible,
	setFollowedFiltersIsLoading,
} = userSlice.actions

export default userSlice.reducer
