import {setPln, setUnPln, setWalletAge} from "../../../features/createStrategy/createListSlice";
import {CreateState} from "../enum";
import {IBound} from "../../../features/createStrategy/interface";
import sortToLow from "../../../images/sort-low.svg";
import sortDefault from "../../../images/sort-default.svg";
import sortToHigh from "../../../images/sort-high.svg";
import ethIcon from "../../../images/icon-eth.svg"
import {OperatorsFrom, OperatorsTo} from "../../../features/createStrategy/enum";
import {SortState} from "../../../enum";
import {IHeaderValues} from "../interface";

interface IFilterStat {
	name: string,
	stat: IBound | 'any'
}

export const pnLValues: IFilterStat[] = [
	{
		name: 'any',
		stat: 'any'
	},
	{
		name: '0-50%',
		stat: {
			from: {
				value: 0,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: .5,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '50-100%',
		stat: {
			from: {
				value: 0.5,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 1,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '100-150%',
		stat: {
			from: {
				value: 1,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 1.5,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '150-200%',
		stat: {
			from: {
				value: 1.5,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 2,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '200-250%',
		stat: {
			from: {
				value: 2,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 2.5,
				operator: OperatorsTo["<="]
			}
		}
	},
]

export const unPnlValues: IFilterStat[] = [
	{
		name: 'any',
		stat: 'any'
	},
	{
		name: '0-50%',
		stat: {
			from: {
				value: 0,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: .5,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '50-100%',
		stat: {
			from: {
				value: 0.5,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 1,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '100-150%',
		stat: {
			from: {
				value: 1,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 1.5,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '150-200%',
		stat: {
			from: {
				value: 1.5,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 2,
				operator: OperatorsTo["<"]
			}
		}
	},
	{
		name: '200-250%',
		stat: {
			from: {
				value: 2,
				operator: OperatorsFrom[">="]
			},
			to: {
				value: 2.5,
				operator: OperatorsTo["<="]
			}
		}
	},
]

export const walletAgeValues: IFilterStat[] = [
	{
		name: 'any',
		stat: 'any'
	},
	{
		name: '1d+',
		stat: {
			to: {
				value: 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
	{
		name: '1w+',
		stat: {
			to: {
				value: 7 * 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
	{
		name: '1m+',
		stat: {
			to: {
				value: 30 * 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
	{
		name: '3m+',
		stat: {
			to: {
				value: 90 * 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
	{
		name: '6m+',
		stat: {
			to: {
				value: 182 * 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
	{
		name: '1y+',
		stat: {
			to: {
				value: 365 * 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
	{
		name: '3y+',
		stat: {
			to: {
				value: 3 * 365 * 24 * 60 * 60 * 1000,
				operator: OperatorsTo["<="]
			}
		}
	},
]

export const modalValues = {
	[CreateState.PnL]: pnLValues,
	[CreateState.UnPlN]: unPnlValues,
	[CreateState.WalletAge]: walletAgeValues
}

export const modalValueDispatch = (
	value: IBound | any,
	type: CreateState.PnL
		| CreateState.UnPlN
		| CreateState.WalletAge
) => {
	if (type === CreateState.PnL)
		return setPln(value)

	if (type === CreateState.UnPlN)
		return setUnPln(value)

	return setWalletAge(value)
}

export const getSortIcon = {
	[SortState.toHigh]: sortToHigh,
	[SortState.toLow]: sortToLow,
	[SortState.default]: sortDefault
}

export const holdersHeaderValues: IHeaderValues[] = [
	{
		id: 'address',
		value: 'Address'
	},
	{
		id: 'holdingValueEth',
		value: 'Holding Value',
		tooltip: 'Holding Value = Floor * NFTs',
		icon: ethIcon
	},
	{
		id: 'pnlPercent',
		value: 'PnL %',
		tooltip: 'PnL % (Profit and Loss %) = (PnL / ETH spent to purchase NFTs in portfolio) * 100'
	},
	{
		id: 'pnlEth',
		value: 'PnL',
		tooltip: 'PnL (Profit and Loss) = Sale Price - Buy Price',
		icon: ethIcon
	},
	{
		id: 'nft',
		value: 'NFTs'
	},
	{
		id: 'collectionCount',
		value: 'Collections'
	},
	{
		id: 'trades',
		value: 'Trades'
	},
	{
		id: 'mints',
		value: 'Mints'
	}
]
