import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from "../../../../state/hooks";
import {setSelectAll, setStage} from "../../../../features/createStrategy/createListSlice";

import {modalValueDispatch, modalValues} from "../../config";
import {CreateState} from "../../enum";

import {ReactComponent as CrossIcon} from '../../../../images/icon-cross.svg'

import styles from '../../styles/modal.module.css'
import {IBound} from "../../../../features/createStrategy/interface";
import {getCreateFilterValue} from "../../helper";
import {isObjectEmpty} from "../../../../lib/helper";
import useCreate from "../../hook/use-create";

interface IProp {
	type: CreateState.PnL | CreateState.UnPlN | CreateState.WalletAge
}

const ModalBody = ({ type }: IProp) => {
	const [value, setValue] = useState<IBound | 'any'>()
	
	const dispatch = useAppDispatch()
	
	const { filter, stage } = useAppSelector(state => state.createList)
	
	const { setAllFiltersAny } = useCreate()
	
	const firstChoice = () => {
		if (isObjectEmpty(filter.filters)) {
			setAllFiltersAny()
		}
	}
	
	const applyHandle = () => {
		if (value) {
			firstChoice()
			dispatch(modalValueDispatch(value, type))
			if (!filter.addresses.length) dispatch(setSelectAll(true))
			dispatch(setStage(CreateState.initial))
		}
	}
	
	useEffect(() => {
		if (filter.filters) {
			if (type === CreateState.PnL)
				setValue(filter.filters.pnlPercent)
			if (type === CreateState.UnPlN)
				setValue(filter.filters.unPnlPercent)
			if (type === CreateState.WalletAge)
				setValue(filter.filters.walletAge)
		}
	}, [type])
	
	return (
		<div className={styles['modal']}>
			<div className={styles['header']}>
				<h3 className={styles['header_title']}>Choose below  {type}</h3>
				<CrossIcon
					onClick={() => dispatch(setStage(CreateState.initial))}
				/>
			</div>
			<div className={styles['body']}>
				<input
					className={styles['body_input']}
					placeholder={'choose below'}
					type={'text'}
					value={(value) ? getCreateFilterValue(value, stage) : ''}
					readOnly={true}
				/>
				{
					modalValues[type].map((value, index) => (
							<div
								className={styles['body_button']}
								onClick={() => setValue(value.stat)}
								key={index}
							>
								<span>{value.name}</span>
							</div>
						)
					)
				}
			</div>
			<div className={styles['footer']}>
				<div
					onClick={() => applyHandle()}
					className={styles['apply_button']}
				>
					<span>Apply</span>
				</div>
			</div>
		</div>
	);
};

export default ModalBody
