import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../state/hooks";
import {
	addAddress, addExcludedAddress,
	removeAddress,
	removeExcludedAddress,
} from "../../../../features/createStrategy/createListSlice";
import Tooltip from "../../../../components/tooltip/Tooltip";
import {intToString} from "../../../../lib/helper";
import {ReactComponent as LinkIcon} from "../../../../images/icon-link.svg"
import styles from '../../styles/table.module.css'

interface IProp {
	isChecked: boolean,
	index?: number,
	address: string,
	pnlPercent: number,
	pnlEth: number,
	holdingValue?: number,
	nft?: number,
	collections?: number,
	trades?: number,
	mints?: number,
	ensName?: string
}

interface IValue {
	value?: number
	tooltipContent?: string
	symbol?: string
}

const Value = ({
	value,
	tooltipContent,
	symbol = ''
}: IValue) => {
	return (
		<>
			{
				!!value
					? <Tooltip
						active={!(intToString(value) === (value?.toString()))}
						content={
							tooltipContent
								? tooltipContent
								: Number.isInteger(value) ? value.toString() : value.toFixed(4)
						}
					>
						<span>{`${intToString(value)} ${symbol}`}</span>
					</Tooltip>
					: <span>0</span>
			}
		</>
	)
}

const AddressItem = ({address, ensName}: {address: string, ensName?: string}) => {
	const [copyText, setCopyText] = useState('Copy address')
	
	useEffect(() => {
		if (copyText === 'Copied!') setTimeout(() => {setCopyText('Copy address')}, 1500)
	}, [copyText])
	
	async function copyHandler() {
		setCopyText('Copied!')
		await navigator.clipboard.writeText(address)
	}
	
	function exploreHandler(){
		window.open(`https://etherscan.io/address/${address}`, "_blank");
	}
	
	return (
		<>
			<Tooltip active={true} content={copyText}>
				<span onClick={copyHandler}>{ensName ?? address}</span>
			</Tooltip>
			<Tooltip active={true} content={'Explore holder'}>
				<LinkIcon onClick={exploreHandler} className={styles.link_icon }/>
			</Tooltip>
		</>
	)
}

const HolderRow = ({isChecked ,index, address, mints, nft, pnlPercent, trades, holdingValue, collections, ensName, pnlEth}: IProp) => {
	const dispatch = useAppDispatch()
	
	const { selectAllAddresses } = useAppSelector(state => state.createList)
	const { ethRate } = useAppSelector(state => state.user)
	
	const toggleAddressHandler = async (isChecked: boolean) => {
		if (isChecked) {
			if (selectAllAddresses) {
				dispatch(addExcludedAddress(address))
				
				return
			}
			
			if (!selectAllAddresses) {
				dispatch(removeAddress(address))
				
				return
			}
		}
		
		if (selectAllAddresses) {
			dispatch(removeExcludedAddress(address))
			
			return
		}
		
		if (!selectAllAddresses) {
			dispatch(addAddress(address))
			
			return
		}
	}
	
	return (
		<div className={styles.body_row}>
			<div className={styles.body_item}>
				<span>{index}</span>
			</div>
			<div className={styles.body_item}>
				<input
					onClick={() => toggleAddressHandler(isChecked)}
					checked={isChecked}
					readOnly={true}
					type={'checkbox'}
				/>
			</div>
			<div className={styles.body_item}>
				<AddressItem address={address} ensName={ensName}/>
			</div>
			<div className={styles.body_item}>
				<Value
					value={holdingValue}
					tooltipContent={`${(ethRate * (holdingValue ?? 0)).toFixed(2)} $`}
				/>
			</div>
			<div className={styles.body_item}>
				<Tooltip content={`${pnlPercent * 100}`}>
					<span>{`${intToString(pnlPercent * 100)} %`}</span>
				</Tooltip>
			</div>
			<div className={styles.body_item}>
				<Value value={pnlEth} />
			</div>
			<div className={styles.body_item}>
				<Value value={nft} />
			</div>
			<div className={styles.body_item}>
				<Value value={collections} />
			</div>
			<div className={styles.body_item}>
				<Value value={trades} />
			</div>
			<div className={styles.body_item}>
				<Value value={mints} />
			</div>
		</div>
	);
};

export default HolderRow;
