import React, {useEffect} from 'react';
import {useConnectModal} from "@rainbow-me/rainbowkit";
import {useAppDispatch, useAppSelector} from "../../state/hooks";

import ExplorerTable from "./components/explorerTable/ExplorerTable";
import Header from "../../components/header/Header";
import Create from "./components/create/Create";
import Steps from "./components/Steps";
import Modal from "./components/modal/Modal";

import styles from "./styles/createlist.module.css"
import NotifyModal from "../../components/notifyModal/notifyModal";
import {TableProvider} from "../../lib/context/table-create-context";

const CreateList = () => {
	const { createdListId, isListCreating } = useAppSelector(state => state.createList)
	const { isWalletConnected } = useAppSelector(state => state.auth)
	const { openConnectModal } = useConnectModal()
	
	useEffect(() => {
		if (!isWalletConnected) {
			openConnectModal?.()
		}
	}, [])
	
	return (
		<>
			<Header />
			<div className={styles.container}>
				<h1 className={styles.title}>CREATE GROUP</h1>
				<div className={styles.create_container}>
					<Create />
					<Steps />
				</div>
				<TableProvider>
					<ExplorerTable />
				</TableProvider>
			</div>
			{
				(createdListId || isListCreating) && <Modal />
			}
			<NotifyModal />
		</>
	);
};

export default CreateList;
