import {periodTrending} from "../../pages/trending/config";
import {periodMint} from "../../pages/mint/config";
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import { ITimePeriodState } from "./interface";
import {PeriodTrendingEnum} from "../../pages/trending/enum";

const initialState: ITimePeriodState = {
	trendingPeriod: periodTrending[4].id,
	mintPeriod: periodMint[1].id
}

export const timePeriodSlice = createSlice({
	name: 'timePeriod',
	initialState,
	reducers: {
		setTrendingPeriod: (state, action: PayloadAction<PeriodTrendingEnum>) => {
			state.trendingPeriod = action.payload
		},
		setMintPeriod: (state, action: PayloadAction<string>) => {
			state.mintPeriod = action.payload
		}
	}
})

export const { setTrendingPeriod, setMintPeriod } = timePeriodSlice.actions;

export default timePeriodSlice.reducer;