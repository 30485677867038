import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch} from "../../../state/hooks";
import {setSearchedValue} from "../../../features/search/searchSlice";
import {debounce} from "lodash";
import {ReactComponent as IconSearch} from "../../../images/icon-search.svg";
import styles from "../styles/header.module.css";
import {useLocation} from "react-router-dom";
import allRouters from "../../../config/router/routes/allRoutes.json"

const Search = () => {
	const [placeholder, setPlaceholder] = useState<string>("")
	const dispatch = useAppDispatch()

	const location = useLocation()
	
	const search = (name: string) => {
		if (name.length > 0) {
			dispatch(setSearchedValue(name))
			
			return
		}
		
		dispatch(setSearchedValue(undefined))
	}
	
	const debouncedSearch = useRef(
		debounce((name: string) => search(name), 300)
	).current
	
	useEffect(() => {
		return () => debouncedSearch.cancel()
	}, [debouncedSearch])
	
	useEffect(() => {
		if (allRouters.PopularLists === location.pathname) setPlaceholder("Search by Group name")
		else setPlaceholder("Search by address or Collection name")
	}, [location.pathname])
	
	return (
		<div
			className={styles.search_input_wrapper}
		>
			<IconSearch className={styles.search_input_icon}/>
			<input
				onChange={(event) => {
					debouncedSearch(event.target.value)
				}}
				className={styles.search_input}
				placeholder={placeholder}
				type={'text'}
			/>
		</div>
	);
};

export default Search;