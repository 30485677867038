import ethIcon from '../../../images/icon-eth.svg'

import {IHeaderValues} from "../interface";
import {PeriodTrendingEnum} from "../enum";

export const tooltipsContent = {
	pickStrategyToFollow: 'With an activated Smart Money Group, you can monitor the activity of addresses included in that strategy with relevant Floor, Volume, Sales, Listings',
	tableHeader: {
		sales: 'Sales = Amount of Buys and Sells by addresses included in Group',
		floor: 'Minimal Buy Price by addresses included in Group',
		listing: 'Percentage of Listings by addresses included in Group, OpenSea',
		twitterScore: 'Generated score for Twitter Accounts based on the popularity of this account in crypto space'
	}
}

export const trendingHeaderValues = (isGroupActive?: boolean) : IHeaderValues[] =>
	isGroupActive
		? [
			{
				id: 'name',
				name: 'Collections in Activated Groups',
				sortable: true
			},
			{
				id: 'avgPrice',
				icon: ethIcon,
				name: 'Avg. Floor',
				sortable: true,
			},
			{
				id: 'floorPriceMin',
				sortable: true,
				icon: ethIcon,
				tooltip: tooltipsContent.tableHeader.floor,
				name: 'Min. Floor',
			},
			{
				name: 'Volume',
				sortable: true,
				icon: ethIcon,
				id: 'volume',
			},
			{
				id:'transfers',
				name: 'Sales',
				tooltip: tooltipsContent.tableHeader.sales,
				sortable: true,
			},
			{
				id:'sales',
				name: 'Buys/Sells',
				sortable: true,
			},
			{
				id:'salesDelta',
				name: 'Sales Δ',
				tooltip: 'Sales Δ = Buys - Sells',
				sortable: false,
			},
			// {
			// 	id: 'listingsPercent',
			// 	name: 'Listings',
			// 	tooltip: tooltipsContent.tableHeader.listing,
			// 	sortable: true,
			// },
			// {
			// 	id: 'twitterScore',
			// 	name: 'Twitter Score',
			// 	tooltip: tooltipsContent.tableHeader.twitterScore,
			// 	sortable: true,
			// },
			{
				id: 'floorPrice',
				name: 'Floor Price Chart',
				sortable: false,
			},
			{
				id: 'action',
				name: 'Action',
				sortable: false,
			}
		]
		: [
			{
				id: 'name',
				name: 'Collection Name',
				sortable: true
			},
			{
				id: 'floorValue',
				sortable: true,
				icon: ethIcon,
				name: 'Floor',
			},
			{
				name: 'Volume',
				sortable: true,
				icon: ethIcon,
				id: 'volume',
			},
			{
				id:'sales',
				name: 'Sales',
				sortable: true,
			},
			{
				id: 'listingsPercent',
				name: 'Listings',
				sortable: true,
			},
			// {
			// 	id: 'twitterScore',
			// 	name: 'Twitter Score',
			// 	tooltip: tooltipsContent.tableHeader.twitterScore,
			// 	sortable: true,
			// },
			{
				id: 'floorPrice',
				name: 'Floor Price Chart',
				sortable: false,
			},
			{
				id: 'action',
				name: 'Action',
				sortable: false,
			}
		]

export const periodTrending = [
	{
		id: PeriodTrendingEnum.min5,
		value: '5M'
	},
	{
		id: PeriodTrendingEnum.h1,
		value: '1H'
	},
	{
		id: PeriodTrendingEnum.h6,
		value: '6H'
	},
	{
		id: PeriodTrendingEnum.d1,
		value: '1D'
	},
	{
		id: PeriodTrendingEnum.d7,
		value: '7D'
	},
	{
		id: PeriodTrendingEnum.d30,
		value: '30D'
	},
]
