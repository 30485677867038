import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getJwt} from "./asyncThunk";
import {createSortedStateAdapter} from "@reduxjs/toolkit/dist/entities/sorted_state_adapter";

interface IAuthSlice {
	token: string,
	isLoading: boolean,
	isWalletConnected: boolean,
}

const initialState: IAuthSlice = {
	token: '',
	isLoading: true,
	isWalletConnected: false
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		},
		setDisconnectWallet: (state) => {
			state.isWalletConnected = false
		},
		
	},
	extraReducers: (builder) => {
		builder.addCase(getJwt.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(getJwt.rejected, (state, action: PayloadAction<any>) => {
			state.isWalletConnected = false
			state.isLoading = false
		})
		builder.addCase(getJwt.fulfilled, (state, action) => {
			state.isLoading = false
			
			if (action.payload) {
				state.token = action.payload
				state.isWalletConnected = true
				
				return
			}
			
			state.isWalletConnected = false
			
		})
	}
})


export const { setDisconnectWallet, setIsLoading } = authSlice.actions

export default authSlice.reducer