import React, {Suspense} from 'react';
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import {getMonthToString} from "../../../utils";

import {floorPriceChartToDataChart} from "../helper";

import styles from '../styles/collection.module.css'
import {useAppSelector} from "../../../state/hooks";

interface IChart {
	collectionId: string
	floorPriceChart: number[][]
}

const CustomTooltip = ({ active, payload, label } : any) => {
	if (active && payload && payload.length) {
		return (
			<div className={styles.custom_tooltip}>
				{
					label === -1
						? <h3>No data</h3>
						: <>
							<p>{`${payload[0].value} ETH`}</p>
							<p>
								{`${getMonthToString(new Date(label).getMonth())} ${new Date(label).getDate()} at ${new Date(label).getHours()}:`}
								{new Date(label).getMinutes() < 10 ? `0${new Date(label).getMinutes()}` : new Date(label).getMinutes()}
							</p>
						</>
				}
			</div>
		);
	}
	
	return null
}

const Chart = ({floorPriceChart, collectionId}: IChart) => {
	const {trendingPeriod} = useAppSelector(state => state.timePeriod)

	const data = floorPriceChartToDataChart(floorPriceChart, trendingPeriod)
	
	return (
		<div className={styles.chart_container}>
			<ResponsiveContainer width='80%' height='100%' >
				<LineChart data={data}>
					<Line
						type="monotone"
						dataKey="price"
						stroke="#898989"
						strokeWidth={2}
						dot={{
							fill: "#898989",
							strokeWidth: 0,
							r: 2
						}}
						activeDot={{
							fill: "#898989",
							strokeWidth: 0,
							r: 3
						}}
						isAnimationActive={false}
				        key={collectionId}
				        id={collectionId}
				        keyPoints={"date"}
					/>
					<Tooltip
						cursor={false}
						content={<CustomTooltip />}
					/>
					<XAxis hide={true} dataKey="date"/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Chart;
