import React from 'react';
import {useAppSelector} from "../../../../state/hooks";
import {generateLink, getCreateFilterValue} from "../../helper";
import {useNavigate, useParams} from "react-router-dom";

import {IStrategies} from "../../../../state/api/strategies/types";

import {CreateState} from "../../enum";

import tooltipIcon from "../../../../images/icon-tooltip.svg";
import arrowIcon from "../../../../images/icon-arrowdown.svg"

import styles from "../../styles/createlist.module.css";
import {IFilters} from "../../../../features/holdersInteraction/interface";
import {TooltipPosition} from "../../../../enum";
import Tooltip from "../../../../components/tooltip/Tooltip";

interface IProp {
	changeStage(stage: CreateState): void,
	exploredStrategy: IStrategies
}

const InitialBody = ({ exploredStrategy, changeStage }: IProp) => {
	const navigate = useNavigate()
	const params = useParams()
	
	const { filters, isStrategyEdited } = useAppSelector(state => state.holdersInteraction)
	
	const activeFilter = (): IFilters => {
		if (isStrategyEdited && filters) return filters
		return exploredStrategy.filters
	}
	
	const onClose = () => {
		navigate(`/${params.prev_page}`)
	}
	
	const onTwitterShare = () => {
		const text = encodeURIComponent('I’ve created a Group of Smart Money with @‌bulltor_ai. Join me on BULLTOR.\n\n')
		const link = generateLink(exploredStrategy.id)
		
		window.open(`https://twitter.com/intent/tweet?text=${text}&url=${link}`, "_blank");
	}
	
	return (
		<>
			<div className={styles.create_body}>
				<div style={{maxWidth: 'none'}} className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>Name</span>
					</div>
					<input
						value={exploredStrategy?.name}
						readOnly={true}
						placeholder={'Empty'}
					/>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>PnL</span>
						<Tooltip
							active={true}
							content={
								<>
									<span>PnL (Profit and Loss)  = Sale Price - Buy Price</span>
									<span>PnL% = [(Sale Price - Buy Price) / Buy Price] * 100</span>
								</>
							}
							style={{position: TooltipPosition.RIGHT, maxWidth: 45}}
						>
							<img src={tooltipIcon} alt={''} />
						</Tooltip>
					</div>
					<div
						onClick={() => changeStage(CreateState.PnL)}
						className={styles.create_item_button}
					>
						<span>{getCreateFilterValue(activeFilter()?.pnlPercent, CreateState.PnL) || 'Empty'}</span>
						<img src={arrowIcon} alt={'v'}/>
					</div>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>uPnL</span>
						<Tooltip
							active={true}
							style={{position: TooltipPosition.RIGHT, maxWidth: 55}}
							content={
								<>
									<span>uPnL (Unrealized Profit and Loss) = Current Price - Buy Price</span>
									<span>uPnL% = [(Current Price - Buy Price) / Buy Price] * 100</span>
								</>
							}
						>
							<img src={tooltipIcon} alt={''} />
						</Tooltip>
					</div>
					<div
						onClick={() => changeStage(CreateState.UnPlN)}
						className={styles.create_item_button}
					>
						<span>{getCreateFilterValue(activeFilter()?.unPnlPercent, CreateState.UnPlN)  || 'Empty'}</span>
						<img src={arrowIcon} alt={'v'}/>
					</div>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>Wallet Age</span>
					</div>
					<div
						onClick={() => changeStage(CreateState.WalletAge)}
						className={styles.create_item_button}
					>
						<span>{getCreateFilterValue(activeFilter()?.walletAge, CreateState.WalletAge)  || 'Empty'}</span>
						<img src={arrowIcon} alt={'v'}/>
					</div>
				</div>
			</div>
			<div className={styles.create_buttons_container}>
				<div
					onClick={() => onClose()}
					className={styles.create_buttons_next}
				>
					<span>Close</span>
				</div>
				<div
					onClick={() => onTwitterShare()}
					className={styles.create_buttons_share}
				>
					<span>Share on Twitter</span>
				</div>
			</div>
		</>
	);
};

export default InitialBody;
