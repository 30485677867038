import React from 'react';
import {SkeletonType} from "./emun";

import styles from './styles.module.css'

interface IProp {
	type: SkeletonType,
	amount?: number
}

const Skeleton = ({ amount, type }: IProp) => {
	if (!!amount && amount < 1) return null
	
	const range = Array.from({length: amount ?? 1}, (_, idx) => idx)
	
	const skeletonClass = {
		[SkeletonType.Strategy]: styles.strategies,
		[SkeletonType.Table]: styles.table_row,
		[SkeletonType.StrategyPreview]: styles.strategy_preview,
		[SkeletonType.DropDownItem]: styles.dropdown_item
	}
	
	return (
		<>
			{
				range.map((_, index) =>
					<div className={skeletonClass[type]} key={index}>
						<div></div>
					</div>
				)
			}
		</>
	);
};

export default Skeleton;