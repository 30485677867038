import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../state/hooks";

import {CreateState} from "../../enum";

import arrowIcon from '../../../../images/icon-arrowdown.svg'

import styles from '../../styles/table.module.css'
import {getCreateFilterValue} from "../../helper";
import {IStrategies} from "../../../../state/api/strategies/types";

interface IProp {
	exploredStrategy: IStrategies
}

const Suggestion = ({exploredStrategy}: IProp) => {
	
	const [isActiveSuggestion, setIsActiveSuggestion] = useState(false)
	
	useEffect(() => {
		if (
			getCreateFilterValue(exploredStrategy.filters?.pnlPercent, CreateState.PnL) !== ''
			||  getCreateFilterValue(exploredStrategy.filters?.unPnlPercent, CreateState.UnPlN) !== ''
			|| getCreateFilterValue(exploredStrategy.filters?.walletAge, CreateState.WalletAge) !== ''
		)
			setIsActiveSuggestion(true)
		else
			setIsActiveSuggestion(false)
	}, [exploredStrategy.filters])
	
	return (
		<div className={styles.explorer}>
			{
				isActiveSuggestion
					? (
						<div className={styles.explorer_dropdown}>
							<span className={styles.explorer_select_title}>{exploredStrategy.name}:</span>
							{
								getCreateFilterValue(exploredStrategy.filters?.pnlPercent, CreateState.PnL) !== '' &&
								<div className={styles.explorer_value_item}>
									<span>PnL: {getCreateFilterValue(exploredStrategy.filters?.pnlPercent, CreateState.PnL)};</span>
								</div>
							}
							{
								getCreateFilterValue(exploredStrategy.filters?.unPnlPercent, CreateState.UnPlN) !== '' &&
								<div className={styles.explorer_value_item}>
                                    <span>uPnL: {getCreateFilterValue(exploredStrategy.filters?.unPnlPercent, CreateState.UnPlN)};</span>
                                </div>
							}
							{
								getCreateFilterValue(exploredStrategy.filters?.walletAge, CreateState.WalletAge) !== '' &&
								<div className={styles.explorer_value_item}>
                                    <span>Wallet Age: {getCreateFilterValue(exploredStrategy.filters?.walletAge, CreateState.WalletAge)};</span>
                                </div>
							}
							
						</div>
					)
					: (
						<div
							className={styles.explorer_dropdown}
						>
							<span className={styles.explorer_select_title}>Empty</span>
							<img src={arrowIcon} alt={''} />
						</div>
					)
			}
		</div>
	);
};
export default Suggestion;
