import React from 'react';
import styles from "../../../../styles/collection.module.css";
import {NftCollectionProps} from "../../index";
import ValueWithDelta from "../value-with-delta";
import Tooltip from "../../../../../../components/tooltip/Tooltip";
import {intToString} from "../../../../../../lib/helper";
import {useAppSelector} from "../../../../../../state/hooks";

interface WithActiveGroupProps {
	collection: Pick<Required<NftCollectionProps>, "buys"
		| "sales"
		| "volume"
		| "listings"
		| "transfers"
		| "volumeDelta"
		| "salesChanged"
		| "listingsDelta"
		| "volumePercent"
		|	"floorPriceAvg"
		| "floorPriceMin"
		| "salesBuysDelta"
	>
}

const WithActiveGroup = ({collection}: WithActiveGroupProps) => {
	const { ethRate} = useAppSelector(state => state.user)
	
	const {
		buys,
		sales,
		volume,
		listings,
		transfers,
		volumeDelta,
		salesChanged,
		volumePercent,
		floorPriceMin,
		floorPriceAvg,
		listingsDelta,
		salesBuysDelta,
	} = {
		...collection,
		volumeDelta: collection.volume === 0 ? 0 : collection.volumeDelta,
		salesChanged: collection.transfers === 0 ? 0 : collection.salesChanged,
		listingsDelta: collection.listings === 0 ? 0 : collection.listingsDelta
	}
	
	return (
		<>
			<div className={styles['nft-collection__column__blue']}>
				<Tooltip content={`${(floorPriceAvg * ethRate).toFixed(2)} $`}>
					<span className={styles['value-with-delta__main-text']}>
						{intToString(floorPriceAvg, true)}
					</span>
				</Tooltip>
			</div>
			<div className={styles['nft-collection__column__blue']}>
				<Tooltip content={`${(floorPriceMin * ethRate).toFixed(2)} $`}>
					<span className={styles['value-with-delta__main-text']}>
						{intToString(floorPriceMin, true)}
					</span>
				</Tooltip>
			</div>
			<div className={styles['nft-collection__column__blue']}>
				<ValueWithDelta delta={volumeDelta}>
					<Tooltip content={`${(volume * ethRate).toFixed(4)} $`}>
						<span className={styles['value-with-delta__main-text']}>
							{intToString(volume, true)}
						</span>
					</Tooltip>
					{/*<Tooltip content={volumePercent.toString()}>*/}
					{/*	<span style={{marginLeft: '-.4rem', fontSize: '1.3rem'}}>*/}
					{/*		{'(' + intToString(volumePercent, true) + '%)'}*/}
					{/*	</span>*/}
					{/*</Tooltip>*/}
				</ValueWithDelta>
			</div>
			<div className={styles['nft-collection__column__blue']}>
				<ValueWithDelta delta={salesChanged}>
					<Tooltip content={transfers.toString()}>
						<span className={styles['value-with-delta__main-text']}>
							{intToString(transfers, false)}
						</span>
					</Tooltip>
				</ValueWithDelta>
			</div>
			
			<div className={styles['nft-collection__column__blue']}>
				<Tooltip content={"Buys - " + buys.toString()}>
					<span className={styles['value-with-delta__main-text']}>
						{intToString(buys, false)}
					</span>
				</Tooltip>
				
				/
				
				<Tooltip content={"Sales - " + sales.toString()}>
						<span className={styles['value-with-delta__main-text']}>
							{intToString(sales, false)}
						</span>
				</Tooltip>
			</div>
			
			<div className={styles['nft-collection__column__blue']}>
				<Tooltip content={salesBuysDelta.toString()}>
					<span className={styles['value-with-delta__main-text']}>
						{intToString(salesBuysDelta, false)}
					</span>
				</Tooltip>
			</div>
			
			{/*<div className={styles['nft-collection__column__blue']}>*/}
			{/*	<ValueWithDelta delta={listingsDelta}>*/}
			{/*		<Tooltip content={(listings * 100).toString()}>*/}
			{/*			<span className={styles['value-with-delta__main-text']}>*/}
			{/*				{`${intToString(listings * 100, false)} %`}*/}
			{/*			</span>*/}
			{/*		</Tooltip>*/}
			{/*	</ValueWithDelta>*/}
			{/*</div>*/}
		</>
	);
};

export default WithActiveGroup;
