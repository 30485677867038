import {setNotifyModalActive, setNotifyModalContent} from "../../../features/notify/notifySlice";
import {
	clearFilter,
	setDescription,
	setIsListCreated, setListCreating,
	setName,
	setStage
} from "../../../features/createStrategy/createListSlice";
import {CreateState} from "../enum";
import {disconnect} from "@wagmi/core";
import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import {useCreateStrategyMutation} from "../../../state/api/strategies/strategies.api";
import {Mixpanel} from "../../../utils/mixpanel";
import {TrackedEventsName} from "../../../utils/mixpanel/enum";

const useFinalBody = () => {
	const dispatch = useAppDispatch()
	
	const { filter, selectAllAddresses, exploredGroups} = useAppSelector(state => state.createList)
	const { isWalletConnected} = useAppSelector(state => state.auth)
	
	const [createFilter] = useCreateStrategyMutation()
	
	const openModal = (content: string) => {
		dispatch(setNotifyModalActive(true))
		dispatch((setNotifyModalContent(content)))
	}
	
	const onFinishHandler = async () => {
		if (!isWalletConnected) {
			openModal('Please connect wallet')
			return
		}
		if (!localStorage.getItem('jwt')) {
			openModal('Please sign the message')
			return
		}
		
		if (!filter.name) {
			openModal('Please enter name')
			return
		}
		
		if (!filter.addresses.length && !selectAllAddresses) {
			openModal('You should select at list one holder')
			return
		}
		
		dispatch(setListCreating())
		
		await createFilter({
			userFilter: filter,
			filterIds: (exploredGroups.length !== 0 && filter.addresses.length === 0) ? exploredGroups : undefined
		})
		.unwrap()
		.then((res) => {
			Mixpanel.track(TrackedEventsName.CreateStrategySuccess, {[TrackedEventsName.CreateStrategySuccess]: filter.name})
			dispatch(setIsListCreated(res.data))
			dispatch(clearFilter())
			dispatch(setStage(CreateState.initial))
		})
		.catch((error) => {
			dispatch(setIsListCreated(undefined))
			
			if (error.data.errorCode === 1) {
				openModal('Yours account has deleted')
				disconnect()
			}
			if (error.data.errorCode === 2) {
				openModal('Group with this name already exist')
			}
		})
	}
	
	const changeNameHandler = (name: string) => {
		if (name.length < 30) {
			dispatch(setName(name))
		}
	}
	
	const changeDescriptionHandler = (description: string) => {
		if (description.length < 200) {
			dispatch(setDescription(description))
		}
	}
	
	const onBackHandler = () => {
		dispatch(setStage(CreateState.initial))
	}
	
	return {
		strategyName: filter.name,
		strategyDescription: filter.description,
		changeNameHandler,
		changeDescriptionHandler,
		onFinishHandler,
		onBackHandler
	}
}

export default useFinalBody
