import React from 'react';

import useHoldersInteraction from "./hook/useHoldersInteraction";

import AddDropdown from "./components/addDropdown/AddDropdown";

import plusIcon from '../../images/icon-plus.svg'
import arrowLeftIcon from '../../images/icon-arrowleft.svg'

import styles from './styles/interaction.module.css'

const HoldersInteraction = () => {
	const {
		onReset,
		showAll,
		onShowAll,
		onSelectAll,
		onCreateAndAdd,
		isResetVisible,
		isShowAllVisible,
		isSelectAllVisible,
		isAddToGroupBlocked,
		isAddToGroupVisible,
		isCreateAndAddVisible,
		isCreateAndAddBlocked,
		getSelectedAddressesAmount,
	} = useHoldersInteraction()
	
	return (
		<div className={styles.container}>
			<div className={styles.left_container}>
				{
					isResetVisible &&
					<button
						style={{marginRight: '3rem'}}
						onClick={() => onReset()}
						className={styles.button_with_icon}
					>
						<img src={arrowLeftIcon} alt={'<-'}/>
						<span>Reset</span>
					</button>
				}
				<span>Selected addresses: {getSelectedAddressesAmount()}</span>
				{
					isCreateAndAddVisible &&
					<button
						onClick={ async () => onCreateAndAdd()}
						className={styles.button_with_icon}
						style={isCreateAndAddBlocked ? {filter: "brightness(.8)", color: "#999", cursor: "not-allowed"} : undefined}
					>
						<img src={plusIcon} alt={'+'} />
						<span>Create Group & add</span>
					</button>
				}
				{
					isAddToGroupVisible &&
					<AddDropdown isBlocked={isAddToGroupBlocked} />
				}
				{
					isShowAllVisible &&
					<div
						className={styles.checkbox_container}
						onClick={() => onShowAll()}
					>
						<input
							className={styles.checkbox_input}
							type={'checkbox'}
							checked={showAll}
							readOnly={true}
						/>
						<span className={styles.checkbox_label}>Show All</span>
					</div>
				}
			</div>
			{
				isSelectAllVisible &&
				<button onClick={() => onSelectAll()} className={styles.button}>
					<span>Select All</span>
				</button>
			}
		</div>
	);
};

export default HoldersInteraction;
