import {SortState} from "../../enum";

import sortToHigh from "../../images/sort-high.svg";
import sortToLow from "../../images/sort-low.svg";
import sortDefault from "../../images/sort-default.svg";

export const getSortIcon = {
	[SortState.toHigh]: sortToHigh,
	[SortState.toLow]: sortToLow,
	[SortState.default]: sortDefault
}

export const intToString = (num: number | string, isFloorOrVolume?: boolean) => {
	if (typeof num === 'string') return num
	if (num === 0) return 0
	let abs = Math.abs(num)
	const rounder = Math.pow(10, 1)
	const isNegative = num < 0
	let key = ''
	
	const powers = [
		{key: 'Q', value: Math.pow(10, 15)},
		{key: 'T', value: Math.pow(10, 12)},
		{key: 'B', value: Math.pow(10, 9)},
		{key: 'M', value: Math.pow(10, 6)},
		{key: 'K', value: 1000}
	]
	
	for (let i = 0; i < powers.length; i++) {
		let reduced = abs / powers[i].value
		reduced = Math.round(reduced * rounder) / rounder
		if (reduced >= 1) {
			abs = reduced
			key = powers[i].key
			break;
		}
	}
	
	if (isFloorOrVolume && !key && num < 1) return (isNegative ? '-' : '') + (Number.isInteger(abs) ? abs : abs.toFixed(4))
	if (!key) return (isNegative ? '-' : '') + (Number.isInteger(abs) ? abs : abs.toFixed())
	return (isNegative ? '-' : '') + abs + key;
}

export const isObjectEmpty = (x: any): boolean => {
	if (x === undefined || x === null) return true
	
	if (typeof x !== 'object') return false
	
	return !Object.keys(x).length
}

export const trimValue = (name: string, bound: number) => {
	if (name.length > bound) {
		return name.slice(0, bound - 1) + '...'
	}
	return name
}
