import React from 'react';

import styles from './spinner.module.css'

interface IProp {
	sizeRem?: number,
}

const Spinner = ({sizeRem}: IProp) => {
	
	const stylesSize = { width: `${sizeRem}rem`, height: `${sizeRem}rem`}
	
	return (
		<div style={stylesSize} className={styles.spinner}></div>
	);
};

export default Spinner;
