import React from 'react'
import classNames from "classnames";
import {TrendingProvider} from "../../lib/context/trending-context";
import Modal from "./components/modal/Modal";
import Header from '../../components/header/Header'
import PeriodBar from "./components/PeriodBar";
import Strategies from "./components/Strategies";
import TrendingTable from "./components/TrendingTable";
import NotifyModal from "../../components/notifyModal/notifyModal";
import settingIcon from '../../images/icon-setting.svg'
import styles from './styles/trending.module.css'

const Trending = () => {
	return (
		<TrendingProvider>
			<Header />
				<div className={styles.trending_container}>
					<div className={styles.trending_header}>
						<h1 className={styles.trending_title}>EXCLUSIVE INSIGHTS INTO MOVES OF TOP NFT PLAYERS</h1>
						<p className={styles.trending_description}>
							<span>Track <span style={{color: '#FD0808', fontWeight: 900}}>300k</span> High-Performing Traders and Alpha Groups</span>
							<span style={{color: '#9342FF'}}>and/or</span>
							<span>Create your own Group of addresses based on Advanced On-chain Data</span>
						</p>
					</div>
					<div className={styles.trending_lists_container}>
						<Strategies />
						<TrendingTable />
					</div>
				</div>
			<Modal />
			<NotifyModal />
		</TrendingProvider>
	);
};

export default Trending
