import {IBound} from "../../../features/createStrategy/interface";
import {CreateState} from "../enum";
import allRoutes from '../../../config/router/routes/allRoutes.json'

export const isEmpty = (x: object | null | undefined) => {
	if (!x) return false
	return !Object.keys(x).length;
}

export const trimAddress = (address: string): string => {
	return  address.slice(0, 3) + '...' + address.slice(address.length - 3, address.length)
}

// export const getPrevPage = (route: string) => {
// 	if (route === allRoutes.PopularListsExplore) return allRoutes.PopularLists
// 	if (route === allRoutes.TrendingExplore) return allRoutes.Trending
// 	return allRoutes.Trending
// }

export const getCreateFilterValue = (bound: IBound | undefined | 'any', state: CreateState): string => {
	if (bound === 'any') return 'any'
	if (bound === undefined) return 'any'
	if (isEmpty(bound.from) && isEmpty(bound.to)) return 'any'
	
	if (state === CreateState.WalletAge && bound?.to) {
		const day = 1000 * 60 * 60 * 24
		const to = bound.to.value
		
		if (Math.floor(to / day) < 1) {
			return 'any'
		}
		if (Math.floor(to / (7 * day)) < 1) {
			return `${Math.floor(to / day)}d+`
		}
		if (Math.floor(to / (30 * day)) < 1) {
			return `${Math.floor(to / (7 * day))}w+`
		}
		if (Math.floor(to / (365 * day)) < 1) {
			return `${Math.floor(to / (30 * day))}m+`
		}
		return `${Math.floor(to / (365 * day))}y+`
	}
	
	if (bound?.from) {
		const from = bound.from.value
		const to = bound.to?.value
		
		if (from === 0 && to === undefined) {
			return 'any'
		}
		
		if (to)
			return `${from * 100}-${to * 100} %`
	}
	return ''
}

export function generateLink(strategyId: string){
	const origin = window.location.origin
	
	return encodeURIComponent(`${origin}/trending/explore-group/${strategyId}`)
}