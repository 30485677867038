import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IGetCurrencyRes} from "./interface";

export const currencyRateApi = createApi({
	reducerPath: "currencyRateApi",
	baseQuery: fetchBaseQuery({
		baseUrl: 'https://api.coingecko.com/api/v3',
	}),
	endpoints: (build) =>  ({
		getCurrencyRate: build.query({
			query: () => {
				return `/simple/price?ids=ethereum&vs_currencies=usd`
			},
			// keepUnusedDataFor: 15 * 60 * 1000,
			transformResponse: (v, meta, arg) => (v as IGetCurrencyRes).ethereum.usd
		})
	})
})

export const { useLazyGetCurrencyRateQuery } = currencyRateApi
