import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IBound} from "../createStrategy/interface";
import {IFilters, IHoldersInteractionState} from "./interface";
import {isEmpty} from "../../pages/exploreStrategy/helper";
import {IStrategies} from "../../state/api/strategies/types";

const initialState: IHoldersInteractionState = {
	showAll: false,
	excludedHolders: [],
	selectAllHolders: false,
	isStrategyEdited: false,
	exploredStrategy: undefined,
	selectedAddresses: [],
	exploredStrategyId: "",
	selectedAddressesAmount: 0,
	filters: {},
}

const holdersInteractionSlice = createSlice({
	name: 'holdersInteraction',
	initialState,
	reducers: {
		setExploredStrategy: (state, action: PayloadAction<IStrategies>) => {
			state.exploredStrategy = action.payload
		},
		clearExploredStrategy: (state) => {
			state.exploredStrategy = undefined
		},
 		setShowAll: (state, action: PayloadAction<boolean>) => {
			state.showAll = action.payload
		},
		setIsStrategyEdited: (state, action: PayloadAction<boolean>) => {
			state.isStrategyEdited = action.payload
		},
		setSelectedAddresses: (state, action: PayloadAction<string[]>) => {
			state.selectedAddresses = action.payload
		},
		setExcludedHolders: (state, action: PayloadAction<string[]>) => {
			state.excludedHolders = action.payload
		},
		setSelectedAddressesAmount: (state, action: PayloadAction<number>) => {
			state.selectedAddressesAmount = action.payload
		},
		setSelectAllHolders: (state, action: PayloadAction<boolean>) => {
			state.selectAllHolders = action.payload
		},
		setFilters: (state, action: PayloadAction<IFilters>) => {
			state.filters = action.payload
		},
		setPln: (state, action: PayloadAction<IBound | 'any'>) => {
			if (!state.filters) {
				if (action.payload === 'any') {
					state.filters = {}
					
					return
				}
				
				state.filters = {
					pnlPercent: action.payload
				}
				
				return
			}
			
			
			if (!(state.filters.unPnlPercent?.from) && !(state.filters.unPnlPercent?.to)) {
				delete state.filters.unPnlPercent
			}
			if (!(state.filters.walletAge?.from) && !(state.filters.walletAge?.to)) {
				delete state.filters.walletAge
			}
			
			
			if (action.payload === 'any' || isEmpty(state.filters.pnlPercent)) {
				delete state.filters.pnlPercent
				
				return
			}
			
			state.filters.pnlPercent = action.payload
		},
		setUnPln: (state, action: PayloadAction<IBound | 'any'>) => {
			if (!state.filters) {
				if (action.payload === 'any') {
					state.filters = {}
					
					return
				}
				
				state.filters = {
					unPnlPercent: action.payload
				}
				
				return
			}
			
			if (!(state.filters.pnlPercent?.from) && !(state.filters.pnlPercent?.to)) {
				delete state.filters.pnlPercent
			}
			if (!(state.filters.walletAge?.from) && !(state.filters.walletAge?.to)) {
				delete state.filters.walletAge
			}
			
			if (action.payload === 'any' || isEmpty(state.filters.unPnlPercent)) {
				delete state.filters.unPnlPercent
				
				return
			}
			
			state.filters.unPnlPercent = action.payload
		},
		setWalletAge: (state, action: PayloadAction<IBound | 'any'>) => {
			if (!state.filters) {
				if (action.payload === 'any') {
					state.filters = {}
					
					return
				}
				
				state.filters = {
					walletAge: action.payload
				}
				
				return
			}
			
			
			if (!(state.filters.pnlPercent?.from) && !(state.filters.pnlPercent?.to)) {
				delete state.filters.pnlPercent
			}
			if (!(state.filters.unPnlPercent?.from) && !(state.filters.unPnlPercent?.to)) {
				delete state.filters.unPnlPercent
			}
			
			
			if (action.payload === 'any') {
				delete state.filters.walletAge
				
				return
			}
			
			state.filters.walletAge = action.payload
		},
		clearSelectedAddresses: (state) => {
			state.selectedAddresses = []
		},
		clearSelectedAddressesAmount: (state) => {
			state.selectedAddressesAmount = 0
		},
		addSelectedAddresses: (state, action: PayloadAction<string>) => {
			state.isStrategyEdited = true
			
			state.selectedAddresses.push(action.payload)
		},
		removeSelectedAddresses: (state, action) => {
			state.isStrategyEdited = true
		
			const index = state.selectedAddresses.findIndex(address => address === action.payload)
			
			if (index === -1) {
				return
			}
			
			state.selectedAddresses.splice(index, 1)
		},
		addExcludedHolders: (state, action: PayloadAction<string>) => {
			state.isStrategyEdited = true
			
			state.excludedHolders.push(action.payload)
		},
		removeExcludedHolders: (state, action) => {
			state.isStrategyEdited = true
		
			const index = state.excludedHolders.findIndex(address => address === action.payload)
			
			if (index === -1) {
				return
			}
			
			state.excludedHolders.splice(index, 1)
		},
		setExploredStrategyId: (state, action: PayloadAction<string>) => {
			state.exploredStrategyId = action.payload
		},
		clearHoldersInteraction: (state) => {
			state.filters = {}
			state.showAll = false
			state.excludedHolders = []
			state.selectedAddresses = []
			state.exploredStrategyId = ""
			state.selectAllHolders = false
			state.isStrategyEdited = false
			state.exploredStrategy = undefined
			state.selectedAddressesAmount = 0
		}
	}
})

export const {
	setPln,
	setUnPln,
	setFilters,
	setShowAll,
	setWalletAge,
	setExcludedHolders,
	addExcludedHolders,
	setIsStrategyEdited,
	setSelectAllHolders,
	setExploredStrategy,
	addSelectedAddresses,
	setSelectedAddresses,
	clearExploredStrategy,
	setExploredStrategyId,
	clearSelectedAddressesAmount,
	clearSelectedAddresses,
	removeExcludedHolders,
	removeSelectedAddresses,
	clearHoldersInteraction,
	setSelectedAddressesAmount,
} = holdersInteractionSlice.actions

export default holdersInteractionSlice.reducer
