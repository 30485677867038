import React, {useEffect, useState} from 'react';
import {useAccount} from "wagmi";
import {useAppDispatch, useAppSelector} from "./state/hooks";
import Router from "./config/router/Router";
import Auth from "./pages/auth/Auth";
import Plug from "./pages/plug/Plug";
import {getJwt} from "./features/auth/asyncThunk";
import {
    clearActiveStrategy,
    setFollowedFilters,
    setFollowedFiltersIsLoading,
    setUserAddress
} from "./features/user/userSlice";
import {setDisconnectWallet, setIsLoading} from "./features/auth/authSlice";
import {isProduction} from "./utils/config";
import {useLocation} from "react-router-dom";
import useGetCurrencyRate from "./lib/hook/useGetCurrencyRate";
import {
    useLazyGetFollowedFiltersQuery
} from "./state/api/user/user.api";
import allRoutes from "./config/router/routes/allRoutes.json"
import styles from './app.module.css'

function App () {
    const dispatch = useAppDispatch()
    
    const [getFollowedFilters] = useLazyGetFollowedFiltersQuery()
    
    const location = useLocation()
    
    const [secureData, setSecureData] = useState(localStorage.getItem('secureData') || null)
    
    const {getCurrencyRate} = useGetCurrencyRate()
    
    const {isWalletConnected} = useAppSelector(state => state.auth)
    
    const {isConnected} = useAccount({
        onConnect({ address, isReconnected, connector}) {
            if (!address) return
            
            dispatch(getJwt(address))
            dispatch(setUserAddress(address))
        },
        onDisconnect() {
            dispatch(clearActiveStrategy())
            dispatch(setFollowedFilters([]))
            dispatch(setUserAddress(''))
            dispatch(setDisconnectWallet())
            localStorage.removeItem('jwt')
        }
    })
    
    useEffect(() => {
        getCurrencyRate()
    }, [])
    
    useEffect(() => {
        if (!isConnected) dispatch(setIsLoading(false))
    }, [isConnected])
    
    useEffect(() => {
        setSecureData(localStorage.getItem('secureData'))
    }, [secureData, localStorage])
    
    useEffect(() => {
        if (!isWalletConnected) return
        
        dispatch(setFollowedFiltersIsLoading(true))
        
        getFollowedFilters({withoutDefault: location.pathname === allRoutes.Trending})
        .then(res => {
            if (res.isSuccess) dispatch(setFollowedFilters(res.data))
        })
    }, [location.pathname, isWalletConnected])
    
    return (
      <>
          <div className={styles.container}>
            {
              (!secureData && !isProduction)
                ? <Auth setSecureData={setSecureData} />
                : <Router />
            }
          </div>
          <Plug />
      </>
    );
}

export default App;
