import React from 'react';
import Skeleton from "../../../../../components/skeleton/Skeleton";
import {SkeletonType} from "../../../../../components/skeleton/emun";
import Item from "./item";
import {useExplorerDropdownActions} from "../../../../../lib/context/explorer-dropdown-context";
import styles from "../explorer.module.css";
import FetchMore from "./fetch-more";

const ExplorerBody = () => {
	const {
		limit,
		groups: {
			data, isLoading
		},
		searchedValue,
		exploredGroups,
		onSearchHandler,
		isFetchMoreVisible
	} = useExplorerDropdownActions()
	
	return (
		<div className={styles.body}>
			<div className={styles.body_item_wrapper}>
				<div className={styles.body_item}>
					<input
						className={styles.search}
						placeholder={"Search..."}
						defaultValue={searchedValue}
						onChange={(e) => onSearchHandler(e.target.value)}
					/>
				</div>
			</div>
			<div className={styles.scrolled_body}>
				{
					isLoading && (
						<Skeleton type={SkeletonType.DropDownItem} amount={limit} />
					)
					||
					data.map(({name, id}) =>
						<Item
							id={id}
							name={name}
							isChecked={exploredGroups.includes(id)}
							key={id}
						/>
					)
				}
				{
					isFetchMoreVisible && (
						<FetchMore />
					)
				}
			</div>
		</div>
	);
};

export default ExplorerBody;