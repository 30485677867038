import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import classNames from "classnames";

import {setTrendingPeriod} from "../../../features/timePeriod/timePeriodSlice";

import {periodTrending} from "../config";

import styles from '../styles/trending.module.css'
import {PeriodTrendingEnum} from "../enum";
import {Mixpanel} from "../../../utils/mixpanel";
import {TrackedEventsName} from "../../../utils/mixpanel/enum";

const PeriodBar = () => {
	const dispatch = useAppDispatch()
	const { trendingPeriod } = useAppSelector(state => state.timePeriod)
	
	const clickHandler = (id: PeriodTrendingEnum) => {
		dispatch(setTrendingPeriod(id))
		Mixpanel.track(TrackedEventsName.ChangeTimeRange, {[TrackedEventsName.ChangeTimeRange]: id})
	}
	
	return (
		<div className={styles.periodBar_container}>
			{
				Object.values(periodTrending).map(({value, id}) => (
					<div
						onClick={() => clickHandler(id)}
						className={(trendingPeriod === id)
							? classNames(styles.periodBar_item, styles.periodBar_item_active)
							: styles.periodBar_item
						}
						key={id}
					>
						<span>
							{ value }
						</span>
					</div>
				))
			}
		</div>

	);
};

export default PeriodBar
