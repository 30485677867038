import React from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import classNames from "classnames";

import {useAppSelector} from "../../state/hooks";

import Search from "./components/Search";
import ConnectButton from "./components/ConnectButton";
import Remainder from "./components/Remainder";

import logo from '../../images/nftbull_logo.svg'
import iconPlus from '../../images/icon-plus.svg'

import { socialMap } from "./config";

import allRoutes from '../../config/router/routes/allRoutes.json'

import styles from './styles/header.module.css'

const Header = () => {
	const {isRemainderVisible} = useAppSelector(state => state.user)
	
	const location = useLocation()
	const navigate = useNavigate()
	
	const logoClickHandler = () => {
		navigate(allRoutes.Trending)
	}
	
	const isActiveLink = (route: string) => {
		return location.pathname === route;
	}
	
	return (
		<>
			<header className={styles.header}>
				<div className={styles.left_container}>
					<div
						className={styles.logo_container}
						onClick={() => logoClickHandler()}
					>
						<div className={styles.logo_wrapper}>
							<img
								className={styles.nftBull_logo}
								src={logo}
								alt={''}
							/>
						</div>
						<div className={styles.title_container}>
							<h2>alpha</h2>
							<h1>Bulltor</h1>
						</div>
					</div>
					<Search />
				</div>
				<div className={styles.right_container}>
					<nav className={styles.header_nav}>
								<Link
									to={allRoutes.Trending}
									className={
										isActiveLink(allRoutes.Trending)
											? classNames(styles.nav_link, styles.active)
											: styles.nav_link
									}
								>
									Trending
								</Link>
								<Link
									to={allRoutes.Mint}
									className={
										isActiveLink(allRoutes.Mint)
											? classNames(styles.nav_link, styles.active)
											: styles.nav_link
									}
								>
									Mint
								</Link>
								<Link
									to={allRoutes.PopularLists}
									className={
										isActiveLink(allRoutes.PopularLists)
											? classNames(styles.nav_link, styles.active)
											: styles.nav_link
									}
								>
									POPULAR GROUPS
								</Link>
					</nav>
					{
						socialMap.map((social, index) => (
							<Link
								to={social.url}
								target={'_blank'}
								className={styles.social_link}
								key={index}
							>
								<img src={social.icon} alt={''}/>
							</Link>
						))
					}
						<ConnectButton/>
						<div
							className={styles.createList_button}
							onClick={() => navigate(allRoutes.CreateLists)}
						>
							<img src={iconPlus} alt={''} />
							<span>CREATE GROUP</span>
						</div>
				</div>
			</header>
			{isRemainderVisible && <Remainder />}
		</>
	);
};

export default Header;
