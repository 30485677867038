import React from 'react';

import {ReactComponent as Logo} from "../../images/nftbull_logo.svg"

import styles from './style.module.css'

const Plug = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.logo_container}>
					<Logo className={styles.logo} />
					<div className={styles.logo_text_container}>
						<span className={styles.logo_text_hint}>alpha</span>
						<span className={styles.logo_text_title}>Bulltor</span>
					</div>
				</div>
				<span className={styles.content}>Currently, Bulltor is only available on desktop, with a mobile version coming soon. Stay tuned for updates!</span>
			</div>
		</div>
	);
};

export default Plug;