import React, {useRef} from 'react';
import ExplorerHeader from "../explorer-header";
import ExplorerBody from "../explorer-body";
import {
	useExplorerDropdownActions
} from "../../../../../lib/context/explorer-dropdown-context";
import styles from '../explorer.module.css';
import useOnOutsideClick from "../../../../../lib/hook/use-on-outside-click";

const ExplorerTemplate = () => {
	const ref = useRef<HTMLDivElement>(null)
	
	const {open, setOpen} = useExplorerDropdownActions()
	
	useOnOutsideClick({
		ref: ref,
		isListening: open,
		clickHandler: () => setOpen(false)
	})
	
	return (
			<div className={styles.container} ref={ref}>
				<ExplorerHeader />
				{
					open && (
						<ExplorerBody />
					)
				}
			</div>
	);
};

export default ExplorerTemplate;
