import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from "../../../state/hooks";
import { setFiltersSort } from "../../../features/sort/sortSlice";

import {getSortIcon} from "../helper";

import {ISort} from '../../../features/sort/interface';

import styles from "../styles/popularLists.module.css"
import {SortState, TooltipPosition} from "../../../enum";
import Tooltip from "../../../components/tooltip/Tooltip";
import TooltipIcon from "../../../images/icon-tooltip.svg";

interface IProp {
	id: string,
	name: string,
	sortable: boolean
	icon?: string
	tooltip?: string
}

const HeaderItem = ({name, sortable, id, icon, tooltip}: IProp) => {
	const dispatch = useAppDispatch()
	const { filtersSort } = useAppSelector(state => state.sort)

	const [sortIcon, setSortIcon] = useState(getSortIcon[SortState.default])

	const clickHandler = () => {
		if (!sortable) {
			return;
		}

		const res:ISort = {
			sortedField: "",
			sortType: SortState.toHigh
		}
		if (filtersSort.sortedField !== id) {
			res.sortedField = id
			res.sortType = SortState.toLow

			dispatch(setFiltersSort(res))

			return;
		}

		if (filtersSort.sortType === SortState.toLow){
			res.sortType = SortState.toHigh
		} else {
			res.sortType = SortState.toLow
		}
		
		res.sortedField = id
		dispatch(setFiltersSort(res))
	}

	useEffect(() => {
		if (filtersSort.sortedField !== id) {
			setSortIcon(getSortIcon[SortState.default])
			return
		}

		setSortIcon(getSortIcon[filtersSort.sortType])
		
	}, [id, filtersSort])

	return (
		<div
			onClick={() => clickHandler()}
			className={styles.table_header_item}
		>
			{
				sortable && (
					<img className={styles.sort_img} src={sortIcon} alt={''} />
				)
			}
			<span>{name}</span>
			{
				icon && (
					<img className={styles.tab_header_item_icon} src={icon} alt={''}/>
				)
			}
			{
				tooltip && (
					<Tooltip
						active={true}
						style={{position: TooltipPosition.RIGHT}}
						content={tooltip}
					>
						<img src={TooltipIcon} alt={'tooltip'} className={styles.tab_header_tooltip} />
					</Tooltip>
				)
			}
		</div>
	);
};

export default HeaderItem;
