import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BACKEND_URL} from "../../../utils/config";
import {IFinedCollection, INftCollectionResponse} from "./types";
import {IResponse} from "../../../interfaces";
import {IGetNftListQuery, ISearchNftCollection} from "./interface";

export const nftCollectionsApi = createApi({
    reducerPath: 'nftCollections',
    baseQuery: fetchBaseQuery({ baseUrl: BACKEND_URL }),
    endpoints: (builder) => ({
        getNftList: builder.query({
            query: ({offset, limit, range, filters, userFilters, name}: IGetNftListQuery) => {
                name = encodeURIComponent(name ?? "")
                range = encodeURIComponent(range)
                userFilters = encodeURIComponent(userFilters ?? "")
                const filtersString = encodeURIComponent(JSON.stringify(filters))
                
                if (name && userFilters)
                    return `/trending?offset=${offset}&limit=${limit}&range=${range}&filters=${filtersString}&userFilters=${userFilters}&name=${name}`
                if (name)
                    return `/trending?offset=${offset}&limit=${limit}&range=${range}&filters=${filtersString}&name=${name}`
                if (userFilters)
                    return `/trending?offset=${offset}&limit=${limit}&range=${range}&filters=${filtersString}&userFilters=${userFilters}`
                
                return `/trending?offset=${offset}&limit=${limit}&range=${range}&filters=${filtersString}`
            },
            transformResponse: (v) => (v as IResponse<INftCollectionResponse>).data
        }),
        searchNftCollection: builder.query({
            query: ({name}: ISearchNftCollection) => `/nft-collections/search?name=${encodeURIComponent(name)}`,
            transformResponse: (v) => (v as IResponse<IFinedCollection[]>).data
        })
    }),
})

export const {
    useLazyGetNftListQuery,
} = nftCollectionsApi
