import React from "react";
import {ReactComponent as Checked} from "../../../../../../images/icon-check.svg";
import {useAppDispatch} from "../../../../../../state/hooks";
import {addExploredGroup, removeExploredGroup} from "../../../../../../features/createStrategy/createListSlice";
import {trimValue} from "../../../../../../lib/helper";
import classNames from "classnames";
import styles from "../../explorer.module.css";

interface BodyItemProps {
	id: string
	name: string
	isChecked: boolean
}

const Item = ({name, id, isChecked}: BodyItemProps) => {
	const dispatch = useAppDispatch()
	
	const onClickHandler = () => {
		isChecked
			? dispatch(removeExploredGroup(id))
			: dispatch(addExploredGroup(id))
	}
	
	return (
		<div
			className={
				isChecked
					? classNames(styles.body_item_wrapper, styles.body_item_selected) :
					styles.body_item_wrapper
			}
			
			onClick={onClickHandler}
		>
			<div className={styles.body_item}>
				{isChecked && <Checked className={styles.checked_icon}/>}
				<span>{trimValue(name, 20)}</span>
			</div>
		</div>
	)
}

export default Item;