export enum TrackedEventsName {
	SignInSuccess = 'Successful Authorization',
	SignInFailed = 'Failed Authorization',
	CreateStrategySuccess = 'Strategy Created',
	FollowStrategy = 'Strategy Followed',
	UnFollowStrategy = 'Strategy Unfollowed',
	ExploreStrategy = 'Strategy Explored',
	ActivateStrategy = 'Strategy Activated',
	ChangeTimeRange = 'Time Range Changed',
	ActivateFilter = 'Filter Activated',
	ClickDetails = 'Details Button Clicked',
	ClickBuy = 'Buy Button Clicked'
}