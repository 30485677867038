import {IStrategies} from "../../../state/api/strategies/types";
import {PeriodTrendingEnum} from "../enum";

export const isStrategyActive = (strategy: IStrategies, activeStrategies?: IStrategies[]) => {
	if (!activeStrategies) return false
	return !!activeStrategies.find(activeStrategy => strategy.id === activeStrategy.id)
}

interface IGetCollectionBadges {
	filtersWithThisCollection?: string[],
	activeStrategies: IStrategies[]
}

export const getCollectionBadges = ({filtersWithThisCollection, activeStrategies}: IGetCollectionBadges) => {
	if (!filtersWithThisCollection) return undefined
	
	const badges: string[] = []
	
	activeStrategies.forEach((strategy) => {
		const matchedId = filtersWithThisCollection.find(id => id === strategy.id)
		
		if (matchedId) badges.push(strategy.name)
	})
	
	return badges
}

interface IDataChart {
	price: number,
	date: number
}

export const floorPriceChartToDataChart = (value: number[][], timePeriod: PeriodTrendingEnum) => {
	let dataChart: IDataChart[] = []
	
	const trimValue = {
		[PeriodTrendingEnum.min5]: 1,
		[PeriodTrendingEnum.h1]: 2,
		[PeriodTrendingEnum.h6]: 2,
		[PeriodTrendingEnum.d1]: 4,
		[PeriodTrendingEnum.d7]: 11,
		[PeriodTrendingEnum.d30]: 1
	}
	
	if (!value.length) return [{
			price: 0,
			date: -1
		},
		{
			price: 0,
			date: -1
		}
	]
	
	interface IFloorPriceChart {
		prices: number[]
		dates: number[]
	}
	
	let floorPriceChart: IFloorPriceChart = {
		dates: value[0],
		prices: value[1]
	}
	
	if (value[0].length < value[1].length) floorPriceChart = {
		dates: value[0],
		prices: value[1].slice(0, value[0].length)
	}
	if (value[1].length < value[0].length) floorPriceChart = {
		dates: value[0].slice(0, value[1].length),
		prices: value[1]
	}
	
	for (let i = 0; i < floorPriceChart.prices.length; i++) {
		if (i % trimValue[timePeriod] === 0)
			dataChart.push({
				date: floorPriceChart.dates[i],
				price: floorPriceChart.prices[i]
			})
	}
	
	
	return dataChart
}
