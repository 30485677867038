import React, {useEffect} from 'react';

import useFinalBody from "../../hook/use-final-body";

import styles from '../../styles/createlist.module.css'
import {useAppSelector} from "../../../../state/hooks";

const FinalBody = () => {
	
	const {
		strategyName,
		onBackHandler,
		onFinishHandler,
		changeNameHandler,
		strategyDescription,
		changeDescriptionHandler,
	} = useFinalBody()
	
	return (
		<>
			<div className={styles.create_body}>
				<div style={{maxWidth: 'none'}} className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>Name</span>
					</div>
					<input
						value={strategyName}
						placeholder={'Enter name'}
						onChange={(event) => changeNameHandler(event.target.value)}
					/>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>Note</span>
					</div>
					<input
						onChange={(event) => changeDescriptionHandler(event.target.value)}
						value={strategyDescription}
						placeholder={'Enter note'}
					/>
				</div>
			</div>
			<div className={styles.create_buttons_container}>
				<div
					onClick={() => onFinishHandler()}
					className={styles.create_buttons_next}
				>
					<span>Finish</span>
				</div>
				<div
					onClick={() => onBackHandler()}
					className={styles.create_buttons_back}
				>
					<span>Back</span>
				</div>
			</div>
		</>
	);
};

export default FinalBody;
