import React from 'react';
import {useAppDispatch} from "../../../state/hooks";
import {ReactComponent as CrossIcon} from '../../../images/icon-cross.svg'
import styles from '../styles/remainder.module.css';
import {setIsRemainderVisible} from "../../../features/user/userSlice";

const Remainder = () => {
	const dispatch = useAppDispatch()
	
	return (
		<div className={styles.container}>
			<div className={styles.title_container}>
				<span className={styles.title}>
					BULLTOR is currently in Alpha stage with limited data available.
					Your feedback is crucial in helping us improve.
				</span>
				<a className={styles.link} href={'https://discord.com/invite/Pqf7gpasas'} target={'_blank'}>
					Join Discord
				</a>
			</div>
			<CrossIcon
				onClick={() => dispatch(setIsRemainderVisible(false))}
				className={styles.close_icon}
			/>
		</div>
	);
};

export default Remainder;