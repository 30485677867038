import {useGetExploredHoldersMutation} from "../../../state/api/strategies/strategies.api";
import {ISort} from "../../../features/sort/interface";
import {IFilters} from "../../../features/holdersInteraction/interface";
import {useEffect, useRef, useState} from "react";
import {IExploredHoldersData, IStrategies} from "../../../state/api/strategies/types";
import {setTotalAmount} from "../../../features/pagination/paginationSlice";
import {useAppDispatch} from "../../../state/hooks";
import {debounce} from "lodash";

interface UseGetHoldersProps {
	currentPage: number
	showAll: boolean
	filters: IFilters
	rowsAmount: number
	holdersSort: ISort
	exploredStrategy: IStrategies
}

interface LoadingResponseType {
	isError: false,
	isLoading: true,
	isSuccess: false,
	data: undefined
}

interface ErrorResponseType {
	isError: true,
	isLoading: false,
	isSuccess: false,
	data: undefined
}

interface SuccessResponseType {
	isError: false
	isLoading: false
	isSuccess: true
	data: IExploredHoldersData
}

export type UseGetHoldersResponse = SuccessResponseType | LoadingResponseType | ErrorResponseType

const LoadingResponse: LoadingResponseType = {
	data: undefined,
	isSuccess: false,
	isLoading: true,
	isError: false
}

const ErrorResponse: ErrorResponseType = {
	isError: true,
	isLoading: false,
	isSuccess: false,
	data: undefined
}

const useGetHolders = (props: UseGetHoldersProps): UseGetHoldersResponse => {
	const dispatch = useAppDispatch()
	
	const {
		filters,
		showAll,
		rowsAmount,
		holdersSort,
		currentPage,
		exploredStrategy,
	} = props
	
	const debouncedFetch = useRef(
		debounce((props: Omit<UseGetHoldersProps, "exploredStrategy">) => fetchHolders(props), 300)
	).current
	
	useEffect(() => {
		return () => debouncedFetch.cancel()
	}, [debouncedFetch])
	
	const [
		response,
		setResponse
	] = useState<UseGetHoldersResponse>(LoadingResponse)
	
	const [getHolders] = useGetExploredHoldersMutation()
	
	const fetchHolders = ({
	  holdersSort, rowsAmount, currentPage, filters, showAll
	}: Omit<UseGetHoldersProps, "exploredStrategy">) => {
		
		setResponse(LoadingResponse)
		
		getHolders({
			arg: {
				filter: {
					[holdersSort.sortedField]: holdersSort.sortType
				},
				filterIds: exploredStrategy.id,
				offset: (currentPage - 1) * rowsAmount,
				limit: rowsAmount
			},
			body: {
				userFilter: {
					name: "",
					filters: filters
				},
				showAll: showAll
			}
		})
			.unwrap()
			.then(res => {
				dispatch(setTotalAmount(res.total))
				setResponse({
					isError: false,
					isLoading: false,
					isSuccess: true,
					data: res
				})
			})
			.catch(() => {
				setResponse(ErrorResponse)
			})
	}
	
	useEffect(() => {
		setResponse(LoadingResponse)
		
		debouncedFetch(props)
	}, [holdersSort, rowsAmount, currentPage, filters, showAll])
	
	return response
}

export default useGetHolders