import React, {createContext, ReactElement, useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {debounce} from "lodash";
import {
	useGetPopularStrategiesQuery,
} from "../../state/api/strategies/strategies.api";
import {IStrategies} from "../../state/api/strategies/types";
import {setExploredGroup} from "../../features/createStrategy/createListSlice";

interface ExplorerDropdownContext {
	limit: number,
	open: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	searchedValue: string
	setSearchedValue: React.Dispatch<React.SetStateAction<string>>
	exploredGroups: string[]
	groups: {
		data: IStrategies[]
		isLoading: boolean
		isError: boolean
	}
	isFetchMoreVisible: boolean
	onFetchMoreHandler: () => void
	onSearchHandler: (name: string) => void
}

const ExplorerActionContext  = createContext<ExplorerDropdownContext | null>(null)

interface ExplorerProviderProps {
	children: ReactElement | ReactElement[] | string
}

export const ExplorerDropdownProvider = ({children}: ExplorerProviderProps) => {
	const dispatch = useAppDispatch()
	
	const { exploredGroups} = useAppSelector(state => state.createList)
	
	const limit: number = 200
	
	const [open, setOpen] = useState<boolean>(false)
	const [offset, setOffset] = useState<number>(0)
	const [groups, setGroups] = useState<IStrategies[]>([])
	const [searchedValue, setSearchedValue] = useState<string>("")
	const [totalGroupsAmount, setTotalGroupsAmount] = useState<number>(0)
	
	const debouncedSearch = useRef(debounce((name: string) => {
		setSearchedValue(name)
	}, 300)).current
	
	useEffect(() => {
		return () => debouncedSearch.cancel()
	}, [debouncedSearch])
	
	const {data, isLoading, isError } = useGetPopularStrategiesQuery(
		{
			offset: offset * limit,
			limit: limit,
			filters: {
				followersCount: -1
			},
			name: searchedValue
		}
	)
	
	const setData = () => {
		if (data === undefined) return
		
		setTotalGroupsAmount(data.total)
		
		if (offset === 0) setGroups(data.list)
		else setGroups([...groups, ...data.list])
	}
	
	useEffect(() => {
	
		setData() // set groups and total amount
	
	}, [data])
	
	const onFetchMoreHandler = (): void => {
		setOffset(offset + 1)
	}
	
	const onSearchHandler = (name: string): void => {
		if (exploredGroups.length !== 0) dispatch(setExploredGroup([]))
		setOffset(0)
		debouncedSearch(name)
	}
	
	const isFetchMoreVisible: boolean = totalGroupsAmount > (limit * (offset + 1))
	
	return (
		<ExplorerActionContext.Provider
			value={{
				open,
				limit,
				setOpen,
				searchedValue,
				onSearchHandler,
				exploredGroups,
				setSearchedValue,
				isFetchMoreVisible,
				onFetchMoreHandler,
				groups: { data: groups, isLoading, isError },
			}}
		>
			{
				children
			}
		</ExplorerActionContext.Provider>
	)
}

export const useExplorerDropdownActions = () => {
	const context = useContext(ExplorerActionContext)
	if (context === null) {
		throw new Error(
			"useExplorerDropdownActions must be used within a ExplorerDropdownProvider"
		)
	}
	
	return context
}
