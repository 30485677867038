import React from 'react';
import classNames from "classnames";

import {IMints} from "../../../state/api/mints/types";

import chartImg from "../../../images/graph.svg"

import styles from '../../trending/styles/trending.module.css'
import Tooltip from "../../../components/tooltip/Tooltip";
import {intToString, trimValue} from "../../../lib/helper";

const DeltaValue = ({delta, value, symbol} : {delta?: number, value?: number, symbol?: string}) => {
	return (
		<div className={styles['value-with-delta']}>
			{!!delta &&
				<div
					className={classNames(
						styles['value-with-delta__delta-text'],
						{
							[styles['value-with-delta__delta-text-profit']]: delta >= 0,
							[styles['value-with-delta__delta-text-lose']]: delta < 0,
						}
					)}
				>
					<div
						className={styles['value-with-delta__delta-text__triangle']}
						style={{transform: `rotate(${delta > 0? 0: 180}deg)`}}
					>
						▲
					</div>
					{Number.isInteger(delta) ? delta: delta.toFixed(2)}%
				</div>
			}
			{!!value ?
				<Tooltip
					active={!(intToString(value) === value.toString())}
					content={Number.isInteger(value) ? value.toString() : value.toFixed(4) + (symbol ? symbol : '')}
				>
					<div className={styles['value-with-delta__main-text']}>
						{intToString(value) + (symbol ? symbol : '')}
					</div>
				</Tooltip>
				: <span>0</span>
			}
		</div>
	)
}

const MintItem = (mint: IMints) => {
	const buyHandler = () => {
		window.open(mint.openseaLink, '_blank')
	}
	
	return (
		<div className={styles.body_row}>
			<div className={styles.body_item_img}>
				<img src={mint.logo} alt={''}/>
				<Tooltip active={trimValue(mint.name, 20) !== mint.name} content={mint.name}>
					<span>
						{ trimValue(mint.name, 20) }
					</span>
				</Tooltip>
			</div>
			<div className={styles.body_item}>
				<DeltaValue
					delta={mint.stats.mintsChanged}
					value={mint.stats.mints}
				/>
			</div>
			<div className={styles.body_item}>
				<DeltaValue
					value={mint.stats.totalMints}
				/>
			</div>
			<div className={styles.body_item}>
				<DeltaValue
					delta={mint.stats.mintPriceChanged}
					value={mint.stats.mintPrice}
				/>
			</div>
			<div className={styles.body_item}>
				<DeltaValue
					delta={mint.stats.twitterScoreChanged}
					value={mint.stats.twitterScore || 0}
				/>
			</div>
			<div className={styles.body_item}>
				<img className={styles.body_item_graph} src={chartImg} alt={''}/>
			</div>
			<div className={styles.body_item}>
				<button
					className={styles.button}
					style={{'borderColor': '#E72247'}}
					onClick={buyHandler}
				>
					Buy
				</button>
				<button
					className={styles.button}
					style={{'borderColor': '#2F2F2F'}}
				>
					Details
				</button>
			</div>
		</div>
	);
};

export default MintItem;
