import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../state/hooks";
import {getCreateFilterValue} from "../../helper";
import {setContractAddress, setStage} from "../../../../features/createStrategy/createListSlice";

import {CreateState} from "../../enum";

import tooltipIcon from "../../../../images/icon-tooltip.svg";
import arrowIcon from "../../../../images/icon-arrowdown.svg";

import styles from "../../styles/createlist.module.css";
import useCreate from "../../hook/use-create";
import Tooltip from "../../../../components/tooltip/Tooltip";
import {TooltipPosition} from "../../../../enum";
import {debounce} from "lodash";

const InitialBody = () => {
	const dispatch = useAppDispatch()
	
	const {filter, contractAddresses} = useAppSelector(state => state.createList)
	
	const { setAllFiltersAny } = useCreate()
	
	const debouncedSetAddress = useRef(debounce((value: string) => {
		dispatch(setContractAddress(value))
	}, 300)).current
	
	const onNext = () => {
		if (filter.filters === undefined) {
			if (!(!!filter.addresses || !!filter.excludedAddresses)) {
				dispatch(setStage(CreateState.PnL))
				
				return
			}
			
			setAllFiltersAny()
			
			return
		}
		
		if (!getCreateFilterValue(filter.filters.pnlPercent, CreateState.PnL)) {
			dispatch(setStage(CreateState.PnL))
			return
		}
		if (!getCreateFilterValue(filter.filters.unPnlPercent, CreateState.UnPlN)) {
			dispatch(setStage(CreateState.UnPlN))
			return;
		}
		if (!getCreateFilterValue(filter.filters.walletAge, CreateState.WalletAge)) {
			dispatch(setStage(CreateState.WalletAge))
			return;
		}
		
		dispatch(setStage(CreateState.Finish))
	}
	
	useEffect(() => {return () => debouncedSetAddress.cancel()}, [debouncedSetAddress])
	
	return (
		<>
			<div className={styles.create_body}>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>PnL</span>
						<Tooltip
							active={true}
							content={
								<>
									<span>PnL (Profit and Loss)  = Sale Price - Buy Price</span>
									<span>PnL% = [(Sale Price - Buy Price) / Buy Price] * 100</span>
								</>
							}
							style={{position: TooltipPosition.RIGHT, maxWidth: 45}}
						>
							<img src={tooltipIcon} alt={''} />
						</Tooltip>
					</div>
					<div
						onClick={() => dispatch(setStage(CreateState.PnL))}
						className={styles.create_item_button}
					>
						<span>{filter.filters === undefined ? "Choose Amount" : getCreateFilterValue(filter.filters.pnlPercent, CreateState.PnL)}</span>
						<img src={arrowIcon} alt={''} />
					</div>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>uPnL</span>
						<Tooltip
							active={true}
							style={{position: TooltipPosition.RIGHT, maxWidth: 55}}
							content={
								<>
									<span>uPnL (Unrealized Profit and Loss) = Current Price - Buy Price</span>
									<span>uPnL% = [(Current Price - Buy Price) / Buy Price] * 100</span>
								</>
							}
						>
							<img src={tooltipIcon} alt={''} />
						</Tooltip>
					</div>
					<div
						onClick={() => dispatch(setStage(CreateState.UnPlN))}
						className={styles.create_item_button}
					>
						<span>{filter.filters === undefined ? "Choose Amount" : getCreateFilterValue(filter.filters.unPnlPercent, CreateState.UnPlN)}</span>
						<img src={arrowIcon} alt={''} />
					</div>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>Wallet Age</span>
					</div>
					<div
						onClick={() => dispatch(setStage(CreateState.WalletAge))}
						className={styles.create_item_button}
					>
						<span>{filter.filters === undefined ? "Choose Amount" : getCreateFilterValue(filter.filters.walletAge, CreateState.WalletAge)}</span>
						<img src={arrowIcon} alt={''} />
					</div>
				</div>
				<div className={styles.create_body_item}>
					<div className={styles.create_item_title_wrapper}>
						<span className={styles.create_text}>Filter by NFT holdings</span>
					</div>
					<input
						onChange={(event) => {
							debouncedSetAddress(event.target.value)
						}}
						defaultValue={contractAddresses}
						placeholder={'Paste a Contract Address of NFT collection or Single Address'}
					/>
				</div>
			</div>
			<div className={styles.create_buttons_container}>
				<div
					onClick={() => onNext()}
					className={styles.create_buttons_next}
				>
					<span>Next</span>
				</div>
				
			</div>
		</>
	);
};

export default InitialBody;
