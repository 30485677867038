import React, {useEffect, useRef, useState} from 'react';
import useHoldersInteraction from "../../hook/useHoldersInteraction";
import useGetUserStrategies from "../../hook/useGetUserStrategies";
import {callNotifyModal, setNotifyModalActive, setNotifyModalContent} from "../../../../features/notify/notifySlice";
import {useAppDispatch} from "../../../../state/hooks";
import styles from './styles.module.css'

interface IProp {
	isBlocked?: boolean
}

const AddDropdown = ({isBlocked}: IProp) => {
	const dispatch = useAppDispatch()
	
	const [isVisible, setIsVisible] = useState<boolean>(false)
	
	const ref = useRef<HTMLDivElement>(null)
	
	const {
		userFilters,
		isContentPending,
		isError,
		error,
		onUserFiltersRefetch,
	} = useGetUserStrategies()
	
	const {
		onAddToStrategy,
		addToGroupTitle,
		exploredStrategyId
	} = useHoldersInteraction()
	
	
	const onClickOutside = (e: MouseEvent) => {
		if (!isVisible) {
			return
		}
		if (!(e.target instanceof Element)) {
			return
		}
		if (ref.current && ref.current.contains(e.target)) {
			return;
		}
		
		setIsVisible(false)
	}
	
	useEffect(() => {
		document.addEventListener('click', (e) => onClickOutside(e))
		
		return () => {
			document.removeEventListener('click', (e) => onClickOutside(e))
		}
	}, [isVisible])
	
	useEffect(() => {
		setIsVisible(false)
	}, [addToGroupTitle])
	
	return (
		<div ref={ref} className={styles.dropdown_wrapper}>
			<button
				onClick={() => {
					if (isBlocked) {
						dispatch(callNotifyModal('You should select at list one holder'))
						
						return;
					}
					
					if (!localStorage.getItem('jwt')) {
						dispatch(setNotifyModalActive(true))
						dispatch((setNotifyModalContent('Please connect wallet')))
						
						return
					}
					
					onUserFiltersRefetch()
					setIsVisible(!isVisible)
				}}
				className={styles.button}
				style={isBlocked ? {filter: "brightness(.8)", color: "#999", cursor: "not-allowed"} : undefined}
			>
				<span>{addToGroupTitle}</span>
			</button>
			{
				isVisible
				&& <div className={styles.dropdown_body}>
					<div className={styles.dropdown_container}>
						{
							(
								isContentPending &&
								<div className={styles.dropdown_notification}>
									<span>Pending...</span>
								</div>
							)
							||
							(
								isError &&
								<div className={styles.dropdown_notification}>
									<span>{error}</span>
								</div>
							)
							||
							(
								!!userFilters?.length
									&& userFilters?.map((item, index) =>
										exploredStrategyId === item.id
											? null
											: (
												<div
													onClick={() => onAddToStrategy(item.id)}
													className={styles.dropdown_item}
													key={index}
												>
													<span>{item.name}</span>
													<span>{item.includedAddresses}</span>
												</div>
											)
									)
							)
						}
					</div>
				</div>
			}
		</div>
	);
};

export default AddDropdown;
