import {IBound, IFilter} from "../../../features/createStrategy/interface";
import {CreateState} from "../enum";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {BACKEND_URL} from "../../../utils/config";
import {IData} from "../../../state/api/holders/types";
import {restApiRequest} from "../../../utils";

export const trimAddress = (address: string): string => {
	return  address.slice(0, 3) + '...' + address.slice(address.length - 3, address.length)
}

export const getCreateFilterValue = (bound: IBound | undefined | 'any', state: CreateState): string => {
	if (bound === undefined || bound === 'any') return 'any'
	
	
	if (state === CreateState.WalletAge && bound?.to) {
		const day = 1000 * 60 * 60 * 24
		const to = bound.to.value
		
		if (Math.floor(to / (7 * day)) < 1) {
			return `${Math.floor(to / day)}d+`
		}
		if (Math.floor(to / (30 * day)) < 1) {
			return `${Math.floor(to / (7 * day))}w+`
		}
		if (Math.floor(to / (365 * day)) < 1) {
			return `${Math.floor(to / (30 * day))}m+`
		}
		return `${Math.floor(to / (365 * day))}y+`
	}
	
	if (bound?.from) {
		const from = bound.from.value
		const to = bound.to?.value
		
		if (to)
			return `${from * 100}-${to * 100} %`
	}
	return ''
}