import React, {useState} from 'react';

import {TooltipPosition} from "../../enum";

import styles from './styles/tooltip.module.css'
import classNames from "classnames";
import {tooltipPosition} from "./config";

interface IProp {
	active?: boolean,
	style?: {
		position?: TooltipPosition,
		fontSize?: number,
		maxWidth?: number,
	},
	content: string | JSX.Element | JSX.Element[],
	children: string | JSX.Element | JSX.Element[],
}

const Tooltip = ({active = true, children, content, style}: IProp) => {
	const [showTip, setShowTip] = useState(false)
	
	const tooltipStyles = {
		fontSize: `${(style?.fontSize ?? 1.6)}rem`,
		maxWidth: `${(style?.maxWidth ?? 40)}rem`
	}
	
	const tooltipClass = classNames(
		styles.tooltiptext, tooltipPosition[style?.position ?? TooltipPosition.TOP]
	)
	
	return (
		<div
			className={styles.tooltip}
			onMouseEnter={() => setShowTip(true)}
			onMouseLeave={() => setShowTip(false)}
		>
			{
				children
			}
			{(active && showTip) && <div style={tooltipStyles} className={tooltipClass}>{content}</div>}
		</div>
	);
};

export default Tooltip;